<script setup lang="ts">
import Alert from "../../base-components/Alert";
import { userUserStore } from "../../stores/user";
const versionActual = import.meta.env.VITE_APP_VERSION;
const userStore = userUserStore();
</script>

<template>
  <Alert
    variant="danger"
    class="mb-2 my-4"
    v-if="userStore.ultimaVersionFixed > versionActual"
  >
    <h1 class="text-lg">Actualiza la pantalla</h1>
    <p class="mt-5">
      Hay una nueva versión de Fixed, favor de presionar F5 o (Ctrl + R) para
      actualizar la pantalla (command + r en Mac).
    </p>
    <p class="mt-5">
      Si la alerta persiste, presiona (ctrl + F5) o (Ctrl + Shift + R) (command
      + shift + r en Mac).
    </p>
    <p class="mt-5">
      Si continuas sin actualizar la pantalla podrías encontrar un mal o
      inesperado funcionamiento del sistema.
    </p>
  </Alert>
</template>
