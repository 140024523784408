import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "./getFunctions";
import {
  CloudFunctionResponse,
  FuncCompraAgregar,
  ComprasFuncs
} from "@fixeduy/fixedtypes";

const comprasAgregar = async (
  data: FuncCompraAgregar
): Promise<CloudFunctionResponse> => {
  const run = httpsCallable<ComprasFuncs, CloudFunctionResponse>(
    cloudFunctions,
    "compras"
  );
  const result = await run({
    func: "comprasAgregar",
    params: data
  });
  return result.data;
};

export { comprasAgregar };
