import { Permisos } from "@fixeduy/fixedtypes";
import { userUserStore } from "../stores/user";

export const permisos = (permiso: Permisos): boolean => {
  const userStore = userUserStore();

  if (userStore.token?.claims["rol"] == "Admin") {
    return true;
  }

  // No es admin
  const permisos: string | null = userStore.token?.claims["permisos"];
  return permisos?.includes(permiso) ?? false;
};

export const isAdmin = (): boolean => {
  const userStore = userUserStore();
  return userStore.token?.claims["rol"] == "Admin";
};
