import {defineStore} from "pinia";
import {collection, query, getFirestore, where, getDocs, addDoc, doc, setDoc, updateDoc} from "firebase/firestore";
import {userUserStore} from "../stores/user";
import {
  Moneda,
  monedaConverter,
  utilFechaGetDiaActualDDMMYYYY,
  utilFechaGetDiaActualYYYYMMDD
} from "@fixeduy/fixedtypes";
import {useCotizacionesStore} from "./cotizaciones";

export const useMonedasStore = defineStore({
  id: "monedas",
  persist: true,
  state: () => {
    return {
      _monedas: [] as Moneda[],
      cargando: false
    };
  },
  getters: {
    monedas(): Moneda[] {
      return this._monedas;
    },
    monedasActivas(): Moneda[] {
      return this._monedas.filter(m => m.activo);
    },
    getUltimaMonedaEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this._monedas.map(r => {
        if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
          mayorFechaUltimaEdicion = r.ultimaEdicion;
        }
      });
      return mayorFechaUltimaEdicion;
    }
  },
  actions: {
    async getMonedasFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/monedas/`;
      const mayorFechaUltimaEdicion = this.getUltimaMonedaEdicion;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(collection(db, collectionRoute), where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)).withConverter(
            monedaConverter
          )
        ).then(querySnapshot => {
          let cotizacionesCargadas = false;
          querySnapshot.forEach(doc => {
            const m = doc.data();
            this.addMonedaStore(m);
            if (m.codigoIso != "UYU" && cotizacionesCargadas == false) {
              const cotizacionesStore = useCotizacionesStore();
              cotizacionesStore.getCotizacion(utilFechaGetDiaActualDDMMYYYY(), m.id);
              cotizacionesCargadas = true;
            }
          });
          resolve();
        });
      });
    },
    addMonedaStore(moneda: Moneda) {
      // reviso que no exista
      for (var i = 0; i < this._monedas.length; i++) {
        if (this._monedas[i].id == moneda.id) {
          this._monedas[i] = moneda;
          return; // corto ejecucion de la funcion
        }
      }

      // lo inserto y ordeno
      this._monedas.push(moneda);
      this._monedas.sort((a, b) => (b.nombre > a.nombre ? -1 : 1));
    },
    async crearMoneda(moneda: Moneda) {
      this.cargando = true;
      // const mensajesStore = useMensajesStore();
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/monedas/`;

      // Verificar si la moneda ya existe
      const consultaMonedas = await getDocs(
        query(collection(db, collectionRoute), where("codigoBcu", "==", moneda.codigoBcu))
      );

      if (!consultaMonedas.empty) {
        // La moneda ya existe, no se puede agregar de nuevo
        const dataMoneda = consultaMonedas.docs[0].data();

        // Actualizar la moneda existente a activa
        const docRef = doc(db, collectionRoute, consultaMonedas.docs[0].id);
        await updateDoc(docRef, {
          activo: true,
          ultimaEdicion: new Date().getTime()
        });
        await setDoc(
          doc(db, "empresas", userStore.empresa!.id),
          {
            ultimaEdicionMonedas: new Date().getTime()
          },
          {merge: true}
        );
        this.cargando = false;
        return false;
      } else {
        // Agregar la nueva moneda
        try {
          const docRef = doc(collection(db, collectionRoute));
          moneda.id = docRef.id;
          await setDoc(docRef, moneda);
          this.addMonedaStore(moneda);
          this.cargando = false;

          setDoc(
            doc(db, "empresas", userStore.empresa!.id),
            {
              ultimaEdicionMonedas: new Date().getTime()
            },
            {merge: true}
          );

          // mensajesStore.mostrarToast(
          //   "Moneda creada con éxito",
          //   moneda.nombre,
          //   mensajesStore.mensajeType.exito
          // );
          this.cargando = false;

          return true;
        } catch (error) {
          console.error(error);
        }
      }
    },
    getMonedaById(id: string): Moneda | null {
      for (var i = 0; i < this._monedas.length; i++) {
        if (this._monedas[i].id == id) {
          return this._monedas[i];
        }
      }
      return null;
    },
    getMonedaByTpoMoneda(tpoMoneda: string): Moneda | null {
      for (var i = 0; i < this._monedas.length; i++) {
        if (this._monedas[i].codigoIso == tpoMoneda) {
          return this._monedas[i];
        }
      }
      return null;
    },
    async editarMoneda(moneda: Moneda) {
      this.cargando = true;
      // const mensajesStore = useMensajesStore();
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/monedas/`;

      const docRef = doc(db, collectionRoute, moneda.id);

      await updateDoc(docRef, {
        nombre: moneda.nombre,
        simbolo: moneda.simbolo,
        codigoBcu: moneda.codigoBcu,
        codigoIso: moneda.codigoIso,
        numeroIso: moneda.numeroIso,
        activo: moneda.activo,
        ultimaEdicion: moneda.ultimaEdicion
      });

      if (!moneda.activo) {
        this._monedas = this._monedas.filter(moneda => moneda.activo);
        this.cargando = false;
      } else {
        for (let i = 0; i < this._monedas.length; i++) {
          if (this._monedas[i].id == moneda.id) {
            this._monedas[i] = moneda;
            break;
          }
        }
        this.cargando = false;
      }

      setDoc(
        doc(db, "empresas", userStore.empresa!.id),
        {
          ultimaEdicionMonedas: new Date().getTime()
        },
        {merge: true}
      );

      // mensajesStore.mostrarToast(
      //   "Moneda editada con éxito",
      //   moneda.nombre,
      //   mensajesStore.mensajeType.exito
      // );
      this.cargando = false;

      return true;
    }
  }
});
