<script setup lang="ts">
import {computed, onMounted, reactive, watch, provide} from "vue";
import {useRoute} from "vue-router";
import MobileMenu from "../components/MobileMenu";
import TopBar from "../custom-components/TopBar";
import {useSideMenuStore} from "../stores/side-menu";
import Divider from "./Divider.vue";
import Menu from "./Menu.vue";
import {ProvideForceActiveMenu, forceActiveMenu, Route, FormattedMenu, enter, leave, nestedMenu} from "./side-menu";
import {userUserStore} from "../stores/user";
import Chat from "../custom-components/Chat/Chat.vue";
import {storeToRefs} from "pinia";
import AlertaNuevaVersion from "../custom-components/AlertaNuevaVersion";
import AlertaVencimientoFirma from "../custom-components/AlertaVencimientoFirma";

const route: Route = useRoute();
const user = userUserStore();
user.checkListeners();
const {eventScrollToTop} = storeToRefs(user);

let formattedMenu = reactive<Array<FormattedMenu | "divider">>([]);
const setFormattedMenu = (computedFormattedMenu: Array<FormattedMenu | "divider">) => {
  Object.assign(formattedMenu, computedFormattedMenu);
};

const sideMenuStore = useSideMenuStore();
sideMenuStore.regenerarMenu();
const sideMenu = computed(() => nestedMenu(sideMenuStore.menu, route));

provide<ProvideForceActiveMenu>("forceActiveMenu", (pageName: string) => {
  forceActiveMenu(route, pageName);
  setFormattedMenu(sideMenu.value);
});

watch(sideMenu, () => {
  setFormattedMenu(sideMenu.value);
});

onMounted(() => {
  setFormattedMenu(sideMenu.value);
});

watch(eventScrollToTop, () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
});
</script>

<template>
  <div v-if="user.menuCollapsed" class="py-5 md:py-0">
    <MobileMenu />
    <TopBar layout="simple-menu" />
    <div class="flex overflow-hidden">
      <!-- BEGIN: Simple Menu -->
      <nav class="w-[105px] px-5 pb-16 overflow-x-hidden z-50 pt-32 -mt-4 hidden md:block">
        <!-- <Button
          variant="secondary"
          class="mb-2"
          @click="user.changeMenuState(user.menuCollapsed)"
        >
          <Lucide icon="Menu"></Lucide>
        </Button> -->
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <Divider
              v-if="menu == 'divider'"
              type="li"
              :class="[
                'my-6',

                // Animation
                `opacity-0 animate-[0.4s_ease-in-out_0.1s_intro-divider] animate-fill-mode-forwards animate-delay-${
                  (menuKey + 1) * 10
                }`
              ]"
              :key="'divider-' + menuKey"
            ></Divider>
            <li v-else :key="menuKey">
              <Menu
                :class="{
                  // Animation
                  [`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${
                    (menuKey + 1) * 10
                  }`]: !menu.active
                }"
                :menu="menu"
                :formattedMenuState="[formattedMenu, setFormattedMenu]"
                level="first"
              ></Menu>
              <!-- BEGIN: Second Child -->
              <Transition @enter="() => enter" @leave="() => leave">
                <ul
                  v-if="menu.subMenu && menu.activeDropdown"
                  :class="[
                    'bg-white/[0.04] rounded-xl relative dark:bg-transparent',
                    'before:content-[\'\'] before:block before:inset-0 before:bg-white/30 before:rounded-xl before:absolute before:z-[-1] before:dark:bg-darkmode-900/30',
                    {block: menu.activeDropdown},
                    {hidden: !menu.activeDropdown}
                  ]"
                >
                  <li v-for="(subMenu, subMenuKey) in menu.subMenu" :key="subMenuKey">
                    <Menu
                      :class="{
                        // Animation
                        [`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${
                          (subMenuKey + 1) * 10
                        }`]: !subMenu.active
                      }"
                      :menu="subMenu"
                      :formattedMenuState="[formattedMenu, setFormattedMenu]"
                      level="second"
                    ></Menu>
                    <!-- BEGIN: Third Child -->
                    <Transition @enter="() => enter" @leave="() => leave" v-if="subMenu.subMenu">
                      <ul
                        v-if="subMenu.subMenu && subMenu.activeDropdown"
                        :class="[
                          'bg-white/[0.04] rounded-xl relative dark:bg-transparent',
                          'before:content-[\'\'] before:block before:inset-0 before:bg-white/30 before:rounded-xl before:absolute before:z-[-1] before:dark:bg-darkmode-900/30',
                          {block: subMenu.activeDropdown},
                          {hidden: !subMenu.activeDropdown}
                        ]"
                      >
                        <li v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu" :key="lastSubMenuKey">
                          <Menu
                            :class="{
                              // Animation
                              [`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${
                                (lastSubMenuKey + 1) * 10
                              }`]: !lastSubMenu.active
                            }"
                            :menu="lastSubMenu"
                            :formattedMenuState="[formattedMenu, setFormattedMenu]"
                            level="third"
                          ></Menu>
                        </li>
                      </ul>
                    </Transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </Transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- END: Simple Menu -->
      <!-- BEGIN: Content -->
      <div
        class="max-w-full md:max-w-none rounded-[30px] md:rounded-none px-4 md:px-[22px] min-w-0 min-h-screen bg-slate-100 flex-1 md:pt-20 pb-10 mt-5 md:mt-1 relative dark:bg-darkmode-700 before:content-[\'\'] before:w-full before:h-px before:block"
      >
        <RouterView />
      </div>
      <!-- END: Content -->
    </div>
  </div>
  <div v-else class="py-5 md:py-0">
    <MobileMenu />
    <TopBar layout="side-menu" />
    <div class="flex overflow-hidden">
      <!-- BEGIN: Side Menu -->
      <nav class="w-[105px] xl:w-[205px] px-5 pb-16 overflow-x-hidden z-50 pt-32 -mt-4 hidden md:block">
        <!-- <Button
          variant="secondary"
          class="mb-2"
          @click="user.changeMenuState(user.menuCollapsed)"
        >
          <Lucide icon="Menu"></Lucide>
        </Button> -->
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <Divider v-if="menu == 'divider'" type="li" :class="['my-6']" :key="'divider-' + menuKey"></Divider>
            <li v-else :key="menuKey">
              <Menu :menu="menu" :formattedMenuState="[formattedMenu, setFormattedMenu]" level="first"></Menu>
              <!-- BEGIN: Second Child -->
              <Transition @enter="() => enter" @leave="() => leave">
                <ul
                  v-if="menu.subMenu && menu.activeDropdown"
                  :class="[
                    'bg-white/[0.04] rounded-xl relative dark:bg-transparent',
                    'before:content-[\'\'] before:block before:inset-0 before:bg-white/30 before:rounded-xl before:absolute before:z-[-1] before:dark:bg-darkmode-900/30',
                    {block: menu.activeDropdown},
                    {hidden: !menu.activeDropdown}
                  ]"
                >
                  <li v-for="(subMenu, subMenuKey) in menu.subMenu" :key="subMenuKey">
                    <Menu
                      :class="{
                        // Animation
                        [`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${
                          (subMenuKey + 1) * 10
                        }`]: !subMenu.active
                      }"
                      :menu="subMenu"
                      :formattedMenuState="[formattedMenu, setFormattedMenu]"
                      level="second"
                    ></Menu>
                    <!-- BEGIN: Third Child -->
                    <Transition @enter="() => enter" @leave="() => leave" v-if="subMenu.subMenu">
                      <ul
                        v-if="subMenu.subMenu && subMenu.activeDropdown"
                        :class="[
                          'bg-white/[0.04] rounded-xl relative dark:bg-transparent',
                          'before:content-[\'\'] before:block before:inset-0 before:bg-white/30 before:rounded-xl before:absolute before:z-[-1] before:dark:bg-darkmode-900/30',
                          {block: subMenu.activeDropdown},
                          {hidden: !subMenu.activeDropdown}
                        ]"
                      >
                        <li v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu" :key="lastSubMenuKey">
                          <Menu
                            :class="{
                              // Animation
                              [`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${
                                (lastSubMenuKey + 1) * 10
                              }`]: !lastSubMenu.active
                            }"
                            :menu="lastSubMenu"
                            :formattedMenuState="[formattedMenu, setFormattedMenu]"
                            level="third"
                          ></Menu>
                        </li>
                      </ul>
                    </Transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </Transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div
        :class="[
          'max-w-full md:max-w-none rounded-[30px] md:rounded-none px-4 md:px-[22px] min-w-0 min-h-screen bg-slate-100 flex-1 md:pt-20 pb-10 mt-5 md:mt-1 relative dark:bg-darkmode-700',
          'before:content-[\'\'] before:w-full before:h-px before:block'
        ]"
      >
        <AlertaNuevaVersion />
        <AlertaVencimientoFirma />

        <RouterView />
      </div>
      <Chat />
      <!-- END: Content -->
    </div>
  </div>
</template>
