import {defineStore} from "pinia";
import {collection, query, getFirestore, where, getDocs, addDoc, doc, setDoc} from "firebase/firestore";
import {userUserStore} from "../stores/user";
import {useMensajesStore} from "./mensajes";
import {TasaIVA, tasaConverter} from "@fixeduy/fixedtypes";

export const useTasasIVAStore = defineStore({
  id: "tasasIVA",
  persist: true,
  state: () => {
    return {
      _tasas: [] as TasaIVA[]
    };
  },
  getters: {
    tasas(): TasaIVA[] {
      return this._tasas;
    },
    getUltimaTasaEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this._tasas.map(r => {
        if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
          mayorFechaUltimaEdicion = r.ultimaEdicion;
        }
      });
      return mayorFechaUltimaEdicion;
    }
  },
  actions: {
    async getTasasFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/tasas-iva/`;
      const mayorFechaUltimaEdicion = this.getUltimaTasaEdicion;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(collection(db, collectionRoute), where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)).withConverter(
            tasaConverter
          )
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addTasaIVAToStore(doc.data());
          });
          resolve();
        });
      });
    },
    addTasaIVAToStore(tasa: TasaIVA) {
      // reviso que no exista
      for (var i = 0; i < this._tasas.length; i++) {
        if (this._tasas[i].id == tasa.id) {
          this._tasas[i] = tasa;
          return; // corto ejecucion de la funcion
        }
      }

      // lo inserto y ordeno
      this._tasas.push(tasa);
      this._tasas.sort((a, b) => (b.nombre > a.nombre ? -1 : 1));
    },
    async crearTasa(tasa: TasaIVA) {
      try {
        const mensajesStore = useMensajesStore();
        const db = getFirestore();
        const userStore = userUserStore();
        const collectionRoute = `empresas/${userStore.empresa!.id}/tasas-iva/`;

        const docRef = doc(collection(db, collectionRoute));
        tasa.id = docRef.id;
        await setDoc(docRef, tasa);
        this.addTasaIVAToStore(tasa);

        setDoc(
          doc(db, "empresas", userStore.empresa!.id),
          {
            ultimaEdicionTasasIVA: new Date().getTime()
          },
          {merge: true}
        );

        mensajesStore.mostrarToast("Tasa IVA creada con éxito", tasa.nombre, mensajesStore.mensajeType.exito);

        return true;
      } catch (error) {
        console.error(error);
      }
    },
    getTasaIVAById(id: string): TasaIVA | null {
      for (var i = 0; i < this._tasas.length; i++) {
        if (this._tasas[i].id == id) {
          return this._tasas[i];
        }
      }
      return null;
    },
    getTasaIVAByCFEId(cfeDgi: string): TasaIVA | null {
      for (var i = 0; i < this._tasas.length; i++) {
        if (this._tasas[i].cfeDgi == cfeDgi) {
          return this._tasas[i];
        }
      }
      return null;
    },
    async editarTasaIVA(tasa: TasaIVA) {
      const mensajesStore = useMensajesStore();
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/tasas-iva/`;

      const docRef = doc(db, collectionRoute, tasa.id);
      await setDoc(
        docRef,
        {
          nombre: tasa.nombre,
          sigla: tasa.sigla,
          valor: tasa.valor,
          cfeDgi: tasa.cfeDgi,
          activoProductos: tasa.activoProductos,
          activoServicios: tasa.activoServicios,
          ultimaEdicion: tasa.ultimaEdicion
        },
        {merge: true}
      );

      setDoc(
        doc(db, "empresas", userStore.empresa!.id),
        {
          ultimaEdicionTasasIVA: new Date().getTime()
        },
        {merge: true}
      );

      mensajesStore.mostrarToast("Tasa IVA editada con éxito", tasa.nombre, mensajesStore.mensajeType.exito);

      return true;
    }
  }
});
