import { defineStore } from "pinia";
import { Producto } from "@fixeduy/fixedtypes";

export const useProductosCacheStore = defineStore({
  id: "productosCacheStore",
  persist: true,
  state: () => {
    return {
      _productos: [] as Producto[],
    };
  },
  getters: {
    productos(): Producto[] {
      return this._productos;
    },
  },
  actions: {
    agregarProducto(producto: Producto) {
      // reviso que no exista
      for (var i = 0; i < this._productos.length; i++) {
        if (this._productos[i].id == producto.id) {
          this._productos[i] = producto;
          return; // corto ejecucion de la funcion
        }
      }

      // lo inserto y ordeno
      this._productos.push(producto);
      this._productos.sort((a, b) =>
        b.nombre > a.nombre ? -1 : 1
      );
    },
  }
});
