import {defineStore} from "pinia";
import {Icon} from "../base-components/Lucide/Lucide.vue";
import {userUserStore} from "./user";
import {useCAEStore} from "../services/caes";
import {isAdmin, permisos} from "../utils/permisos";
import {Permisos} from "@fixeduy/fixedtypes";

export interface Menu {
  icon: Icon;
  title: string;
  pageName?: string;
  subMenu?: Menu[];
  ignore?: boolean;
}

export interface SideMenuState {
  menu: Array<Menu | "divider">;
}

export const useSideMenuStore = defineStore("sideMenu", {
  state: (): SideMenuState => {
    return {menu: [] as Array<Menu | "divider">};
  },
  actions: {
    setUpdatedMenu(menuUpdated: Array<Menu | "divider">) {
      this.menu = menuUpdated;
    },
    regenerarMenu() {
      const userStore = userUserStore();
      const caesStore = useCAEStore();

      let mostrarEboleta = false;
      for (const cae of caesStore.caes) {
        if (cae.tcfe === 151) mostrarEboleta = true;
      }
      let nombreServicio: string = userStore.opcionesGeneralesServicios.nombreModuloServicios || "Servicios";

      let menu: Array<Menu | "divider"> = [
        {
          icon: "Home",
          pageName: "home",
          title: "Inicio"
        },
        {
          icon: "DollarSign",
          pageName: "caja",
          title: "Caja"
        },
        {
          icon: "CreditCard",
          pageName: "handyTransacciones",
          title: "Cobros Handy"
        },
        {
          icon: "CreditCard",
          pageName: "fiservTransacciones",
          title: "Cobros Fiserv"
        },
        {
          icon: "ShoppingCart",
          pageName: "ventas",
          title: "Ventas"
        },
        {
          icon: "ShoppingBag",
          pageName: "compras",
          title: "Compras"
        },
        {
          icon: "Briefcase",
          pageName: "servicios",
          title: nombreServicio,
          subMenu: [
            {
              icon: "List",
              pageName: "servicios",
              title: nombreServicio,
              ignore: true
            },
            {
              icon: "List",
              pageName: "serviciosListasPrecios",
              title: "Listas de precios",
              ignore: true
            }
          ]
        },
        {
          icon: "Tags",
          pageName: "productos",
          title: "Productos",
          subMenu: [
            {
              icon: "List",
              pageName: "productos",
              title: "Productos",
              ignore: true
            },
            {
              icon: "List",
              pageName: "productosListasPrecios",
              title: "Listas de precios",
              ignore: true
            }
          ]
        },
        {icon: "Ruler", pageName: "unidades", title: "Unidades"},
        {icon: "FilePlus", pageName: "importador", title: "Importador"},
        {icon: "Package", pageName: "stock", title: "Stock"},
        {icon: "FileText", pageName: "remitos", title: "Remitos"},
        {
          icon: "FileText",
          pageName: "resguardos",
          title: "Resguardos",
          subMenu: [
            {
              icon: "List",
              pageName: "resguardos",
              title: "Resguardos emitidos",
              ignore: true
            },
            {
              icon: "List",
              pageName: "resguardosRecibidos",
              title: "Resguardos recibidos",
              ignore: true
            }
          ]
        },
        {icon: "FileText", pageName: "eboletas-compra", title: "eBoletas"},
        {
          icon: "UserPlus",
          pageName: "clientes",
          title: "Clientes",
          subMenu: [
            {
              icon: "List",
              pageName: "clientes",
              title: "Clientes",
              ignore: true
            },
            {
              icon: "List",
              pageName: "ClientesCuentasCorrientes",
              title: "Cuentas clientes",
              ignore: true
            },
            {
              icon: "List",
              pageName: "ClientesRecibos",
              title: "Recibos clientes",
              ignore: true
            }
          ]
        },
        {
          icon: "UserMinus",
          pageName: "proveedores",
          title: "Proveedores",
          subMenu: [
            {
              icon: "List",
              pageName: "proveedores",
              title: "Proveedores",
              ignore: true
            },
            {
              icon: "List",
              pageName: "ProveedoresCuentasCorrientes",
              title: "Cuentas proveedores",
              ignore: true
            },
            {
              icon: "List",
              pageName: "ProveedoresRecibos",
              title: "Recibos proveedores",
              ignore: true
            }
          ]
        },
        {
          icon: "PieChart",
          pageName: "estadisticas",
          title: "Estadisticas",
          subMenu: [
            {
              icon: "List",
              pageName: "estadisticasVenta",
              title: "Venta",
              ignore: true
            },
            {
              icon: "List",
              pageName: "estadisticasCompra",
              title: "Compra",
              ignore: true
            }
          ]
        },
        {
          icon: "ActivityIcon",
          pageName: "centroCostos",
          title: "Centro de costos"
        },
        {
          icon: "Inbox",
          pageName: "CFEsRecibidos",
          title: "CFE recibidos",
          ignore: true
        },
        {
          icon: "Lock",
          pageName: "usuariosPosHandy",
          title: "Usuarios POS Handy",
          ignore: true
        },
        {
          icon: "Settings",
          pageName: "opciones",
          title: "Opciones",
          subMenu: [
            {icon: "PersonStanding", pageName: "usuarios", title: "Usuarios"},
            {
              icon: "Wrench",
              pageName: "opcionesGenerales",
              title: "Generales",
              ignore: true
            },
            {
              icon: "Wrench",
              pageName: "opcionesCompras",
              title: "Compras",
              ignore: true
            },
            {
              icon: "Wrench",
              pageName: "opcionesVentas",
              title: "Ventas",
              ignore: true
            },
            {
              icon: "Wrench",
              pageName: "opcionesCotizaciones",
              title: "Cotizaciones",
              ignore: true
            }
          ]
        },
        {
          icon: "Sun",
          pageName: "factura-electronica",
          title: "Factura electrónica",
          subMenu: [
            {
              icon: "Inbox",
              pageName: "CFEs",
              title: "CFE emitidos",
              ignore: true
            },
            {
              icon: "File",
              pageName: "cae",
              title: "CAE",
              ignore: true
            },
            {
              icon: "File",
              pageName: "constancia-contingencia",
              title: "Constancias de contingencia",
              ignore: true
            },
            {
              icon: "Wrench",
              pageName: "firma-electronica",
              title: "Firma electrónica",
              ignore: true
            },
            {
              icon: "Wrench",
              pageName: "envio-mail",
              title: "Envío por mail",
              ignore: true
            }
          ]
        }
      ];
      if (!userStore.opcionesGenerales.integracionPOSHandy.habilitado) {
        menu = menu.filter(item => (item as Menu).pageName !== "handyTransacciones");
      }
      if (!userStore.opcionesGenerales.integracionPOSFiserv.habilitado) {
        menu = menu.filter(item => (item as Menu).pageName !== "fiservTransacciones");
      }
      if (!userStore.opcionesGenerales.centroCostos) {
        menu = menu.filter(item => (item as Menu).pageName !== "centroCostos");
        // menu = menu.filter(item => (item as Menu).pageName !== "estadisticasCentroCostos");
      }
      if (!userStore.opcionesGenerales.unidades) {
        menu = menu.filter(item => (item as Menu).pageName !== "unidades");
      }
      if (!userStore.opcionesGenerales.moduloServicios) {
        menu = menu.filter(item => (item as Menu).pageName !== "servicios");
      }
      if (!userStore.opcionesGenerales.moduloProductos) {
        menu = menu.filter(item => (item as Menu).pageName !== "productos");
      }
      if (!userStore.opcionesGenerales.moduloProductos) {
        menu = menu.filter(item => (item as Menu).pageName !== "stock");
      }
      if (!userStore.opcionesGenerales.moduloResguardos) {
        menu = menu.filter(item => (item as Menu).pageName !== "resguardos");
      }
      if (!userStore.opcionesGenerales.moduloRemitos) {
        menu = menu.filter(item => (item as Menu).pageName !== "remitos");
      }
      if (
        !userStore.opcionesCompras.controlarCaja &&
        !userStore.opcionesVentaUsuario.controlarCaja &&
        !userStore.opcionesGenerales.moduloCaja
      ) {
        menu = menu.filter(item => (item as Menu).pageName !== "caja");
      }
      if (!userStore.opcionesGenerales.moduloImportador) {
        menu = menu.filter(item => (item as Menu).pageName !== "importador");
      }

      if (!mostrarEboleta) {
        menu = menu.filter(item => (item as Menu).pageName !== "eboletas-compra");
      }
      if (userStore.opcionesGenerales.integracionFEPOS.habilitado == false || !isAdmin()) {
        menu = menu.filter(item => (item as Menu).pageName !== "usuariosPosHandy");
      }

      if (!isAdmin()) {
        // Solo para admin
        menu = menu.filter(item => (item as Menu).pageName !== "opciones");
        menu = menu.filter(item => (item as Menu).pageName !== "factura-electronica");

        if (!permisos(Permisos.ListarVentas)) {
          menu = menu.filter(item => (item as Menu).pageName !== "ventas");
        }
        if (!permisos(Permisos.ListarClientes)) {
          menu = menu.filter(item => (item as Menu).pageName !== "clientes");
        }
        if (!permisos(Permisos.ListarCompras)) {
          menu = menu.filter(item => (item as Menu).pageName !== "compras");
        }
        if (!permisos(Permisos.ListarProveedores)) {
          menu = menu.filter(item => (item as Menu).pageName !== "proveedores");
        }
        if (!permisos(Permisos.ListarServicios)) {
          menu = menu.filter(item => (item as Menu).pageName !== "servicios");
        }
        if (!permisos(Permisos.ListarProductos)) {
          menu = menu.filter(item => (item as Menu).pageName !== "productos");
        }
        if (!permisos(Permisos.ListarStock)) {
          menu = menu.filter(item => (item as Menu).pageName !== "stock");
        }
        if (!permisos(Permisos.ListarRemitos)) {
          menu = menu.filter(item => (item as Menu).pageName !== "remitos");
        }
        if (!permisos(Permisos.Estadisticas)) {
          menu = menu.filter(item => (item as Menu).pageName !== "estadisticas");
        }
        if (!permisos(Permisos.ListarCentroCostos)) {
          menu = menu.filter(item => (item as Menu).pageName !== "centroCostos");
        }
        if (!permisos(Permisos.ListarCFERecibidos)) {
          menu = menu.filter(item => (item as Menu).pageName !== "CFEsRecibidos");
        }
      }

      this.menu = menu;
    }
  }
});
