import { defineStore } from "pinia";
import { getFirestore, setDoc, doc, getDoc } from "firebase/firestore";
import { userUserStore } from "../stores/user";
import {
  Cotizacion,
  Moneda,
  cotizacionConverter,
  utilFechaGetDiaActualYYYYMMDD
} from "@fixeduy/fixedtypes";
import { useMonedasStore } from "./monedas";
import { useCotizacionesBCUStore } from "./cotizacionesBCU";

// Modulo de las cotizaciones a utilizar por la empresa
// Si quiero hacer una factura con fecha 20/9
// traera de la db la cotización con fecha 20/9
// es decir, guardará con la fecha a utilizar.
// El caso de cotización oficial, traerá la del cierre del 19/9

export const useCotizacionesStore = defineStore({
  id: "cotizaciones",
  persist: true,
  state: () => {
    return {
      _cotizaciones: {} as { [key: string]: number }
    };
  },
  getters: {},
  actions: {
    /**
     * Devuelve la cotización a usar por la empresa para un día dado
     * @param fecha En formato dd/mm/aaaa
     * @param monedaId El id de la moneda como string
     * @returns la cotización como number
     */
    async getCotizacion(fechaRecibida: string, monedaId: string) {
      const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/;
      if (!regex.test(fechaRecibida)) {
        return 1;
        // throw new Error(
        //   `La fecha ${fechaRecibida} recibida no es en formato dd/mm/yyyy`
        // );
      }
      const parts = fechaRecibida.split("/");
      const [day, month, year] = parts;
      const fecha = `${year}-${month}-${day}`;

      const monedasStore = useMonedasStore();
      const moneda = monedasStore.getMonedaById(monedaId);

      if (moneda == null) {
        return 1;
      }

      if (moneda.codigoIso == "UYU") {
        return 1;
      }

      const clave = `${fecha}_${moneda.codigoBcu}`;

      if (clave in this._cotizaciones) {
        return this._cotizaciones[clave];
      } else {
        // cargo de la db
        await this.getCotizacionesFromFirestore(fecha, moneda);
        return this._cotizaciones[clave];
      }
    },
    /**
     * Guarda la cotización en Firestore, es void pero async, y termina cuando guardo
     * @param fecha En formato aaaa-mm-dd
     * @returns la cotización como number
     */
    async getCotizacionesFromFirestore(fecha: string, moneda: Moneda) {
      const regex = /^(19|20)\d\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
      if (!regex.test(fecha)) {
        return;
        // throw new Error(
        //   `La fecha ${fecha} recibida no es en formato aaaa-mm-dd`
        // );
      }
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/cotizaciones/`;
      const clave = `${fecha}_${moneda.codigoBcu}`;

      const docRef = doc(db, collectionRoute, clave).withConverter(
        cotizacionConverter
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // Existen cotizaciones para ese dia, las cargo
        const cotizacion = docSnap.data();
        this._cotizaciones[clave] = cotizacion.cotizacion;
      } else {
        // No existe cotizacion para ese día
        // Leo de /cotizaciones las del BCU
        const cotizacionesBCU = useCotizacionesBCUStore();
        const cotizacion = await cotizacionesBCU.getCotizacion(
          fecha,
          moneda.codigoBcu
        );
        this._cotizaciones[clave] = cotizacion.cotizacion; // guardo en el store
        // Guardo en firestore
        await setDoc(docRef, cotizacion);
      }
    },
    /**
     * Guarda la cotización en Firestore, es void pero async, y termina cuando guardo
     * @param moneda La moneda
     * @returns la cotización como number
     */
    async setCotizacionHoy(moneda: Moneda, valor: number) {
      const fecha = utilFechaGetDiaActualYYYYMMDD();
      const clave = `${fecha}_${moneda.codigoBcu}`;
      this._cotizaciones[clave] = valor;

      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/cotizaciones/`;

      await setDoc(
        doc(db, collectionRoute, clave),
        { cotizacion: valor },
        { merge: true }
      );
    }
  }
});
