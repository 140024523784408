import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where
} from "firebase/firestore";
import { Cliente, ProductosListasPrecio, ServiciosListasPrecio, clienteConverter } from "@fixeduy/fixedtypes";
import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { userUserStore } from "../stores/user";

export const useClientesStore = defineStore("clientes", () => {
  const db = getFirestore();
  const clientes: Ref<Cliente[]> = ref([]);
  const cliente: Ref<Cliente | null> = ref(null);
  const userStore = userUserStore();

  let last: any = null;
  let mostrar: number = 20;
  const cargando = ref<boolean>(false);
  const procesandoGetClientes = ref(false);
  const empresaId: string = userStore.empresa!.id;
  const collectionRoute: string = `empresas/${empresaId}/clientes/`;
  let timerBuscar: NodeJS.Timeout | null = null;
  const buscar = ref<string>("");
  const listasPreciosServicios: Ref<ServiciosListasPrecio[]> = ref([]);
  const listaPreciosProductos: Ref<ProductosListasPrecio[]> = ref([]);
  let ultimaCargaVacia = false;
  const verInactivo = ref(<boolean>false);
  const esBuscar = ref(false);

  const init = async () => {
    ultimaCargaVacia = false;
    cargando.value = true;
    last = null;
    clientes.value = [];
    mostrar = 20;
    let i = 0;
    while (!ultimaCargaVacia) {
      await getClientesFromFirestore(false);
      i++;
      if (i >= 5) {
        break;
      }
    }
    getClientesReordenarReCalcular();
    cargando.value = false;
    mostrar = 20;
  };

  const getClientesFromFirestore = async (limpiarActuales: boolean) => {
    if ((procesandoGetClientes.value || ultimaCargaVacia) && !esBuscar.value) return;
    procesandoGetClientes.value = true;
    const querySnapshot = await getDocs(
      query(
        collection(db, collectionRoute),
        ...(!verInactivo.value || buscar.value ? [where("activo", "==", true)] : []),
        ...(buscar.value != "" ? [where("query", "array-contains", buscar.value.toLocaleLowerCase())] : []),
        orderBy("nombreComercial", "asc"),
        ...(last != null && !limpiarActuales ? [startAfter(last)] : []),
        ...(mostrar > 0 ? [limit(mostrar)] : [])
      ).withConverter(clienteConverter)
    );
    if (limpiarActuales) {
      clientes.value = [];
      last = null;
    }
    if (querySnapshot.docs[querySnapshot.docs.length - 1] != null) {
      last = querySnapshot.docs[querySnapshot.docs.length - 1];
    }
    ultimaCargaVacia = querySnapshot.docs.length == 0;
    querySnapshot.forEach(doc => {
      clientes.value.push(doc.data());
    });
    procesandoGetClientes.value = false;
    esBuscar.value = false;
  };

  const getClientesReordenarReCalcular = () => {
    clientes.value = Object.values(
      clientes.value.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {} as { [key: string]: Cliente })
    );

    clientes.value.sort((a, b) => {
      const nombreA = a.nombreComercial.toLowerCase();
      const nombreB = b.nombreComercial.toLowerCase();

      if (nombreA < nombreB) {
        return -1;
      }
      if (nombreA > nombreB) {
        return 1;
      }
      return 0;
    });
  };

  const buscarClientes = async (buscarCliente: string) => {
    esBuscar.value = true;
    if (buscarCliente == "") {
      init();
      if (timerBuscar != null) clearTimeout(timerBuscar);
      return;
    }
    if (timerBuscar != null) clearTimeout(timerBuscar);
    let tiempoTimer = 600;
    if (clientes.value.length == 0) {
      tiempoTimer = 200;
    }

    timerBuscar = setTimeout(() => {
      buscar.value = buscarCliente;
      verInactivo.value = false;
      getClientesFromFirestore(true);
    }, tiempoTimer);
  };

  const existeCliente = async (cliente: Cliente): Promise<Cliente | null> => {
    const tipoDocumento: number = cliente.tipoDocumento;
    const documento: string = cliente.documento;
    const existe = await getDocs(
      query(
        collection(db, collectionRoute).withConverter(clienteConverter),
        where("tipoDocumento", "==", tipoDocumento),
        where("documento", "==", documento),
        limit(1)
      )
    );
    if (existe.docs.length > 0) {
      return existe.docs[0].data();
    } else {
      return null;
    }
  };

  const crearCliente = async (cliente: Cliente) => {
    try {
      const docRef = doc(collection(db, collectionRoute)).withConverter(clienteConverter);
      cliente.id = docRef.id;
      await setDoc(docRef, cliente);
      clientes.value.unshift(cliente);
      return true;
    } catch (error) {
      console.error(error);
    }
  };

  const editarCliente = async (cliente: Cliente) => {
    const docRef = doc(db, collectionRoute, cliente.id).withConverter(clienteConverter);
    await setDoc(
      docRef,
      {
        tipoDocumento: cliente.tipoDocumento,
        documento: cliente.documento,
        razonSocial: cliente.razonSocial,
        pais: cliente.pais,
        nombreComercial: cliente.nombreComercial,
        direccion: cliente.direccion,
        localidad: cliente.localidad,
        departamento: cliente.departamento,
        direccionEntrega: cliente.direccionEntrega,
        descuento: cliente.descuento,
        emails: cliente.emails,
        mailCopiaXml: cliente.mailCopiaXml,
        vendedorId: cliente.vendedorId,
        listaPrecioServicios: cliente.listaPrecioServicios,
        listaPrecioProductos: cliente.listaPrecioProductos,
        formaDePago: cliente.formaDePago,
        rubroServiciosVentaId: cliente.rubroServiciosVentaId,
        activo: cliente.activo,
        campoExtra: cliente.campoExtra,
        topeCuentas: cliente.topeCuentas,
        buscablePor: cliente.buscablePor,
        comentarios: cliente.comentarios
      },
      { merge: true }
    );

    if (!cliente.activo) {
      clientes.value = clientes.value.filter(elemento => elemento.activo);
    } else {
      for (let i: number = 0; i < clientes.value.length; i++) {
        if (clientes.value[i].id == cliente.id) {
          clientes.value[i] = cliente;
          break;
        }
      }
    }
    init();
    return true;
  };

  const modificarComentario = async (idComprobante: string, comentario: string) => {
    const docRef = doc(db, collectionRoute, idComprobante).withConverter(clienteConverter);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      throw new Error("No existe el cliente");
    }

    await updateDoc(docRef, {
      comentarios: comentario
    });
  };

  const geClienteById = async (id: string) => {
    try {
      const docRef = doc(db, collectionRoute, id).withConverter(clienteConverter);

      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        return docSnapshot.data();
      } else {
        return null;
      }
    } catch (e) {
      console.error("Error en la consulta: ", e);
      throw e;
    }
  };

  const getClienteByDoc = async (doc: string, tipoDoc: number) => {
    const documentosRef = collection(getFirestore(), `empresas/${userStore.empresa!.id}/clientes/`).withConverter(
      clienteConverter
    );
    const q = query(documentosRef, where("documento", "==", doc), where("tipoDocumento", "==", tipoDoc));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const documento = doc.data();
      return documento;
    } else {
      return null;
    }
  };

  const getAllClientes = async () => {
    ultimaCargaVacia = false;
    cargando.value = true;
    last = null;
    clientes.value = [];
    mostrar = 200;
    let i = 0;
    while (ultimaCargaVacia == false) {
      await getClientesFromFirestore(false);
      i++;
      // if (i >= 500) {
      //   // console.log("Corto el while");
      //   break;
      // }
    }
    getClientesReordenarReCalcular();
    cargando.value = false;
    mostrar = 20;
  };

  const resetClientes = async () => {
    clientes.value = [];
    // buscar.value = "";
    cargando.value = false;
    last = null;
  };

  return {
    init,
    clientes,
    procesandoGetClientes,
    cliente,
    cargando,
    getClientesFromFirestore,
    buscarClientes,
    existeCliente,
    crearCliente,
    editarCliente,
    listasPreciosServicios,
    listaPreciosProductos,
    verInactivo,
    buscar,
    modificarComentario,
    geClienteById,
    getClienteByDoc,
    getAllClientes,
    resetClientes
  };
});
