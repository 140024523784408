import { defineStore } from "pinia";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  addDoc,
  doc,
  setDoc
} from "firebase/firestore";
import { userUserStore } from "../stores/user";

import { CentroCostos, centroCostosConverter } from "@fixeduy/fixedtypes";

export const useCentroCostosStore = defineStore({
  id: "costos",
  persist: true,
  state: () => {
    return {
      _costos: [] as CentroCostos[],
      cargando: false
    };
  },
  getters: {
    costos(): CentroCostos[] {
      return this._costos;
    },
    getUltimoCentroCostosEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this._costos.map(r => {
        if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
          mayorFechaUltimaEdicion = r.ultimaEdicion;
        }
      });
      return mayorFechaUltimaEdicion;
    }
  },
  actions: {
    async getCentroCostosFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/centro-costos/`;
      const mayorFechaUltimaEdicion = this.getUltimoCentroCostosEdicion;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(
            collection(db, collectionRoute),
            where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)
          ).withConverter(centroCostosConverter)
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addCentroCostoToStore(doc.data());
          });
          resolve();
        });
      });
    },
    addCentroCostoToStore(costo: CentroCostos) {
      // reviso que no exista
      for (var i = 0; i < this._costos.length; i++) {
        if (this._costos[i].id == costo.id) {
          this._costos[i] = costo;
          return; // corto ejecucion de la funcion
        }
      }

      // lo inserto y ordeno
      this._costos.push(costo);
      this._costos.sort((a, b) => (b.nombre > a.nombre ? -1 : 1));
    },
    async crearCosto(costo: CentroCostos) {
      try {
        this.cargando = true;
        // const mensajesStore = useMensajesStore();
        const db = getFirestore();
        const userStore = userUserStore();
        const collectionRoute = `empresas/${userStore.empresa!.id}/centro-costos/`;

        const docRef = doc(collection(db, collectionRoute));
        costo.id = docRef.id;
        await setDoc(docRef, costo);
        this.addCentroCostoToStore(costo);

        setDoc(
          doc(db, "empresas", userStore.empresa!.id),
          {
            ultimaEdicionCentroCostos: new Date().getTime()
          },
          { merge: true }
        );
        this.cargando = false;
        
        return true;
  
      } catch (error) {
        console.error(error);
      }

       },
    getCentroCostosById(id: string): CentroCostos | null {
      for (var i = 0; i < this._costos.length; i++) {
        if (this._costos[i].id == id) {
          return this._costos[i];
        }
      }
      return null;
    },
    async editarCentroCostos(costo: CentroCostos) {
      this.cargando = true;
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${
        userStore.empresa!.id
      }/centro-costos/`;

      const docRef = doc(db, collectionRoute, costo.id);

      await setDoc(
        docRef,
        {
          version: 1,
          nombre: costo.nombre,
          valorExportacion: costo.valorExportacion,
          activo: costo.activo,
          ultimaEdicion: costo.ultimaEdicion
        },
        { merge: true }
      );

      if (!costo.activo) {
        this._costos = this._costos.filter(costo => costo.activo);
      } else {
        for (let i = 0; i < this._costos.length; i++) {
          if (this._costos[i].id == costo.id) {
            this._costos[i] = costo;
            break;
          }
        }
      }

      setDoc(
        doc(db, "empresas", userStore.empresa!.id),
        {
          ultimaEdicionCentroCostos: new Date().getTime()
        },
        { merge: true }
      );

      this.cargando = false;

      return true;
    }
  }
});
