import { defineStore } from "pinia";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  doc,
  setDoc
} from "firebase/firestore";
import { userUserStore } from "../stores/user";
import { CajaMediosPago, cajaMediosPagoConverter } from "@fixeduy/fixedtypes";
import { useCajasStore } from "./cajas";

export const useCajasMediosPagoStore = defineStore({
  id: "cajasMediosPago",
  persist: true,
  state: () => {
    return {
      _mediosPago: [] as CajaMediosPago[],
      cargando: false,
      cajas: [] as string[]
    };
  },
  getters: {
    mediosPago(): CajaMediosPago[] {
      return this._mediosPago;
    },
    mediosPagoActivo(): CajaMediosPago[] {
      return this._mediosPago.filter(medio => medio.activo);
    },
    getUltimoMedioPagoEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this._mediosPago.map(r => {
        if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
          mayorFechaUltimaEdicion = r.ultimaEdicion;
        }
      });
      return mayorFechaUltimaEdicion;
    }
  },
  actions: {
    async getMediosPagoFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/medios-pago/`;
      const mayorFechaUltimaEdicion = this.getUltimoMedioPagoEdicion;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(
            collection(db, collectionRoute),
            where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)
          ).withConverter(cajaMediosPagoConverter)
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addMedioPagoStore(doc.data());
          });
          resolve();
        });
      });
    },
    addMedioPagoStore(medioPago: CajaMediosPago) {
      // reviso que no exista
      for (var i = 0; i < this._mediosPago.length; i++) {
        if (this._mediosPago[i].id == medioPago.id) {
          this._mediosPago[i] = medioPago;
          return; // corto ejecucion de la funcion
        }
      }

      // lo inserto y ordeno
      this._mediosPago.push(medioPago);
      this._mediosPago.sort((a, b) => (b.nombre > a.nombre ? -1 : 1));
    },
    async crearMedioPago(medioPago: CajaMediosPago) {
      try {
        this.cargando = true;
        const db = getFirestore();
        const userStore = userUserStore();
        const collectionRoute = `empresas/${userStore.empresa!.id
          }/medios-pago/`;
        const cajasStore = useCajasStore();

        const docRef = doc(collection(db, collectionRoute));
        medioPago.id = docRef.id;
        await setDoc(docRef, medioPago);
        this.addMedioPagoStore(medioPago);

        setDoc(
          doc(db, "empresas", userStore.empresa!.id),
          {
            ultimaEdicionMedioPago: new Date().getTime()
          },
          { merge: true }
        );

        if (this.cajas.length) {
          for (const caja of this.cajas) {
            let cajaOriginal = cajasStore.getCajaById(caja);
            if (cajaOriginal) {
              const mediosPago = cajaOriginal.mediosPago;
              mediosPago.push(medioPago.id);
              const cajaEditar = {
                version: cajaOriginal.version,
                id: cajaOriginal.id,
                nombre: cajaOriginal.nombre,
                puedenRetirar: cajaOriginal.puedenRetirar,
                puedenAceptarDepositos: cajaOriginal.puedenAceptarDepositos,
                mediosCobro: cajaOriginal.mediosCobro,
                mediosPago: mediosPago,
                saldoMonedas: cajaOriginal.saldoMonedas,
                saldoMedios: cajaOriginal.saldoMedios,
                depositosPendientes: cajaOriginal.depositosPendientes,
                depositosPendientesImportes:
                  cajaOriginal.depositosPendientesImportes,
                depositosPendientesAceptar:
                  cajaOriginal.depositosPendientesAceptar,
                depositosPendientesAceptarImportes:
                  cajaOriginal.depositosPendientesAceptarImportes,
                locales: cajaOriginal.locales,
                admiteDepositos: cajaOriginal.admiteDepositos,
                ultimaEdicion: cajaOriginal.ultimaEdicion,
                activo: cajaOriginal.activo
              };
              await cajasStore.editarCaja(cajaEditar);
            }
          }
        }

        this.cargando = false;

        return true;
      } catch (error) {
        console.error(error);
      }
    },

    getMedioPagoById(id: string): CajaMediosPago | null {
      for (var i = 0; i < this._mediosPago.length; i++) {
        if (this._mediosPago[i].id == id) {
          return this._mediosPago[i];
        }
      }
      return null;
    },
    async editarMedioPago(medioPago: CajaMediosPago) {
      this.cargando = true;
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/medios-pago/`;

      const docRef = doc(db, collectionRoute, medioPago.id).withConverter(
        cajaMediosPagoConverter
      );

      await setDoc(
        docRef,
        {
          version: 1,
          nombre: medioPago.nombre,
          camposExtra: medioPago.camposExtra,
          activo: medioPago.activo,
          ultimaEdicion: medioPago.ultimaEdicion
        },
        { merge: true }
      );

      if (!medioPago.activo) {
        this._mediosPago = this._mediosPago.filter(
          medioPago => medioPago.activo
        );
      } else {
        for (let i = 0; i < this._mediosPago.length; i++) {
          if (this._mediosPago[i].id == medioPago.id) {
            this._mediosPago[i] = medioPago;
            break;
          }
        }
      }

      setDoc(
        doc(db, "empresas", userStore.empresa!.id),
        {
          ultimaEdicionMedioPago: new Date().getTime()
        },
        { merge: true }
      );

      this.cargando = false;

      return true;
    }
  }
});
