import {defineStore, storeToRefs} from "pinia";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  startAfter,
  limit,
  orderBy,
  doc,
  setDoc,
  getDoc
} from "firebase/firestore";
import {Ref, ref} from "vue";
import {userUserStore} from "../stores/user";
import {
  Documento,
  DocumentoConverter,
  InterfazDesafectarDocumento,
  InterfazDocumentoAfectado,
  utilFechaGetTimestampFromDate
} from "@fixeduy/fixedtypes";
import {documentoDesafectar} from "./cloudFunctions/documentoDesafectar";
import {documentoAfectar} from "./cloudFunctions/documentoAfectar";
import {formatearFechaAAAAMMDD} from "../utils/fecha";
import {FiltroDocumentos} from "./interfaceDocumentosFiltros";

export const useDocumentosStore = defineStore("documentos", () => {
  const db = getFirestore();
  const userStore = userUserStore();
  const documentos: Ref<Documento[]> = ref([]);
  let last: any = null;
  let mostrar = 10;
  const cargando = ref(false);
  let procesandoGetDocumentos = false;
  const showExitoMsg = ref(false);
  const buscar = ref("");
  const collectionRoute = `empresas/${userStore.empresa!.id}/documentos/`;
  let timerBuscar: NodeJS.Timeout | null = null;
  const verInactivo = ref(false);
  const montoTotal = ref([] as {moneda: string; importe: number}[]);
  const montoIVA = ref([] as {moneda: string; importe: number}[]);
  const montoSubtotal = ref([] as {moneda: string; importe: number}[]);
  const filtrado = ref(false);
  let ultimaCargaVacia = false;
  const filtros: Ref<FiltroDocumentos> = ref({
    fechaInicio: "null",
    fechaFin: "null",
    cliente: "",
    proveedor: "",
    nroComprobanteMinimo: 0,
    nroComprobanteMaximo: 0,
    moneda: [],
    locales: []
  });
  let ejecutarVaciarFiltros = true;

  const firestoreQuery = () => {
    let fechaInicioComprobante: number | string = "";
    let fechaFinComprobante: number | string = "";

    let filtrarFechaComprobante = false;
    if (filtros.value.fechaInicio != "" && filtros.value.fechaInicio != "null") {
      fechaInicioComprobante = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtros.value.fechaInicio));
    }

    if (filtros.value.fechaFin != "" && filtros.value.fechaFin != "null") {
      fechaFinComprobante = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtros.value.fechaFin));
      let fechaAux: any = new Date(fechaFinComprobante);
      fechaAux = fechaAux.setDate(fechaAux.getDate() + 1);
      fechaFinComprobante = fechaAux;
    }

    let entidad = "";
    if (filtros.value.cliente) {
      entidad = filtros.value.cliente;
    } else {
      if (filtros.value.proveedor) {
        entidad = filtros.value.proveedor;
      }
    }
    return query(
      collection(db, collectionRoute),
      ...(fechaInicioComprobante ? [where("fechaComprobante", ">=", fechaInicioComprobante)] : []),
      ...(fechaFinComprobante ? [where("fechaComprobante", "<", fechaFinComprobante)] : []),
      ...(entidad != "" && entidad != null ? [where("entidadId", "==", entidad)] : []),
      ...(filtros.value.moneda.length ? [where("moneda", "in", filtros.value.moneda)] : []),
      ...(filtros.value.locales.length ? [where("local", "in", filtros.value.locales)] : []),
      orderBy("fechaComprobante", "desc"),
      ...(last != null ? [startAfter(last)] : []),
      ...(mostrar > 0 ? [limit(mostrar)] : [])
    ).withConverter(DocumentoConverter);
  };

  const init = () => {
    last = null;
    filtrado.value = false;
    ultimaCargaVacia = false;
    documentos.value = [];
    if (ejecutarVaciarFiltros) vaciarFiltros();
    else {
      ejecutarVaciarFiltros = true;
      return filtrarDocumentos();
    }
    ejecutarVaciarFiltros = true;
    getDocumentos();
  };

  const vaciarFiltros = () => {
    (filtros.value.fechaInicio = "null"),
      (filtros.value.fechaFin = "null"),
      (filtros.value.cliente = ""),
      (filtros.value.proveedor = ""),
      (filtros.value.nroComprobanteMinimo = 0),
      (filtros.value.nroComprobanteMaximo = 0),
      (filtros.value.moneda = []),
      (filtros.value.locales = []);
  };

  const getDocumentos = async () => {
    if (procesandoGetDocumentos || ultimaCargaVacia) return;

    procesandoGetDocumentos = true;

    const querySnapshot = await getDocs(firestoreQuery());
    if (querySnapshot.docs[querySnapshot.docs.length - 1] != null) {
      last = querySnapshot.docs[querySnapshot.docs.length - 1];
    }
    ultimaCargaVacia = querySnapshot.docs.length == 0;
    querySnapshot.forEach(doc => {
      const documento = doc.data();
      const encontrada = documentos.value.find(x => x.id === documento.id);
      if (encontrada == null) {
        documentos.value.push(doc.data() as Documento);
      }
    });

    procesandoGetDocumentos = false;
  };

  const buscarDocumentos = async (buscarDocumento: string) => {
    if (timerBuscar != null) clearTimeout(timerBuscar);

    timerBuscar = setTimeout(() => {
      buscar.value = buscarDocumento;
      documentos.value = [];
      last = null;
      getDocumentos();
    }, 500);
  };

  const addDocumento = async (documento: Documento) => {
    const docRef = doc(collection(db, collectionRoute));
    documento.id = docRef.id;
    await setDoc(docRef, {
      version: 1,
      id: "",
      tipoDocumento: documento.tipoDocumento,
      documentoId: documento.documentoId,
      fechaComprobante: documento.fechaComprobante,
      fechaCreacion: documento.fechaCreacion,
      comprobante: documento.comprobante,
      entidadId: documento.entidadId,
      entidadNombre: documento.entidadNombre,
      moneda: documento.moneda,
      cotizacion: documento.cotizacion,
      cancelado: documento.cancelado,
      cancelo: documento.cancelo,
      pendienteCancelarCantidad: documento.pendienteCancelarCantidad,
      disponibleCancelarCantidad: documento.disponibleCancelarCantidad,
      pendienteCancelarImporte: documento.pendienteCancelarImporte,
      disponibleCancelarImporte: documento.disponibleCancelarImporte,
      importe: documento.importe,
      importePendiente: documento.importePendiente,
      importeDisponible: documento.importeDisponible,
      canceladoPor: documento.canceladoPor,
      cancela: documento.cancela,
      tipoCancelacionRecibida: documento.tipoCancelacionRecibida,
      tipoCancelacionRealizada: documento.tipoCancelacionRealizada,
      relacionCancelacionesRealizada: documento.relacionCancelacionesRealizada,
      relacionCancelacionesRecibida: documento.relacionCancelacionesRecibida,
      anulado: false
    });
    documentos.value.unshift(documento);
  };

  const editarDocumento = async (documento: Documento) => {
    const docRef = doc(db, collectionRoute, documento.id).withConverter(DocumentoConverter);
    await setDoc(
      docRef,
      {
        ...documento
      },
      {merge: true}
    );
  };

  const transferirDocumentos = async (clienteOriginalId: string, clienteNuevoId: string) => {
    const documentosRef = collection(getFirestore(), `empresas/${userStore.empresa!.id}/documentos/`).withConverter(
      DocumentoConverter
    );
    const q = query(documentosRef, where("entidadId", "==", clienteOriginalId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        let documento = doc.data();
        documento = {...documento, entidadId: clienteNuevoId};
        editarDocumento(documento);
      });
    }
  };

  const getDocumentoById = async (id: string) => {
    try {
      const docRef = doc(db, collectionRoute, id).withConverter(DocumentoConverter);

      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        return docSnapshot.data();
      } else {
        return null;
      }
    } catch (e) {
      console.error("Error en la consulta: ", e);
      throw e;
    }
  };

  const getDocumentoByDocumentoId = async (id: string, tipoDocumento: string | null = null) => {
    //     | "venta"
    //     | "ventaNotaCredito"
    //     | "ventaNotaDebito"
    //     | "reciboCliente"
    //     | "compra"
    //     | "compraNotaCredito"
    //     | "reciboProveedor"
    //     | "ajusteagregardeudacliente"
    //     | "ajustequitardeudacliente"
    //     | "ajusteagregardeudaproveedor"
    //     | "ajustequitardeudaproveedor"
    //     | null = null
    let tipoDocumentoBusqueda = "";
    if (tipoDocumento != null) {
      if (tipoDocumento == "venta") {
        tipoDocumentoBusqueda = "venta";
      } else if (tipoDocumento == "devolucion") {
        tipoDocumentoBusqueda = "ventaNotaCredito";
      } else if (tipoDocumento == "notaDebito") {
        tipoDocumentoBusqueda = "ventaNotaDebito";
      } else if (tipoDocumento == "reciboCliente") {
        tipoDocumentoBusqueda = "reciboCliente";
      } else if (tipoDocumento == "compra" || tipoDocumento == "compraEliminar") {
        tipoDocumentoBusqueda = "compra";
      } else if (tipoDocumento == "devolucionCompra") {
        tipoDocumentoBusqueda = "compraNotaCredito";
      } else if (tipoDocumento == "reciboProveedor") {
        tipoDocumentoBusqueda = "reciboProveedor";
      } else if (tipoDocumento == "ajusteagregardeudacliente") {
        tipoDocumentoBusqueda = "ajusteagregardeudacliente";
      } else if (tipoDocumento == "ajustequitardeudacliente") {
        tipoDocumentoBusqueda = "ajustequitardeudacliente";
      } else if (tipoDocumento == "ajusteagregardeudaproveedor") {
        tipoDocumentoBusqueda = "ajusteagregardeudaproveedor";
      } else if (tipoDocumento == "ajustequitardeudaproveedor") {
        tipoDocumentoBusqueda = "ajustequitardeudaproveedor";
      }
    }
    try {
      const fichaQuerySnapshot = await getDocs(
        query(
          collection(db, collectionRoute).withConverter(DocumentoConverter),
          where("documentoId", "==", id),
          ...(tipoDocumento != null ? [where("tipoDocumento", "==", tipoDocumentoBusqueda)] : [])
        )
      );

      if (!fichaQuerySnapshot.empty) {
        const doc = fichaQuerySnapshot.docs[0];
        return doc.data();
      } else {
        return null;
      }
    } catch (e) {
      console.error("Error en la consulta: ", e);
      throw e;
    }
  };

  // const getDocumentoByDocumentoIdAfectante = async (
  //   id: string,
  //   entidad:
  //     | "reciboCliente"
  //     | "ventaNotaCredito"
  //     | "venta"
  //     | "ventaNotaDebito"
  //     | "compra"
  //     | "compraNotaCredito"
  //     | "reciboProveedor"
  //     | "ajusteagregardeudacliente"
  //     | "ajustequitardeudacliente"
  //     | "ajusteagregardeudaproveedor"
  //     | "ajustequitardeudaproveedor"
  //     | null = null
  // ) => {
  //   let tiposDocumentosPermitidos: string[] = [];
  //   if (entidad == "reciboCliente") {
  //     tiposDocumentosPermitidos = [
  //       "venta",
  //       "ventaNotaDebito",
  //       "ajusteagregardeudacliente",
  //       "reciboCliente" // anulacion de recibo
  //     ];
  //   } else if (entidad == "ventaNotaCredito") {
  //     tiposDocumentosPermitidos = [
  //       "venta",
  //       "ventaNotaDebito",
  //       "ajusteagregardeudacliente",
  //       "ventaNotaCredito"
  //     ];
  //   } else if (entidad == "compraNotaCredito") {
  //     tiposDocumentosPermitidos = ["compra", "ajusteagregardeudaproveedor", "compraNotaCredito"];
  //   } else if (entidad == "reciboProveedor") {
  //     tiposDocumentosPermitidos = ["compra", "ajusteagregardeudaproveedor", "reciboProveedor"];
  //   }

  //   try {
  //     const fichaQuerySnapshot = await getDocs(
  //       query(
  //         collection(db, collectionRoute).withConverter(DocumentoConverter),
  //         where("documentoId", "==", id),
  //         where("tipoDocumento", "in", tiposDocumentosPermitidos),
  //         ...(entidad != null
  //           ? [where("tipoDocumento", "in", tiposDocumentosPermitidos)]
  //           : [])
  //       )
  //     );

  //     if (!fichaQuerySnapshot.empty) {
  //       const doc = fichaQuerySnapshot.docs[0];
  //       return doc.data();
  //     } else {
  //       return null;
  //     }
  //   } catch (e) {
  //     console.error("Error en la consulta: ", e);
  //     throw e;
  //   }
  // };

  const getDocumentosByEntidad = async (idEtidad: string) => {
    let documentosDevolver: Documento[] = [];
    try {
      const fichaQuerySnapshot = await getDocs(
        query(
          collection(db, collectionRoute).withConverter(DocumentoConverter),
          where("entidadId", "==", idEtidad),
          where("formaDePago", "==", "credito"),
          orderBy("fechaComprobante", "asc"),
          orderBy("fechaCreacion", "asc")
        )
      );

      if (!fichaQuerySnapshot.empty) {
        fichaQuerySnapshot.forEach(doc => {
          // console.log("doc.data() =>", doc.data());
          documentosDevolver.push(doc.data());
        });
        return documentosDevolver;
      } else {
        return null;
      }
    } catch (e) {
      console.error("Error en la consulta: ", e);
      throw e;
    }
  };

  // const cancelarDocumentoParticular = async (
  //   documentoCancelante: Documento,
  //   documentoCancelado: Documento
  // ) => {
  //   // const documentoCancelado = await getDocumentoById(documentoCanceladoString);

  //   console.log(documentoCancelante);
  //   console.log(documentoCancelado);

  //   if (documentoCancelado != null && documentoCancelado != null) {
  //     for (const itemCancelante of documentoCancelante.pendienteCancelarCantidad) {
  //       // Busco el item correspondiente en el documento cancelado
  //       const elementoCancelado =
  //         documentoCancelado.pendienteCancelarCantidad.find(
  //           itemCancelado =>
  //             itemCancelado.productoId === itemCancelante.productoId
  //         );

  //       if (elementoCancelado) {
  //         // Resto la cantidad del documento cancelante al documento cancelado
  //         elementoCancelado.cantidadActual -= itemCancelante.cantidadActual;
  //       }
  //     }

  //     for (const itemCancelado of documentoCancelante.pendienteCancelarCantidad) {
  //       // Busco el índice del elemento correspondiente en el documento cancelante
  //       const index = documentoCancelado.pendienteCancelarCantidad.findIndex(
  //         itemCancelante =>
  //           itemCancelante.productoId === itemCancelado.productoId
  //       );

  //       if (index !== -1) {
  //         const cantidadRestante =
  //           documentoCancelante.pendienteCancelarCantidad[index]
  //             .cantidadActual - itemCancelado.cantidadActual;

  //         if (cantidadRestante <= 0) {
  //           // Si la cantidad restante es menor o igual a cero, elimina el elemento
  //           documentoCancelante.pendienteCancelarCantidad.splice(index, 1);
  //         } else {
  //           console.log(documentoCancelante.pendienteCancelarCantidad[index]);
  //           console.log(documentoCancelante.pendienteCancelarCantidad[index]);
  //           // Si la cantidad restante es mayor que cero, actualiza la cantidad en el elemento
  //           documentoCancelante.pendienteCancelarCantidad[
  //             index
  //           ].cantidadActual = cantidadRestante;
  //         }
  //       }
  //     }

  //     const docRefCancelante = doc(
  //       db,
  //       collectionRoute,
  //       documentoCancelante.id
  //     ).withConverter(DocumentoConverter);
  //     await setDoc(
  //       docRefCancelante,
  //       {
  //         version: 1,
  //         id: "",
  //         tipoDocumento: documentoCancelante.tipoDocumento,
  //         documentoId: documentoCancelante.documentoId,
  //         fechaComprobante: documentoCancelante.fechaComprobante,
  //         fechaComprobanteString: documentoCancelante.fechaComprobanteString,
  //         fechaCreacion: documentoCancelante.fechaCreacion,
  //         comprobante: documentoCancelante.comprobante,
  //         entidadId: documentoCancelante.entidadId,
  //         entidadNombre: documentoCancelante.entidadNombre,
  //         cancelado: documentoCancelante.cancelado,
  //         cancelo: documentoCancelante.cancelo,
  //         pendienteCancelarCantidad:
  //           documentoCancelante.pendienteCancelarCantidad,
  //         disponibleCancelarCantidad:
  //           documentoCancelante.disponibleCancelarCantidad,
  //         pendienteCancelarImporte:
  //           documentoCancelante.pendienteCancelarImporte,
  //         disponibleCancelarImporte:
  //           documentoCancelante.disponibleCancelarImporte,
  //         importe: documentoCancelante.importe,
  //         importePendiente: documentoCancelante.importePendiente,
  //         importeDisponible: documentoCancelante.importeDisponible,
  //         canceladoPor: documentoCancelante.canceladoPor,
  //         cancela: documentoCancelante.cancela,
  //         tipoCancelacionRecibida: documentoCancelante.tipoCancelacionRecibida,
  //         tipoCancelacionRealizada: documentoCancelante.tipoCancelacionRealizada
  //       },
  //       { merge: true }
  //     );

  //     for (let i: number = 0; i < documentos.value.length; i++) {
  //       if (documentos.value[i].id == documentoCancelante.id) {
  //         documentos.value[i] = documentoCancelante;
  //         break;
  //       }
  //     }

  //     const docRefCancelado = doc(
  //       db,
  //       collectionRoute,
  //       documentoCancelado.id
  //     ).withConverter(DocumentoConverter);
  //     await setDoc(
  //       docRefCancelado,
  //       {
  //         version: 1,
  //         id: "",
  //         tipoDocumento: documentoCancelado.tipoDocumento,
  //         documentoId: documentoCancelado.documentoId,
  //         fechaComprobante: documentoCancelado.fechaComprobante,
  //         fechaComprobanteString: documentoCancelado.fechaComprobanteString,
  //         fechaCreacion: documentoCancelado.fechaCreacion,
  //         comprobante: documentoCancelado.comprobante,
  //         entidadId: documentoCancelado.entidadId,
  //         entidadNombre: documentoCancelado.entidadNombre,
  //         cancelado: documentoCancelado.cancelado,
  //         cancelo: documentoCancelado.cancelo,
  //         pendienteCancelarCantidad:
  //           documentoCancelado.pendienteCancelarCantidad,
  //         disponibleCancelarCantidad:
  //           documentoCancelado.disponibleCancelarCantidad,
  //         pendienteCancelarImporte: documentoCancelado.pendienteCancelarImporte,
  //         disponibleCancelarImporte:
  //           documentoCancelado.disponibleCancelarImporte,
  //         importe: documentoCancelado.importe,
  //         importePendiente: documentoCancelado.importePendiente,
  //         importeDisponible: documentoCancelado.importeDisponible,
  //         canceladoPor: documentoCancelado.canceladoPor,
  //         cancela: documentoCancelado.cancela,
  //         tipoCancelacionRecibida: documentoCancelado.tipoCancelacionRecibida,
  //         tipoCancelacionRealizada: documentoCancelado.tipoCancelacionRealizada
  //       },
  //       { merge: true }
  //     );

  //     for (let i: number = 0; i < documentos.value.length; i++) {
  //       if (documentos.value[i].id == documentoCancelado.id) {
  //         documentos.value[i] = documentoCancelado;
  //         break;
  //       }
  //     }
  //   }
  //   return true;
  // };

  // const cancelarDocumentos = async (
  //   documentoCancelante: Documento,
  //   documentosCancelados: Documento[],
  //   relacionesCancelaciones: InterfazDocCancela[]
  // ) => {
  //   for (const relacion of relacionesCancelaciones) {
  //     relacion.cancelante = documentoCancelante.id;
  //   }

  //   // Actualizo idDocumentos cancelantes en relacionesCancelaciones
  //   const docRefCancelado = doc(
  //     db,
  //     collectionRoute,
  //     documentoCancelante.id
  //   ).withConverter(DocumentoConverter);
  //   await setDoc(
  //     docRefCancelado,
  //     {
  //       relacionCancelacionesRealizada: relacionesCancelaciones
  //     },
  //     { merge: true }
  //   );

  //   // Itero sobre cada relación de cancelación
  //   for (const relacionCancelacion of relacionesCancelaciones) {
  //     const {
  //       cancelante,
  //       cancelado,
  //       nroLineaCancelante,
  //       nroLineaCancelado,
  //       cantidad,
  //       importe
  //     } = relacionCancelacion;

  //     // Busco el documento cancelado en el array de documentosCancelados dado por parametro
  //     const documentoCancelado = documentosCancelados.find(
  //       doc => doc.id === cancelado
  //     );

  //     if (documentoCancelado) {
  //       // Armo array de tasas para cancelarImportes
  //       const arrayIVA: string[] = [];
  //       for (const linea of documentoCancelado.pendienteCancelarImporte) {
  //         if (!arrayIVA.includes(linea.tasaIVA)) {
  //           arrayIVA.push(linea.tasaIVA);
  //         }
  //       }

  //       if (documentoCancelante.tipoCancelacionRealizada == "cantidad") {
  //         // Actualizar las cantidades correspondientes en el documento cancelante y cancelado

  //         const cantidadCancelada = cantidad;

  //         // Actualizo cantidad
  //         documentoCancelado.pendienteCancelarCantidad[
  //           nroLineaCancelado
  //         ].cantidadActual -= cantidadCancelada;

  //         // Actualizo importe
  //         documentoCancelado.pendienteCancelarCantidad[
  //           nroLineaCancelado
  //         ].importe -=
  //           cantidadCancelada *
  //           documentoCancelado.pendienteCancelarCantidad[nroLineaCancelado]
  //             .precioUnitario;

  //         // Actualizo pendienteCancelarImportes dentro de cancelarCantidades
  //         let nroLinea = 0;
  //         for (const iva of arrayIVA) {
  //           if (
  //             iva ==
  //             documentoCancelado.pendienteCancelarCantidad[nroLineaCancelado]
  //               .tasaIVA
  //           ) {
  //             documentoCancelado.pendienteCancelarImporte[nroLinea].importe -=
  //               cantidadCancelada *
  //               documentoCancelado.pendienteCancelarCantidad[nroLineaCancelado]
  //                 .precioUnitario;
  //           }
  //           nroLinea++;
  //         }
  //       } else {
  //         // Es devolucion por importe
  //         // Actualizo solo cancelar importes, cancelar cantidades queda obsoleto

  //         const importeCancelado = importe;

  //         documentoCancelado.pendienteCancelarImporte[
  //           nroLineaCancelado
  //         ].importe -= importeCancelado;
  //       }

  //       // Actualizo tipo cancelacion recibida
  //       documentoCancelado.tipoCancelacionRecibida =
  //         documentoCancelante.tipoCancelacionRealizada;

  //       // Actualizar la información en el array de documentosCancelados
  //       const indiceDocumentoCancelado = documentosCancelados.findIndex(
  //         doc => doc.id === cancelado
  //       );
  //       documentosCancelados[indiceDocumentoCancelado] = documentoCancelado;
  //     }
  //   }

  //   for (const cancelado of documentosCancelados) {
  //     for (const relacion of relacionesCancelaciones) {
  //       if (relacion.cancelado == cancelado.id) {
  //         const relacionCancelacionRecibida: InterfazDocCancela = {
  //           cancelante: relacion.cancelante,
  //           cancelado: relacion.cancelado,
  //           nroLineaCancelante: relacion.nroLineaCancelante,
  //           nroLineaCancelado: relacion.nroLineaCancelado,
  //           cantidad: relacion.cantidad,
  //           importe: relacion.importe
  //         };
  //         cancelado.importePendiente -= relacion.importe;
  //         cancelado.relacionCancelacionesRecibida.push(
  //           relacionCancelacionRecibida
  //         );
  //         if (!cancelado.canceladoPor.includes(relacion.cancelante)) {
  //           cancelado.canceladoPor.push(relacion.cancelante);
  //         }
  //       }
  //     }

  //     if (cancelado.importePendiente == 0) {
  //       cancelado.cancelado = true;
  //     }

  //     const docRefCancelado = doc(
  //       db,
  //       collectionRoute,
  //       cancelado.id
  //     ).withConverter(DocumentoConverter);
  //     await setDoc(
  //       docRefCancelado,
  //       {
  //         version: 1,
  //         id: "",
  //         tipoDocumento: cancelado.tipoDocumento,
  //         documentoId: cancelado.documentoId,
  //         fechaComprobante: cancelado.fechaComprobante,
  //         fechaComprobanteString: cancelado.fechaComprobanteString,
  //         fechaCreacion: cancelado.fechaCreacion,
  //         comprobante: cancelado.comprobante,
  //         entidadId: cancelado.entidadId,
  //         entidadNombre: cancelado.entidadNombre,
  //         cancelado: cancelado.cancelado,
  //         cancelo: cancelado.cancelo,
  //         pendienteCancelarCantidad: cancelado.pendienteCancelarCantidad,
  //         disponibleCancelarCantidad: cancelado.disponibleCancelarCantidad,
  //         pendienteCancelarImporte: cancelado.pendienteCancelarImporte,
  //         disponibleCancelarImporte: cancelado.disponibleCancelarImporte,
  //         importe: cancelado.importe,
  //         importePendiente: cancelado.importePendiente,
  //         importeDisponible: cancelado.importeDisponible,
  //         canceladoPor: cancelado.canceladoPor,
  //         cancela: cancelado.cancela,
  //         tipoCancelacionRecibida: cancelado.tipoCancelacionRecibida,
  //         tipoCancelacionRealizada: cancelado.tipoCancelacionRealizada,
  //         relacionCancelacionesRecibida: cancelado.relacionCancelacionesRecibida
  //       },
  //       { merge: true }
  //     );

  //     for (let i: number = 0; i < documentos.value.length; i++) {
  //       if (documentos.value[i].id == cancelado.id) {
  //         documentos.value[i] = cancelado;
  //         break;
  //       }
  //     }
  //   }
  // };

  const desafectarDocumento = async (desafectar: InterfazDesafectarDocumento) => {
    try {
      ejecutarVaciarFiltros = false;
      const resultado = await documentoDesafectar({
        tipoDocumento: desafectar.tipoDocumento,
        documento: desafectar.documento,
        anular: desafectar.anular
      });
      return resultado;
    } catch (e) {
      throw e;
    }
  };

  const afectarDocumento = async (documentoId: string, documentos: InterfazDocumentoAfectado[]) => {
    ejecutarVaciarFiltros = false;
    try {
      const resultado = await documentoAfectar({
        idDocumento: documentoId,
        documentos: documentos
      });
      return resultado;
    } catch (e) {
      throw e;
    }
  };

  const getDocumentosDevolucion = async (filtro: any) => {
    if (filtro.tipoDocumento.length == 0) {
      return [];
    }

    const q = query(
      collection(db, `empresas/${userStore.empresa!.id}/documentos/`),
      where("entidadId", "==", filtro.entidad),
      // ...(filtro.moneda != "" ? [where("moneda", "==", filtro.moneda)] : []),
      ...(filtro.tipoDocumento.length > 0
        ? [where("tipoDocumento", "in", filtro.tipoDocumento)]
        : [where("tipoDocumento", "==", "")]),
      where("moneda", "==", filtro.monedaCuentaAfectada),
      where("formaDePago", "==", filtro.formaDePago),
      where("anulado", "==", false),
      orderBy("fechaComprobante", "asc"),
      orderBy("fechaCreacion", "asc")
    ).withConverter(DocumentoConverter);
    const querySnapshot = await getDocs(q);

    const devolverDocumentos: Ref<Documento[]> = ref([]);

    querySnapshot.forEach(doc => {
      const documento = doc.data();
      if (documento.importePendiente > 0.001) {
        devolverDocumentos.value.push(documento);
      }
    });
    if (devolverDocumentos.value.length > 0) return devolverDocumentos.value;
    else return [];
  };

  const filtrarDocumentos = async () => {
    ultimaCargaVacia = false;
    cargando.value = true;
    documentos.value = [];
    last = null;
    mostrar = 200;
    let i = 0;
    while (ultimaCargaVacia == false) {
      await getDocumentos();
      i++;
      if (i >= 50) {
        console.log("Corto el while");
        break;
      }
    }
    getDocumentosReordenarReCalcular();
    cargando.value = false;
    mostrar = 10;
  };

  const getDocumentosReordenarReCalcular = () => {
    let nroComprobanteMinimo: number = filtros.value.nroComprobanteMinimo;
    let nroComprobanteMaximo: number = filtros.value.nroComprobanteMaximo;
    if (nroComprobanteMaximo == 0) {
      nroComprobanteMaximo = 9999999999999;
    }

    if (nroComprobanteMinimo !== 0) {
      documentos.value = documentos.value.filter(doc => {
        if (doc.nroComprobante !== null && doc.nroComprobante !== undefined) {
          return doc.nroComprobante >= nroComprobanteMinimo;
        }
        return false;
      });
    }
    if (nroComprobanteMaximo !== 0) {
      documentos.value = documentos.value.filter(doc => {
        if (doc.nroComprobante !== null && doc.nroComprobante !== undefined) {
          return doc.nroComprobante <= nroComprobanteMaximo;
        }
        return false;
      });
    }

    // Elimino los documentos repetidas, por si alguna se cargo 2 veces
    documentos.value = Object.values(
      documentos.value.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {} as {[key: string]: Documento})
    );

    documentos.value.sort((a, b) => {
      if (a.fechaCreacion === b.fechaCreacion) {
        return b.fechaComprobante - a.fechaComprobante;
      }
      return b.fechaCreacion - a.fechaCreacion;
    });
    cargando.value = false;
  };

  const getDocumentosSaldosByEntidad = async (idEtidad: string, moneda: string) => {
    let documentosDevolver: Documento[] = [];
    try {
      const fichaQuerySnapshot = await getDocs(
        query(
          collection(db, collectionRoute).withConverter(DocumentoConverter),
          where("entidadId", "==", idEtidad),
          where("formaDePago", "==", "credito"),
          where("moneda", "==", moneda),
          orderBy("fechaComprobante", "asc"),
          orderBy("fechaCreacion", "asc")
        )
      );

      if (!fichaQuerySnapshot.empty) {
        fichaQuerySnapshot.forEach(doc => {
          documentosDevolver.push(doc.data());
        });
        return documentosDevolver;
      } else {
        return null;
      }
    } catch (e) {
      console.error("Error en la consulta: ", e);
      throw e;
    }
  };

  return {
    init,
    documentos,
    getDocumentos,
    cargando,
    filtros,
    addDocumento,
    editarDocumento,
    transferirDocumentos,
    showExitoMsg,
    buscarDocumentos,
    buscar,
    verInactivo,
    montoTotal,
    montoSubtotal,
    montoIVA,
    getDocumentoById,
    getDocumentoByDocumentoId,
    desafectarDocumento,
    afectarDocumento,
    getDocumentosDevolucion,
    getDocumentosByEntidad,
    filtrarDocumentos,
    filtrado,
    ejecutarVaciarFiltros,
    getDocumentosSaldosByEntidad
    // cancelarDocumentoParticular,
    // cancelarDocumentos
  };
});
