import { defineStore } from "pinia";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { Cotizacion, cotizacionConverter } from "@fixeduy/fixedtypes";
import { useMonedasStore } from "./monedas";

// Modulo de las cotizaciones a utilizar por la empresa
// Si quiero hacer una factura con fecha 20/9
// traera de la db la cotización con fecha 19/9 o posterior
// según la que necesite. En la DB se guarda la cotización
// del cierre para ese día.

export const useCotizacionesBCUStore = defineStore({
  id: "cotizacionesBCU",
  persist: true,
  state: () => {
    return {
      cotizaciones: {} as { [key: string]: Cotizacion }
    };
  },
  getters: {},
  actions: {
    /***
     * Carga todas las cotizaciones del BCU para la fecha dada
     * En caso de no haber cotización para ese día, carga del día anterior
     * @param fechaUsar En formato aaaa-mm-dd
     * @returns cotizaciones
     */
    async getCotizacion(
      fechaUsar: string,
      codigoBCU: string
    ): Promise<Cotizacion> {
      const regex = /^(19|20)\d\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
      if (!regex.test(fechaUsar)) {
        return {
          codigoBcu: codigoBCU,
          fecha: fechaUsar,
          cotizacion: 1,
        };

        // throw new Error(
        //   `La fecha ${fechaUsar} recibida no es en formato aaaa-mm-dd`
        // );
      }
      // const fecha = getPreviousDay(fechaUsar);

      const clave = `${fechaUsar}_${codigoBCU}`;

      if (clave in this.cotizaciones) {
        return this.cotizaciones[clave];
      }

      let diaBuscar = fechaUsar;
      diaBuscar = getPreviousDay(diaBuscar);

      // Voy a buscar la cotización del día para esa moneda
      const db = getFirestore();

      // repetir
      let tope = 0;
      while (true && tope < 30) {
        const claveBuscar = `${diaBuscar}_${codigoBCU}`;
        const docRef = doc(db, "cotizaciones", claveBuscar).withConverter(
          cotizacionConverter
        );
        const docSnap = await getDoc(docRef);
        const cotizaciones = docSnap.data();

        if (cotizaciones != null) {
          this.cotizaciones[clave] = cotizaciones;
          return this.cotizaciones[clave];
        }
        // Busco el dia anterior
        diaBuscar = getPreviousDay(diaBuscar);
        tope++;
      }

      const c: Cotizacion = {
        codigoBcu: codigoBCU,
        fecha: fechaUsar,
        cotizacion: 1
      }

      return c;

      // throw new Error("Error obteniendo cotizaciones del BCU");
    }
  }
});
function getPreviousDay(dateStr: string): string {
  // Convert the input string to a Date object
  const date = new Date(dateStr);

  // Subtract one day from the date
  date.setDate(date.getDate() - 1);

  // Format the date back to a string in yyyy-mm-dd format
  const previousDay = date.toISOString().split("T")[0];

  return previousDay;
}
