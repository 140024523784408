import { defineStore } from "pinia";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  addDoc,
  doc,
  setDoc
} from "firebase/firestore";
import { userUserStore } from "../stores/user";
import { Local, localConverter } from "@fixeduy/fixedtypes";

export const useLocalesStore = defineStore({
  id: "locales",
  persist: true,
  state: () => {
    return {
      _locales: [] as Local[],
      _localesDict: {} as { [key: string]: Local },
      cargando: false
    };
  },
  getters: {
    locales(): Local[] {
      return this._locales;
    },
    localesActivos(): Local[] {
      return this._locales.filter(local => local.activo);
    },
    getUltimoLocalEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this._locales.map(r => {
        if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
          mayorFechaUltimaEdicion = r.ultimaEdicion;
        }
      });
      return mayorFechaUltimaEdicion;
    },
  },
  actions: {
    async getLocalesFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/locales/`;
      const mayorFechaUltimaEdicion = this.getUltimoLocalEdicion;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(
            collection(db, collectionRoute),
            where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)
          ).withConverter(localConverter)
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addLocalToStore(doc.data());
          });
          if (this.localesActivos.length == 1) {
            userStore.setLocalActual(this.localesActivos[0]);
          }
          resolve();
        });
      });
    },

    addLocalToStore(local: Local) {
      this._localesDict[local.id] = local;
      // reviso que no exista
      for (var i = 0; i < this._locales.length; i++) {
        if (this._locales[i].id == local.id) {
          this._locales[i] = local;
          return; // corto ejecucion de la funcion
        }
      }
      // lo inserto y ordeno
      this._locales.push(local);
      this._locales.sort((a, b) => (b.nombre > a.nombre ? -1 : 1));
    },

    async crearLocal(local: Local) {
      try {
        this.cargando = true;
        const db = getFirestore();
        const userStore = userUserStore();
        const collectionRoute = `empresas/${userStore.empresa!.id}/locales/`;

        const docRef = doc(collection(db, collectionRoute));
        local.id = docRef.id;
        await setDoc(docRef, local);
        this.addLocalToStore(local);

        setDoc(
          doc(db, "empresas", userStore.empresa!.id),
          {
            ultimaEdicionLocales: new Date().getTime()
          },
          { merge: true }
        );
        this.cargando = false;

        return true;

      } catch (error) {
        console.error(error);
      }
    },

    getLocalById(id: string): Local | null {
      for (var i = 0; i < this._locales.length; i++) {
        if (this._locales[i].id == id) {
          return this._locales[i];
        }
      }
      return null;
    },

    async editarLocal(local: Local) {
      this.cargando = true;
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/locales/`;
      const docRef = doc(db, collectionRoute, local.id);

      await setDoc(
        docRef,
        {
          nombreComercial: local.nombreComercial,
          nombre: local.nombre,
          direccion: local.direccion,
          localidad: local.localidad,
          departamento: local.departamento,
          codigoDgi: local.codigoDgi,
          logo: local.logo,
          logoURL: local.logoURL,
          activo: local.activo,
          ultimaEdicion: local.ultimaEdicion
        },
        { merge: true }
      );

      if (!local.activo) {
        this._locales = this._locales.filter(local => local.activo);
      } else {
        for (let i = 0; i < this._locales.length; i++) {
          if (this._locales[i].id == local.id) {
            this._locales[i] = local;
            break;
          }
        }
      }

      setDoc(
        doc(db, "empresas", userStore.empresa!.id),
        {
          ultimaEdicionLocales: new Date().getTime()
        },
        { merge: true }
      );
      this.cargando = false;

      return true;
    }
  }
});
