import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "./getFunctions";
import {
  CloudFunctionResponse,
  EstablecerContrasenaUsuario,
  CallablesGeneral
} from "@fixeduy/fixedtypes";

const establecerContrasena = async (
  data: EstablecerContrasenaUsuario
): Promise<CloudFunctionResponse> => {
  const run = httpsCallable<CallablesGeneral, CloudFunctionResponse>(
    cloudFunctions,
    "callablesGenerales"
  );
  const result = await run({
    func: "establecerContrasena",
    params: data
  });
  return result.data;
};

export { establecerContrasena };
