<script setup lang="ts">
import Button from "../../base-components/Button";
import {FormInput, FormTextarea} from "../../base-components/Form";
import Lucide from "../../base-components/Lucide";
import {Menu, Tab} from "../../base-components/Headless";

import LoadingIcon from "../../base-components/LoadingIcon";
import {Ref, onBeforeMount, ref, nextTick, watch, onMounted} from "vue";
import {userUserStore} from "../../stores/user";
import ModalChatAbrirImagen from "./modals/ModalChatAbrirImagen.vue";

import {
  Chat,
  ChatMensaje,
  EnviarMensajeChat,
  chatConverter,
  chatMensajeConverter,
  utilFechaConvertirNumeroString,
  utilFechaGetCurrentTime
} from "@fixeduy/fixedtypes";
import {collection, doc, getDocs, getFirestore, query, setDoc, where, writeBatch} from "firebase/firestore";
import {getDownloadURL, getStorage, ref as storageRef, uploadBytes} from "firebase/storage";
import router from "../../router";
import {RouteRecordName} from "vue-router";
import {enviarMensajeChat} from "../../services/cloudFunctions/enviarMensajeChat";

const userStore = userUserStore();
const formMensaje = ref("");
const db = getFirestore();
const autorUserId = userStore.userLoggedIn?.uid ?? "";
const autorNombre = userStore.usuario?.nombre ?? "";
const archivoAdjunto: Ref<any> = ref(null);
const refAdjunto = ref("");
const storage = getStorage();
const cargando = ref(false);
const enChat = ref(false);
const paginaActual: Ref<RouteRecordName | null | undefined> = ref(null);

const emit = defineEmits<{
  (e: "cerrar"): void;
  (e: "cargar"): void;
}>();

enum showModalType {
  none,
  AbrirImagen
}

onBeforeMount(() => {
  router.beforeEach(async (to, from) => {
    const currentRouteName = to.name;
    const currentRoutePath = to.path;
    paginaActual.value = currentRouteName;
  });
});

const showModal = (imagen: any, modalShow: showModalType) => {
  modal.value = modalShow;
  imagenSeleccionada.value = imagen;
};

const cerrarModal = () => {
  modal.value = showModalType.none;
  imagenSeleccionada.value = null;
};

const modal = ref<showModalType>(showModalType.none);
const imagenSeleccionada = ref<any | null>(null);

const enviarMensaje = async () => {
  // return;
  if (!cargando.value && (formMensaje.value != "" || archivoAdjunto.value)) {
    cargando.value = true;
    let urlAdjunto = "";
    if (archivoAdjunto.value) {
      const archivoRef = storageRef(storage, `chat/${userStore.user?.uid}/${archivoAdjunto.value.name}`);

      const snapshot = await uploadBytes(archivoRef, archivoAdjunto.value);
      refAdjunto.value = snapshot.ref.fullPath;

      urlAdjunto = await getDownloadURL(storageRef(storage, refAdjunto.value));
    }

    // const chat: Chat = {
    //   id: autorUserId,
    //   sinLeerCliente: false,
    //   sinLeerFixed: true,
    //   empresaId: userStore.empresa?.id ?? "",
    //   fecha: utilFechaGetCurrentTime(),
    //   ultimoMensaje: formMensaje.value,
    //   vistoCliente: true,
    //   vistoFixed: [],
    //   notaPrivada: false,
    //   nombreCliente: autorNombre,
    //   nombreEmpresa: userStore.empresa?.nombreComercial ?? "",
    //   urlImagenCliente: userStore.user?.photoURL ?? "",
    //   notaPrivadaFija: "",
    //   escribiendo: false
    // };
    const mensaje: ChatMensaje = {
      id: "",
      enviadoPorFixed: false,
      autorNombre: autorNombre,
      autorUserId: autorUserId,
      fecha: utilFechaGetCurrentTime(),
      mensaje: formMensaje.value,
      vistoCliente: true,
      vistoFixed: {},
      notaPrivada: false,
      empresaId: userStore.empresa?.id ?? "",
      urlImagenRemitente: userStore.user?.photoURL ?? "",
      refAdjunto: refAdjunto.value,
      urlAdjunto: urlAdjunto,
      nombreAdjunto: archivoAdjunto.value != null ? archivoAdjunto.value.name : "",
      tamanioAdjunto:
        archivoAdjunto.value != null
          ? String(archivoAdjunto.value.size)
          : // ? String(round(archivoAdjunto.value.size / 1000, 1))
            "",
      resuelto: null,
      reabierto: null,
      clienteId: autorUserId
    };

    const enviarMensaje: EnviarMensajeChat = {
      mensaje: mensaje,
      nombreUsuario: autorNombre,
      nombreEmpresa: userStore.empresa?.nombreComercial ?? "",
      fotoUsuario: userStore.user?.photoURL ?? ""
    };

    await enviarMensajeChat(enviarMensaje);

    formMensaje.value = "";
    archivoAdjunto.value = null;
    refAdjunto.value = "";
    cargando.value = false;
  }
};

onMounted(() => {
  nextTick(() => {
    if (chatContainer.value) {
      chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
    }
  });
});

const chatContainer: Ref<HTMLElement | null> = ref(null);

const scrollToBottomChat = () => {
  nextTick(() => {
    if (chatContainer.value) {
      chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
    }
  });
};

watch(
  () => userStore.chatMensajes.length,
  (newLength, oldLength) => {
    scrollToBottomChat();
  }
);

// onBeforeMount(() => {
//   userStore.listenerChatMensajes();
// });

const adjuntarArchivo = (event: any) => {
  const file = event.target.files?.[0];
  console.log(file);
  if (file) {
    archivoAdjunto.value = file;
  }
};

const borrarAdjunto = () => {
  archivoAdjunto.value = null;
};

const pegarDesdePortapapeles = (event: ClipboardEvent) => {
  const items = Array.from(event.clipboardData?.items || []);

  items.forEach(item => {
    if (item.type.startsWith("image/")) {
      const blob = item.getAsFile();
      if (blob) {
        const reader = new FileReader();
        reader.onload = async e => {
          const base64Image = e.target?.result as string;
          const file = dataURItoFile(base64Image, "pasted_image.png");
          archivoAdjunto.value = file;
        };
        reader.readAsDataURL(blob);
      }
    }
  });
};

function dataURItoFile(dataURI: string, filename: string): File {
  const arr = dataURI.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1] || "image/png";
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type: mime});
}

const descargarArchivo = async (refAdjunto: any) => {
  try {
    const downloadURL = await getDownloadURL(storageRef(storage, refAdjunto));
    window.open(downloadURL, "_blank");
  } catch (error) {}
};

watch(
  () => enChat.value,
  newValue => {
    if (enChat.value) {
      console.log("Entra aca a watch");
      actualizarVistoMensajes(userStore.empresa?.id!, autorUserId);
      actualizarVisto(userStore.empresa?.id!, autorUserId);
      if (formMensaje.value != "") {
        actualizarEscribiendoTrue(userStore.empresa?.id!, autorUserId);
      } else {
        actualizarEscribiendoFalse(userStore.empresa?.id!, autorUserId);
      }
    } else {
      actualizarEscribiendoFalse(userStore.empresa?.id!, autorUserId);
    }
  }
);

watch(
  () => formMensaje.value,
  newValue => {
    if (enChat.value) {
      actualizarVistoMensajes(userStore.empresa?.id!, autorUserId);
      actualizarVisto(userStore.empresa?.id!, autorUserId);
      if (formMensaje.value != "") {
        actualizarEscribiendoTrue(userStore.empresa?.id!, autorUserId);
      } else {
        actualizarEscribiendoFalse(userStore.empresa?.id!, autorUserId);
      }
    } else {
      actualizarEscribiendoFalse(userStore.empresa?.id!, autorUserId);
    }
  }
);

const actualizarVistoMensajes = async (idEmpresa: string, idChat: string) => {
  const q = query(collection(db, `chats/${idEmpresa}-${idChat}/mensajes`), where("vistoCliente", "==", false));

  const querySnapshot = await getDocs(q);

  const batch = writeBatch(db);

  querySnapshot.forEach(doc => {
    batch.update(doc.ref, {vistoCliente: true});
  });

  await batch.commit();

  return true;
};

const actualizarVisto = async (idEmpresa: string, idChat: string) => {
  if (userStore.chatMensajes.length > 0) {
    const docChatRef = doc(db, `chats/${idEmpresa}-${idChat}`).withConverter(chatConverter);
    await setDoc(
      docChatRef,
      {
        sinLeerCliente: false
      },
      {merge: true}
    );
    return true;
  }
};
const actualizarEscribiendoTrue = async (idEmpresa: string, idChat: string) => {
  if (userStore.chatMensajes.length > 0) {
    const docChatRef = doc(db, `chats/${idEmpresa}-${idChat}`).withConverter(chatConverter);
    await setDoc(
      docChatRef,
      {
        escribiendoCliente: true
      },
      {merge: true}
    );

    return true;
  }
};

const actualizarEscribiendoFalse = async (idEmpresa: string, idChat: string) => {
  if (userStore.chatMensajes.length > 0) {
    const docChatRef = doc(db, `chats/${idEmpresa}-${idChat}`).withConverter(chatConverter);
    await setDoc(
      docChatRef,
      {
        escribiendoCliente: false
      },
      {merge: true}
    );

    return true;
  }
};
const handleKeydown = (event: any) => {
  if (event.key === "Enter" && event.shiftKey) {
    event.preventDefault();
    formMensaje.value += "\n";
  } else if (event.key === "Enter") {
    event.preventDefault();
    enviarMensaje();
  }
};

const esEnlace = (texto: string) => {
  // Expresión regular para detectar enlaces en el texto
  const regex = /\b(?:https?|http):\/\/\S+/gi;
  return regex.test(texto);
};
// Función para formatear el mensaje
const formatearMensaje = (mensaje: string) => {
  // Expresión regular para detectar enlaces
  const regex = /(\b(?:https?|http):\/\/\S+)/gi;

  // Reemplaza los enlaces con un HTML de enlace
  return mensaje.replace(regex, match => {
    return `<a href="${match}" target="_blank" style="color: cornflowerblue;">${match}</a>`;
  });
};

const esImagen = (nombreAdjunto: string) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp", "avif"];
  const extension = nombreAdjunto.split(".").pop()?.toLowerCase() ?? "";
  return imageExtensions.includes(extension);
};
</script>

<template>
  <!-- BEGIN: Chat Content -->
  <div
    :class="{
      'fixed bottom-3 z-[100] min-h-[400px] box': true,
      'right-3': userStore.alineacionChat == 'derecha',
      'left-3': userStore.alineacionChat == 'izquierda',
      'w-[35%] h-[calc(100vh-100px)] min-w-[300px]': userStore.tamanoChat == 'grande',
      'w-[35%] h-[500px] min-w-[300px]': userStore.tamanoChat == 'chico'
    }"
  >
    <div class="flex pt-2 px-3">
      <div class="flex-1">
        <h4 class="text-xl font-medium leading-none mt-2">Chat de ayuda</h4>
      </div>
      <div class="flex-none w-14">
        <Button variant="secondary" @click="userStore.toggleTamano">
          <Lucide :icon="userStore.tamanoChat == 'chico' ? 'ZoomIn' : 'ZoomOut'" class="w-5 h-5" />
        </Button>
      </div>
      <div class="flex-none w-14">
        <Button variant="secondary" @click="userStore.toggleAlineacion">
          <Lucide :icon="userStore.alineacionChat == 'izquierda' ? 'ArrowRight' : 'ArrowLeft'" class="w-5 h-5" />
        </Button>
      </div>
      <div class="flex-none w-10">
        <Button variant="secondary" @click="() => userStore.cerrarChat()">
          <Lucide icon="ChevronDown" class="w-5 h-5" />
        </Button>
      </div>
    </div>
    <hr class="mt-3" />
    <!-- BEGIN: Chat Active -->
    <div class="flex flex-col h-full">
      <div
        class="flex-1 px-3 overflow-y-auto scrollbar-thin scrollbar-thumb-primary scrollbar-track-gray-100 dark:scrollbar-track-gray-800 dark:scrollbar-thumb-primary-dark"
        style="max-height: calc(100% - 180px)"
        ref="chatContainer"
      >
        <div class="hidden">
          {{ userStore.chatMensajes }}
        </div>
        <template v-for="chat in userStore.chatMensajes" :key="chat.id">
          <div
            v-if="chat.enviadoPorFixed"
            class="flex items-end mb-1 break-words"
            :title="utilFechaConvertirNumeroString(chat.fecha, true, '/')"
          >
            <div
              v-if="chat.urlImagenRemitente && (chat.refAdjunto != '' || chat.mensaje != '')"
              class="relative flex-none hidden w-6 h-6 mr-2 sm:block image-fit"
            >
              <img alt="Foto perfil" class="rounded-full" :src="chat.urlImagenRemitente" :title="chat.autorNombre" />
            </div>
            <div
              class="px-2 py-1 bg-slate-100 dark:bg-darkmode-400 text-slate-700 dark:text-slate-100 rounded-r-md rounded-t-md"
              v-if="chat.refAdjunto != '' || chat.mensaje != ''"
            >
              <div v-if="esEnlace(chat.mensaje)">
                <div v-html="formatearMensaje(chat.mensaje)"></div>
                <!-- <a :href="chat.mensaje" target="_blank" style="color: cornflowerblue">{{ chat.mensaje }}</a> -->
              </div>
              <div v-else style="white-space: pre-line">
                {{ chat.mensaje }}
              </div>
              <div v-if="chat.refAdjunto != ''" class="text-center">
                <hr style="border-width: 2px" />
                <div v-if="esImagen(chat.nombreAdjunto)">
                  <img
                    @click="showModal(chat.urlAdjunto, showModalType.AbrirImagen)"
                    :src="chat.urlAdjunto"
                    style="cursor: pointer; max-height: 150px"
                    alt="Adjunto"
                  />
                </div>
                <div class="mt-2" @click="descargarArchivo(chat.urlAdjunto)" style="cursor: pointer">
                  <div class="flex">
                    <Lucide icon="PaperclipIcon" class="mr-2" />
                    <span> {{ chat.nombreAdjunto }}</span>
                  </div>
                  <div class="flex">
                    <span v-if="Number(chat.tamanioAdjunto) >= 1000000">
                      {{ (Number(chat.tamanioAdjunto) / 1000000).toFixed(1) }}
                      MB |
                    </span>
                    <span v-else-if="Number(chat.tamanioAdjunto) >= 1000">
                      {{ (Number(chat.tamanioAdjunto) / 1000).toFixed(1) }} KB |
                    </span>
                    <span v-else> {{ Number(chat.tamanioAdjunto) }} bytes | </span>
                    <span class="text-blue-500"> Descargar</span>
                  </div>
                </div>
              </div>
              <!-- <div class="mt-1 text-xs text-slate-500 text-left">
                {{ utilFechaConvertirNumeroString(chat.fecha, true, "/") }}
              </div> -->
            </div>
          </div>
          <div
            v-else
            class="flex justify-end items-end mb-1 break-words"
            :title="utilFechaConvertirNumeroString(chat.fecha, true, '/')"
          >
            <div
              class="px-2 py-1 text-white bg-primary rounded-l-md rounded-t-md text-right"
              v-if="chat.refAdjunto != '' || chat.mensaje != ''"
            >
              <div v-if="esEnlace(chat.mensaje)">
                <div v-html="formatearMensaje(chat.mensaje)"></div>
                <!-- <a :href="chat.mensaje" target="_blank" style="color: cornflowerblue">{{ chat.mensaje }}</a> -->
              </div>
              <div v-else style="white-space: pre-line">{{ chat.mensaje }}</div>
              <div v-if="chat.refAdjunto != ''" class="text-center">
                <hr style="border-width: 2px" />
                <div v-if="esImagen(chat.nombreAdjunto)">
                  <img
                    @click="showModal(chat.urlAdjunto, showModalType.AbrirImagen)"
                    :src="chat.urlAdjunto"
                    style="cursor: pointer; max-height: 150px"
                    alt="Adjunto"
                  />
                </div>
                <div class="mt-1" @click="descargarArchivo(chat.urlAdjunto)" style="cursor: pointer">
                  <div class="flex">
                    <Lucide icon="PaperclipIcon" class="mr-2" />
                    <span> {{ chat.nombreAdjunto }}</span>
                  </div>
                  <div class="flex">
                    <span v-if="Number(chat.tamanioAdjunto) >= 1000000">
                      {{ (Number(chat.tamanioAdjunto) / 1000000).toFixed(1) }}
                      MB |
                    </span>
                    <span v-else-if="Number(chat.tamanioAdjunto) >= 1000">
                      {{ (Number(chat.tamanioAdjunto) / 1000).toFixed(1) }} KB |
                    </span>
                    <span v-else> {{ Number(chat.tamanioAdjunto) }} bytes | </span>
                    <span class="text-blue-500"> Descargar</span>
                  </div>
                </div>
              </div>
              <!-- <div class="mt-1 text-xs text-slate-500 text-left">
                {{ utilFechaConvertirNumeroString(chat.fecha, true, "/") }}
              </div> -->
            </div>
            <div v-if="chat.urlImagenRemitente" class="relative flex-none w-6 h-6 ml-2 mb-1 sm:block image-fit">
              <img alt="Foto perfil" class="rounded-full" :src="chat.urlImagenRemitente" />
            </div>
          </div>
        </template>
      </div>

      <div class="w-full flex border-t border-slate-200/60 dark:border-darkmode-400 p-2" v-if="archivoAdjunto != null">
        <span
          v-if="archivoAdjunto"
          class="ms-1 text-blue-500"
          style="max-width: 280px; overflow: hidden; text-overflow: ellipsis; cursor: pointer"
        >
          {{ archivoAdjunto.name }}
        </span>
        <span style="cursor: pointer" @click="borrarAdjunto" class="text-danger"
          ><Lucide icon="Trash2Icon" class="ml-2" />
        </span>
      </div>
      <!-- END: Chat Active -->
      <!-- BEGIN: Send new chat -->
      <div class="w=full flex items-center pt-2 border-t border-slate-200/60 dark:border-darkmode-400 mt-2">
        <!-- v-if="!cargando" -->
        <FormTextarea
          class="px-2 py-3 border-transparent shadow-none resize-none h-[100%] dark:bg-darkmode-600 focus:border-transparent focus:ring-0"
          :rows="4"
          placeholder="Escribe un mensaje..."
          v-model="formMensaje"
          @keydown="handleKeydown"
          @paste="pegarDesdePortapapeles"
          @focus="enChat = true"
          @blur="enChat = false"
        />
        <div class="relative w-4 h-4 mr-3 sm:w-5 sm:h-5 text-slate-500 sm:mr-5">
          <Lucide icon="Paperclip" class="w-full h-full" />
          <input type="file" class="absolute top-0 left-0 w-full h-full opacity-0" @change="adjuntarArchivo" />
        </div>
        <a
          href="#"
          class="flex items-center justify-center flex-none w-8 h-8 mr-5 text-white rounded-full sm:w-10 sm:h-10 bg-primary"
        >
          <Lucide icon="Send" class="w-4 h-4" @click="enviarMensaje" />
        </a>
      </div>
      <!-- <div v-else>
        <div
          class="flex items-center px-5 py-4 border-t border-slate-200/60 dark:border-darkmode-400"
        >
          <div class="mx-auto">
            <LoadingIcon icon="oval" class="w-8 h-8" />
          </div>
        </div>
      </div> -->
    </div>

    <!-- END: Send new chat -->
  </div>
  <!-- END: Chat Content -->
  <ModalChatAbrirImagen
    v-if="modal == showModalType.AbrirImagen && imagenSeleccionada != null"
    :imagen="imagenSeleccionada"
    @cerrar="cerrarModal"
    @cargar="() => emit('cargar')"
  />
</template>
<style scoped>
.break-words {
  overflow-wrap: break-word;
  word-break: break-word;
}
.break-all {
  word-break: break-all;
}
</style>
