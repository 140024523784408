import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "./getFunctions";
import {
  CloudFunctionResponse,
  ActualizarUsuarioDeEmpresa,
  CallablesGeneral
} from "@fixeduy/fixedtypes";

const actualizarUsuarioDeEmpresa = async (
  data: ActualizarUsuarioDeEmpresa
): Promise<CloudFunctionResponse> => {
  const run = httpsCallable<CallablesGeneral, CloudFunctionResponse>(
    cloudFunctions,
    "callablesGenerales"
  );
  const result = await run({
    func: "actualizarUsuarioDeEmpresa",
    params: data
  });
  return result.data;
};

export { actualizarUsuarioDeEmpresa };
