<script setup lang="ts">
import Button from "./base-components/Button";
import Lucide from "./base-components/Lucide";
import Notification from "./base-components/Notification";
import {NotificationElement} from "./base-components/Notification";
import {ref, provide} from "vue";
import {useMensajesStore} from "./services/mensajes";
import {storeToRefs} from "pinia";
import {Dialog} from "./base-components/Headless";

const mensajesStore = useMensajesStore();
const {
  toastTitulo: toastTitulo,
  toastDetalle: toastDetalle,
  tipoToast: tipoToast,
  modalTitulo: modalTitulo,
  modalDetalle: modalDetalle,
  modalTipo: modalTipo,
  showModal: showModal
} = storeToRefs(mensajesStore);

provide("bind[successNotification]", (el: NotificationElement) => {
  mensajesStore.toastNotification = el;
});

const deleteModalPreview = ref(false);
const setDeleteModalPreview = (value: boolean) => {
  deleteModalPreview.value = value;
};
</script>

<template>
  <RouterView />

  <!-- BEGIN: Notification Content -->
  <Notification
    refKey="successNotification"
    class="flex"
    :options="{
      duration: mensajesStore.duracion
    }"
  >
    <Lucide
      :icon="tipoToast == mensajesStore.mensajeType.exito ? 'CheckCircle' : 'XCircle'"
      :class="{
        'text-success': tipoToast == mensajesStore.mensajeType.exito,
        'text-danger': tipoToast == mensajesStore.mensajeType.error
      }"
    />
    <div class="ml-4 mr-4">
      <div
        class="font-medium"
        :class="{
          'text-danger': tipoToast == mensajesStore.mensajeType.error
        }"
      >
        {{ toastTitulo }}
      </div>
      <div class="mt-1 text-slate-500">
        {{ toastDetalle }}
      </div>
    </div>
  </Notification>
  <!-- END: Notification Content -->

  <!-- BEGIN: Modal Content -->
  <Dialog :open="showModal" @close="mensajesStore.cerrarModal">
    <Dialog.Panel>
      <div class="p-5 text-center">
        <Lucide
          :icon="modalTipo == mensajesStore.mensajeType.exito ? 'CheckCircle' : 'XCircle'"
          class="w-16 h-16 mx-auto mt-3"
          :class="{
            'text-success': modalTipo == mensajesStore.mensajeType.exito,
            'text-danger': modalTipo == mensajesStore.mensajeType.error
          }"
        />
        <div class="mt-5 text-3xl">{{ modalTitulo }}</div>
        <div class="mt-2 text-slate-500">
          {{ modalDetalle }}
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <Button type="button" variant="outline-secondary" @click="mensajesStore.cerrarModal" class="w-24 mr-1">
          Cerrar
        </Button>
      </div>
    </Dialog.Panel>
  </Dialog>
  <!-- END: Modal Content -->
</template>
