import { defineStore } from "pinia";
import {
  collection,
  query,
  getFirestore,
  getDocs,
  limit,
  doc,
  deleteDoc,
  where
} from "firebase/firestore";
import { userUserStore } from "../stores/user";
import {
  Venta,
  utilFechaGetCurrentTime,
  VentaConverter
} from "@fixeduy/fixedtypes";
import { useVentasStore } from "./ventas/ventas";

export const useVentasBorradoresStore = defineStore({
  id: "ventasBorradores",
  persist: true,
  state: () => {
    return {
      _borradores: [] as Venta[],
    };
  },
  getters: {
    borradores(): Venta[] {
      return this._borradores;
    },
  },
  actions: {
    async getVentaBorradoresFromFirestore(fechaMasNuevo: number) {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/ventas-borradores/`;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(
            collection(db, collectionRoute),
            where("fechaCreacion", ">", fechaMasNuevo),
            limit(10)
          ).withConverter(VentaConverter)
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addVentaBorradorToStore(doc.data());
          });
          resolve();
        });
      });
    },
    addVentaBorradorToStore(venta: Venta) {
      // Reviso que tenga menos de 48 horas
      if (venta.fechaCreacion + 48 * 60 * 60 * 1000 < utilFechaGetCurrentTime()) {
        this.eliminarVentaBorrador(venta.idBorrador);
        return;
      }

      // reviso que no exista
      for (var i = 0; i < this._borradores.length; i++) {
        if (this._borradores[i].idBorrador == venta.idBorrador) {
          this._borradores[i] = venta;
          return; // corto ejecucion de la funcion
        }
      }
      // lo inserto y ordeno
      this._borradores.push(venta);
      this._borradores.sort((a, b) =>
        b.fechaCreacion < a.fechaCreacion ? -1 : 1
      );
    },
    eliminarVentaBorrador(borradorId: string) {
      const userStore = userUserStore();
      const ventasStore = useVentasStore();
      ventasStore.ventaBorradorId = "";
      const borradorDoc = doc(
        collection(getFirestore(), `empresas/${userStore.empresa?.id}/ventas-borradores`),
        borradorId
      );
      deleteDoc(borradorDoc);
      this._borradores = this._borradores.filter((v) => v.idBorrador !== borradorId);
    },
    init() {
      this._borradores = [];
      this.getVentaBorradoresFromFirestore(0);
    },
    obtenerNuevos() {
      let fechaMasNuevo = 0;
      for (const borrador of this._borradores) {
        if (borrador.fechaCreacion + 96 * 60 * 60 * 1000 < utilFechaGetCurrentTime()) {
          this.eliminarVentaBorrador(borrador.idBorrador);
        } else {
          fechaMasNuevo = Math.max(fechaMasNuevo, borrador.fechaCreacion);
        }
      }
      this.getVentaBorradoresFromFirestore(fechaMasNuevo);
    },
  }
});
