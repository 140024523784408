import * as moment from "moment-timezone";

export function getTimestampFromYearMonthDay(
  year: number,
  month: number,
  day: number
): number {
  // Create a moment object in Montevideo timezone with the provided year, month, and day
  const montevideoTime = moment.tz(
    [year, month - 1, day],
    "America/Montevideo"
  );

  // Return the Unix timestamp
  return montevideoTime.valueOf();
}

export function getTimestamp(
  year: number,
  month: number,
  day: number,
  hour: number,
  minute: number,
  second: number
): number {
  // Create a moment object in Montevideo timezone with the provided date and time
  const montevideoTime = moment.tz(
    [year, month - 1, day, hour, minute, second],
    "America/Montevideo"
  );

  // Return the Unix timestamp
  return montevideoTime.valueOf();
}

/**
 * @param {string} dateStr En formato aaaa-mm-dd
 * @return {number} Timestamp con zona horaria Montevideo
 */
export function getTimestampFromDate(dateStr: string): number {
  // Split the date string into components
  const parts = dateStr.split("-");

  // Parse each part into a number
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  // Return an object with the separated values
  return getTimestampFromYearMonthDay(year, month, day);
}

/**
 * @return {number} Timestamp del tiempo actual con zona horaria Montevideo
 */
export function getCurrentTime(): number {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1; // JavaScript months are 0-indexed
  const day = now.getDate();
  const hour = now.getHours();
  const minute = now.getMinutes();
  const second = now.getSeconds();

  return getTimestamp(year, month, day, hour, minute, second);
}

/**
 * @param {string} dateStr En formato dd/mm/aaaa
 * @return {number} Fecha como aaaa-mm-dd
 */
export function formatearFechaAAAAMMDD(fechaOriginal: string): string {
  const [dia, mes, año] = fechaOriginal.split("/");
  return `${año}-${mes}-${dia}`;
}

export function obtenerPrimeroDeEneroActual() {
  const añoActual = new Date().getFullYear();
  return `01/01/${añoActual}`;
}

/**
 * Obtiene el timestamp del primer día del mes y año proporcionados.
 *
 * @param month - El mes (de 1 a 12).
 * @param year - El año.
 * @returns El timestamp del primer día del mes y año proporcionados.
 */
export function getFirstDayOfMonthTimestamp(
  month: number,
  year: number
): number {
  // Validar el rango del mes
  if (month < 1 || month > 12) {
    throw new Error("El mes debe estar entre 1 y 12.");
  }

  // Crear una fecha con el primer día del mes y año proporcionados
  const firstDay = new Date(year, month - 1, 1, 0, 0, 0, 0);

  // Obtener el timestamp en milisegundos y convertir a segundos
  const timestamp = Math.floor(firstDay.getTime() / 1000);

  return timestamp;
}

export function getFirstAndLastOfMonthTimestamps(timestampInSeconds: number): {
  year: number;
  firstOfMonth: number;
  lastOfMonth: number;
} {
  const timestampInMillis = timestampInSeconds * 1000; // Convertir segundos a milisegundos
  const date = new Date(timestampInMillis);
  const year = date.getFullYear();
  const month = date.getMonth();

  // Primer día del mes a las 00:00:00 horas
  const firstOfMonth = new Date(year, month, 1, 0, 0, 0).getTime();

  // Último día del mes a las 23:59:59 horas
  const lastDayOfMonth = new Date(year, month + 1, 0); // último día del mes actual
  const lastOfMonth = new Date(
    lastDayOfMonth.getFullYear(),
    lastDayOfMonth.getMonth(),
    lastDayOfMonth.getDate(),
    0,
    0,
    0
  ).getTime();

  return { year, firstOfMonth, lastOfMonth };
}

export function validarFecha(fecha: string) {
  if (fecha === "" || fecha === "null") {
    return true;
  }
  const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
  if (!regex.test(fecha)) {
    return false;
  }
  const [day, month, year] = fecha.split("/").map(Number);
  const date = new Date(year, month - 1, day);
  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}
