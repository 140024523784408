import {defineStore} from "pinia";
import {collection, query, getFirestore, where, getDocs, doc, setDoc} from "firebase/firestore";
import {userUserStore} from "../stores/user";
import {CuentaIVA, cuentaIVAConverter} from "@fixeduy/fixedtypes";
import {useVentasStore} from "./ventas/ventas";
import {useMensajesStore} from "./mensajes";
import {mesesNombre} from "../utils/devolverMesString";
import {getFirstDayOfMonthTimestamp} from "../utils/fecha";
import {useComprasStore} from "./compras/compras";
import {meses} from "../utils/meses";

export const useCuentaIVAStore = defineStore({
  id: "cuentaIVA",
  persist: true,
  state: () => {
    return {
      _cuenta: [] as CuentaIVA[]
    };
  },
  getters: {
    cuenta(): CuentaIVA[] {
      return this._cuenta;
    },
    getUltimaCuentaEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this._cuenta.map(r => {
        if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
          mayorFechaUltimaEdicion = r.ultimaEdicion;
        }
      });
      return mayorFechaUltimaEdicion;
    },
    ultimasCincoCuentasIVA(): CuentaIVA[] {
      const currentDate = new Date();
      const cuentas: CuentaIVA[] = [];

      for (let i = 0; i < 5; i++) {
        const mes = currentDate.getMonth();
        const ano = currentDate.getFullYear().toString();
        const mesNombre = meses[mes];

        let cuenta = this._cuenta.find(c => c.mes === mesNombre && c.ano === ano);

        if (!cuenta) {
          cuenta = {
            id: `${mesNombre}-${ano}`,
            mes: mesNombre,
            ano: ano,
            ivaVentas: 0,
            ivaCompras: 0,
            ultimaEdicion: new Date().getTime()
          };
          this._cuenta.push(cuenta);
        }

        if (cuenta) {
          if (!cuentas.find(data => data.id == cuenta?.id)) {
            cuentas.push(cuenta);
          } else {
            i--;
          }
        }

        currentDate.setMonth(currentDate.getMonth() - 1);
      }

      cuentas.sort((a, b) => {
        const mesA = meses.indexOf(a.mes);
        const mesB = meses.indexOf(b.mes);
        return a.ano === b.ano ? mesB - mesA : parseInt(b.ano) - parseInt(a.ano);
      });

      return cuentas.slice(0, 5);
    }
  },
  actions: {
    async getCuentaIVAFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/cuenta-iva/`;
      const mayorFechaUltimaEdicion = this.getUltimaCuentaEdicion;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(collection(db, collectionRoute), where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)).withConverter(
            cuentaIVAConverter
          )
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addCuentaToStore(doc.data());
          });
          resolve();
        });
      });
    },
    addCuentaToStore(cuenta: CuentaIVA) {
      cuenta.id = `${cuenta.mes}-${cuenta.ano}`;
      // reviso que no exista
      for (var i = 0; i < this._cuenta.length; i++) {
        if (this._cuenta[i].id == cuenta.id) {
          this._cuenta[i] = cuenta;
          return; // corto ejecucion de la funcion
        }
      }
      // lo inserto y ordeno
      this._cuenta.push(cuenta);
      this._cuenta.sort((a, b) => (b.id > a.id ? -1 : 1));
    },
    async crearCuenta(cuenta: CuentaIVA) {
      try {
        const mensajesStore = useMensajesStore();
        const db = getFirestore();
        const userStore = userUserStore();
        const collectionRoute = `empresas/${userStore.empresa!.id}/cuenta-iva/`;

        await setDoc(doc(db, collectionRoute, `${cuenta.mes}-${cuenta.ano}`).withConverter(cuentaIVAConverter), {
          id: `${cuenta.mes}-${cuenta.ano}`,
          mes: cuenta.mes,
          ano: cuenta.ano,
          ivaVentas: cuenta.ivaVentas,
          ivaCompras: cuenta.ivaCompras,
          ultimaEdicion: new Date().getTime()
        });

        this.addCuentaToStore(cuenta);

        setDoc(
          doc(db, "empresas", userStore.empresa!.id),
          {
            ultimaEdicionCuentaIVA: new Date().getTime()
          },
          {merge: true}
        );

        mensajesStore.mostrarToast(
          "Tasa IVA creada con éxito",
          cuenta.mes + " / " + cuenta.ano,
          mensajesStore.mensajeType.exito
        );

        return true;
      } catch (error) {
        console.error(error);
      }
    },
    async editarCuentaIVA(cuenta: CuentaIVA) {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/cuenta-iva/`;

      const docRef = doc(db, collectionRoute, `${cuenta.mes}-${cuenta.ano}`).withConverter(cuentaIVAConverter);

      await setDoc(
        docRef,
        {
          id: `${cuenta.mes}-${cuenta.ano}`,
          mes: cuenta.mes,
          ano: cuenta.ano,
          ivaVentas: cuenta.ivaVentas,
          ivaCompras: cuenta.ivaCompras,
          ultimaEdicion: new Date().getTime()
        },
        {merge: true}
      );

      await setDoc(
        doc(db, "empresas", userStore.empresa!.id),
        {
          ultimaEdicionCuentaIVA: new Date().getTime()
        },
        {merge: true}
      );

      for (let i = 0; i < this.cuenta.length; i++) {
        if (this.cuenta[i].id == cuenta.id) {
          this.cuenta[i] = cuenta;
          break;
        }
      }
      return true;
    },
    getCuentaById(id: string): CuentaIVA | null {
      for (var i = 0; i < this.cuenta.length; i++) {
        if (this.cuenta[i].id == id) {
          return this.cuenta[i];
        }
      }
      return null;
    },

    getCuentaByMesAndAnio(mes: string, anio: string): CuentaIVA | null {
      for (var i = 0; i < this.cuenta.length; i++) {
        if (this.cuenta[i].mes == mes && this.cuenta[i].ano == anio) {
          return this.cuenta[i];
        }
      }
      return null;
    },
    async recalcularCuentaIVA(mes: string, anio: string, entidad: string) {
      let cuentaAux: CuentaIVA | null = this.getCuentaByMesAndAnio(mes, anio);
      if (entidad == "venta") {
        const ventasStore = useVentasStore();
        let ivaVentas = await ventasStore.recalcularIVAVentasPorMes();
        if (cuentaAux) {
          cuentaAux.ivaVentas = ivaVentas;
          // reviso que no exista
          this.editarCuentaIVA(cuentaAux);
          // for (var i = 0; i < this._cuenta.length; i++) {
          //   if (this._cuenta[i].id == cuentaAux.id) {
          //     this._cuenta[i] = cuentaAux;
          //     return; // corto ejecucion de la funcion
          //   }
          // }
        } else {
          let cuentaAgregar: CuentaIVA = {
            id: `${mes}-${anio}`,
            mes: mes,
            ano: anio,
            ivaVentas: ivaVentas,
            ivaCompras: 0,
            ultimaEdicion: new Date().getTime()
          };
          this.crearCuenta(cuentaAgregar);
          // lo inserto y ordeno
          this._cuenta.push(cuentaAgregar);
          this._cuenta.sort((a, b) => (b.id > a.id ? -1 : 1));
        }
      } else if (entidad == "compra") {
        const comprasStore = useComprasStore();
        let ivaCompras = await comprasStore.recalcularIVAComprasPorMes();
        if (cuentaAux) {
          cuentaAux.ivaCompras = ivaCompras;
          // reviso que no exista
          this.editarCuentaIVA(cuentaAux);
          // for (var i = 0; i < this._cuenta.length; i++) {
          //   if (this._cuenta[i].id == cuentaAux.id) {
          //     this._cuenta[i] = cuentaAux;
          //     return; // corto ejecucion de la funcion
          //   }
          // }
        } else {
          let cuentaAgregar: CuentaIVA = {
            id: `${mes}-${anio}`,
            mes: mes,
            ano: anio,
            ivaVentas: 0,
            ivaCompras: ivaCompras,
            ultimaEdicion: new Date().getTime()
          };
          this.crearCuenta(cuentaAgregar);
          // lo inserto y ordeno
          this._cuenta.push(cuentaAgregar);
          this._cuenta.sort((a, b) => (b.id > a.id ? -1 : 1));
        }
      }
    }
  }
});
