import {defineStore} from "pinia";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  startAfter,
  limit,
  orderBy,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  getCountFromServer
} from "firebase/firestore";
import {Ref, computed, ref} from "vue";
import {userUserStore} from "../../stores/user";
import {
  CFE,
  Cliente,
  Documento,
  DocumentoConverter,
  Local,
  Venta,
  VentaConverter,
  cfeConverter,
  utilFechaConvertirNumeroString,
  utilFechaGetTimestampFromDate
} from "@fixeduy/fixedtypes";
import {ventasAgregar} from "../cloudFunctions/ventasAgregar";
import {ventasAgregarDevolucion} from "../cloudFunctions/ventasAgregarDevolucion";
import {ventasAgregarNotaDebito} from "../cloudFunctions/VentasAgregarNotaDebito";
import {formatearFechaAAAAMMDD, getFirstAndLastOfMonthTimestamps, getFirstDayOfMonthTimestamp} from "../../utils/fecha";
import {FiltroVentas} from "./interfaceVentasFiltros";
import {useCuentaIVAStore} from "../cuentaIVA";
import {mesesNumero} from "../../utils/devolverMesString";
import {useClientesStore} from "../clientes";
import {useLocalesStore} from "../locales";

export const useVentasStore = defineStore("ventas", () => {
  const db = getFirestore();
  const userStore = userUserStore();
  const localesStore = useLocalesStore();
  const ventas: Ref<Venta[]> = ref([]);
  const venta: Ref<Venta | null> = ref(null);
  const ventaId: Ref<string> = ref("");
  const ventaBorradorId: Ref<string> = ref("");
  let last: any = null;
  let mostrar = 10;
  const cargando = ref(false);
  let procesandoGetVentas = false;
  const showExitoMsg = ref(false);
  const buscar = ref("");
  const collectionRoute = `empresas/${userStore.empresa!.id}/ventas/`;
  let timerBuscar: NodeJS.Timeout | null = null;
  const verInactivo = ref(false);
  const filtrado = ref(false);
  const monedaReporteEstadisticasVenta = ref("pesos");
  const filtros: Ref<FiltroVentas> = ref({
    fechaInicioComprobante: "null",
    fechaFinComprobante: "null",
    fechaInicioGeneracion: "null",
    fechaFinGeneracion: "null",
    productosYservicios: [],
    formaPago: "",
    tipo: "",
    nroComprobanteDesde: 0,
    nroComprobanteHasta: 0,
    cliente: null,
    cfeEstadoDGI: "",
    moneda: "",
    vendedor: "",
    locales: [],
    monedaReporte: "pesos"
  });
  let ejecutarVaciarFiltros = ref(true);
  let ultimaCargaVacia = false;
  let monedaEstadisticas = ref([]);

  // Importador
  const clientesCreados = ref<Array<Cliente>>([]);
  const cantVentasExitosas = ref<number>(0);
  const cantVentasFallidas = ref<number>(0);
  const errores = ref<Array<string>>([""]);
  const mostrarInforme = ref<boolean>(false);

  const localesUsuario = computed(() => {
    if (userStore.usuario?.locales.length == undefined || userStore.usuario?.locales.length > 0) {
      return localesStore.localesActivos.filter(l => userStore.usuario?.locales.includes(l.id));
    } else {
      return localesStore.localesActivos;
    }
  });

  const firestoreQuery = () => {
    let filtrarFechaComprobante = false;
    let filtrarFechaGeneracion = false;

    if (userStore.usuario?.vendedorRestringido) {
      filtros.value.vendedor = userStore.usuario?.id;
    }

    // Fecha comprobante
    let fechaInicioComprobante: number | string = "";
    let fechaFinComprobante: number | string = "";

    if (filtros.value.fechaInicioComprobante != "" && filtros.value.fechaInicioComprobante != "null") {
      fechaInicioComprobante = utilFechaGetTimestampFromDate(
        formatearFechaAAAAMMDD(filtros.value.fechaInicioComprobante)
      );
      filtrarFechaComprobante = true;
    }

    if (filtros.value.fechaFinComprobante != "" && filtros.value.fechaFinComprobante != "null") {
      fechaFinComprobante = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtros.value.fechaFinComprobante));
      filtrarFechaComprobante = true;
    }

    // Fecha generacion
    let fechaInicioGeneracion: number | string = "";
    let fechaFinGeneracion: number | string = "";

    if (filtros.value.fechaInicioGeneracion != "" && filtros.value.fechaInicioGeneracion != "null") {
      fechaInicioGeneracion = utilFechaGetTimestampFromDate(
        formatearFechaAAAAMMDD(filtros.value.fechaInicioGeneracion)
      );
      filtrarFechaGeneracion = true;
    }

    if (filtros.value.fechaFinGeneracion != "" && filtros.value.fechaFinGeneracion != "null") {
      fechaFinGeneracion = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtros.value.fechaFinGeneracion));
      fechaFinGeneracion += 86400000;
      filtrarFechaGeneracion = true;
    }

    // Orden de filtros por >, <, ==
    // 1ro - Filtro por fecha comprobante
    // 2do - Filtro por fecha generacion
    // 3ro - Filtro por comprobante inicio

    let filtroProductosYservicios: string[] = [];
    if (filtros.value.productosYservicios.length > 0) {
      for (const producto of filtros.value.productosYservicios) {
        filtroProductosYservicios.push(producto.id);
      }
    }

    return query(
      collection(db, collectionRoute),
      ...(fechaInicioComprobante && filtrarFechaComprobante == true
        ? [where("fechaComprobante", ">=", fechaInicioComprobante)]
        : []),
      ...(fechaFinComprobante && filtrarFechaComprobante == true
        ? [where("fechaComprobante", "<=", fechaFinComprobante)]
        : []),
      ...(fechaInicioGeneracion && filtrarFechaGeneracion == true && !filtrarFechaComprobante
        ? [where("fechaCreacion", ">=", fechaInicioGeneracion)]
        : []),
      ...(fechaFinGeneracion && filtrarFechaGeneracion == true && !filtrarFechaComprobante
        ? [where("fechaCreacion", "<=", fechaFinGeneracion)]
        : []),
      ...(!isNaN(filtros.value.nroComprobanteHasta) &&
      filtros.value.nroComprobanteDesde != 0 &&
      filtros.value.nroComprobanteDesde == filtros.value.nroComprobanteHasta
        ? [where("cfeNumero", "==", filtros.value.nroComprobanteHasta)]
        : []),
      ...(!verInactivo.value ? [where("activo", "==", true)] : []),
      ...(filtros.value.formaPago != "" ? [where("formaDePago", "==", filtros.value.formaPago)] : []),
      ...(filtros.value.vendedor != "" ? [where("vendedor", "==", filtros.value.vendedor)] : []),
      ...(filtros.value.tipo != "" && filtros.value.tipo != "todos"
        ? [where("cfeTipo", "==", Number(filtros.value.tipo))]
        : []),
      ...(filtros.value.cliente != null ? [where("cliente.id", "==", filtros.value.cliente.id)] : []),
      ...(filtros.value.cfeEstadoDGI != "todas" && filtros.value.cfeEstadoDGI != ""
        ? [where("cfeEstadoDGI", "==", filtros.value.cfeEstadoDGI)]
        : []),
      ...(filtros.value.moneda != "" ? [where("moneda", "==", filtros.value.moneda)] : []),
      ...(filtros.value.locales.length ? [where("local", "in", filtros.value.locales)] : []),
      ...(filtroProductosYservicios.length > 0
        ? [where("productosYservicios", "array-contains-any", filtroProductosYservicios)]
        : []),
      ...(filtrarFechaComprobante ? [orderBy("fechaComprobante", "desc")] : []),
      orderBy("fechaCreacion", "desc"),
      ...(last != null ? [startAfter(last)] : []),
      ...(mostrar > 0 ? [limit(mostrar)] : [])
    ).withConverter(VentaConverter);
  };

  const init = () => {
    last = null;
    filtrado.value = false;
    ultimaCargaVacia = false;
    ventas.value = [];
    if (ejecutarVaciarFiltros.value) vaciarFiltros();
    else {
      ejecutarVaciarFiltros.value = true;
      if (!filtrosVacios()) {
        return filtrarVenta();
      }
    }

    getVentas();
    getVentasReordenarReCalcular();
  };

  const getVentas = async () => {
    if (procesandoGetVentas || ultimaCargaVacia) {
      // console.log(`Omito hacer getVentas`);
      return;
    }

    procesandoGetVentas = true;
    const querySnapshot = await getDocs(firestoreQuery());
    if (querySnapshot.docs[querySnapshot.docs.length - 1] != null) {
      last = querySnapshot.docs[querySnapshot.docs.length - 1];
      //  console.log("Last: ", last);
    }
    ultimaCargaVacia = querySnapshot.docs.length == 0;
    for (const doc of querySnapshot.docs) {
      const venta = doc.data();
      // const encontrada = ventas.value.find(x => x.id === venta.id);
      // if (encontrada == null) {
      //   /* count.value++;
      //   console.log("Count: ", count.value); */
      // }
      ventas.value.push(venta);
    }

    ventas.value = ventas.value.filter(venta => localesUsuario.value.includes(localesStore.getLocalById(venta.local)!));

    if (buscar.value) {
      ventas.value = ventas.value.filter(venta => {
        const ventasEncontradas =
          venta?.cliente?.nombreComercial.toLowerCase().includes(buscar.value.toLowerCase()) ||
          venta.comprobante.toLowerCase().includes(buscar.value.toLowerCase()) ||
          venta.total.toString().includes(buscar.value) ||
          venta.formaDePago.toLowerCase().includes(buscar.value) ||
          utilFechaConvertirNumeroString(venta.fechaComprobante, false, "/").includes(buscar.value);

        const lineasCoincidentes = venta.ventaLinea.some(linea =>
          linea.descripcion.toLowerCase().includes(buscar.value.toLowerCase())
        );
        return ventasEncontradas || lineasCoincidentes;
      });
    }
    procesandoGetVentas = false;
  };

  const getVentasReordenarReCalcular = () => {
    if (
      filtros.value.nroComprobanteDesde != 0 &&
      filtros.value.nroComprobanteDesde != filtros.value.nroComprobanteHasta
    ) {
      ventas.value = ventas.value.filter(
        venta => venta.cfeNumero && venta.cfeNumero >= filtros.value.nroComprobanteDesde
      );
    }
    if (
      filtros.value.nroComprobanteHasta != 0 &&
      filtros.value.nroComprobanteDesde != filtros.value.nroComprobanteHasta
    ) {
      ventas.value = ventas.value.filter(
        venta => venta.cfeNumero && venta.cfeNumero <= filtros.value.nroComprobanteHasta
      );
    }

    let filtrarFechaComprobante = false;
    let filtrarFechaGeneracion = false;

    // Fecha comprobante
    let fechaInicioComprobante: number | string = "";
    let fechaFinComprobante: number | string = "";

    if (filtros.value.fechaInicioComprobante != "" && filtros.value.fechaInicioComprobante != "null") {
      filtrarFechaComprobante = true;
    }

    if (filtros.value.fechaFinComprobante != "" && filtros.value.fechaFinComprobante != "null") {
      filtrarFechaComprobante = true;
    }

    // Fecha generacion
    let fechaInicioGeneracion: number | string = "";
    let fechaFinGeneracion: number | string = "";

    if (filtros.value.fechaInicioGeneracion != "" && filtros.value.fechaInicioGeneracion != "null") {
      fechaInicioGeneracion = utilFechaGetTimestampFromDate(
        formatearFechaAAAAMMDD(filtros.value.fechaInicioGeneracion)
      );
      filtrarFechaGeneracion = true;
    } else {
      fechaInicioGeneracion = 0;
    }

    if (filtros.value.fechaFinGeneracion != "" && filtros.value.fechaFinGeneracion != "null") {
      fechaFinGeneracion = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtros.value.fechaFinGeneracion));
      fechaFinGeneracion += 86400000;
      filtrarFechaGeneracion = true;
    } else {
      fechaFinGeneracion = 999999999999;
    }

    if (filtrarFechaComprobante && filtrarFechaGeneracion) {
      ventas.value = ventas.value.filter(
        data => data.fechaCreacion >= Number(fechaInicioGeneracion) && data.fechaCreacion <= Number(fechaFinGeneracion)
      );
    }

    // Elimino las ventas repetidas, por si alguna se cargo 2 veces
    ventas.value = Object.values(
      ventas.value.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {} as {[key: string]: Venta})
    );

    ventas.value.sort((a, b) => {
      if (a.fechaCreacion === b.fechaCreacion) {
        return b.fechaComprobante - a.fechaComprobante;
      }
      return b.fechaCreacion - a.fechaCreacion;
    });
  };

  const buscarVentas = async (buscarVenta: string) => {
    if (timerBuscar != null) clearTimeout(timerBuscar);

    timerBuscar = setTimeout(() => {
      buscar.value = buscarVenta;
      ventas.value = [];
      last = null;

      if (!filtrosVacios()) {
        filtrarVenta();
      } else getVentas();
    }, 500);
  };

  const addVenta = async (venta: Venta) => {
    try {
      const resultado = await ventasAgregar(venta);
      ultimaCargaVacia = false;
      return resultado;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const addVentaImportada = async (venta: Venta, cfe: CFE, documento: Documento) => {
    try {
      const docRef = doc(collection(db, collectionRoute)).withConverter(VentaConverter);

      const collectionCFE = `empresas/${userStore.empresa!.id}/cfes/`;
      const docRefCFE = doc(collection(db, collectionCFE)).withConverter(cfeConverter);

      const collectionDocumento = `empresas/${userStore.empresa!.id}/documentos/`;
      const docRefDocumento = doc(collection(db, collectionDocumento)).withConverter(DocumentoConverter);

      venta.id = docRef.id;
      cfe.id = docRefCFE.id;
      documento.id = docRefDocumento.id;
      documento.documentoId = venta.id;

      venta.cfeId = cfe.id;
      cfe.generadorId = venta.id;

      await setDoc(docRef, venta);
      await setDoc(docRefCFE, cfe);
      await setDoc(docRefDocumento, documento);

      return true;
    } catch (error) {
      console.error(error);
    }
  };

  const editarVendedor = async (venta: Venta, nuevoVendedor: string) => {
    try {
      await updateDoc(doc(db, collectionRoute, venta.id), {
        vendedor: nuevoVendedor
      });
      for (let i = 0; i < ventas.value.length; i++) {
        if (ventas.value[i].id == venta.id) {
          ventas.value[i].vendedor = nuevoVendedor;
          break;
        }
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const addDevolucion = async (devolucion: Venta) => {
    try {
      const resultado = await ventasAgregarDevolucion(devolucion);
      ultimaCargaVacia = false;
      // console.log(resultado);
      return resultado;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const addNotaDebito = async (notaDebito: Venta) => {
    // console.log(notaDebito);
    try {
      ejecutarVaciarFiltros.value = false;
      const resultado = await ventasAgregarNotaDebito(notaDebito);
      ultimaCargaVacia = false;
      // console.log(resultado);
      return resultado;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const editarVenta = async (venta: Venta) => {
    // const rubroStore = useVentasRubrosStore();
    // let rubroAux = rubroStore.getRubroById(venta.rubro.toString());
    // const docRef = doc(db, collectionRoute, venta.id);
    // await setDoc(docRef, {}, { merge: true });
    // init();
    // return true;
    const docRef = doc(db, collectionRoute, venta.id).withConverter(VentaConverter);
    await setDoc(
      docRef,
      {
        ...venta
      },
      {merge: true}
    );
  };

  const filtrarVenta = async () => {
    ultimaCargaVacia = false;
    cargando.value = true;
    ventas.value = [];
    last = null;
    mostrar = 1000000;
    // const snapshot = await getCountFromServer(firestoreQuery());
    // let cantidadVentas = snapshot.data().count;
    mostrar = 200;
    let i = 0;
    while (ultimaCargaVacia == false) {
      await getVentas();
      i++;
      if (i >= 500) {
        // console.log("Corto el while");
        break;
      }
    }

    getVentasReordenarReCalcular();
    filtrado.value = true;
    cargando.value = false;

    mostrar = 10;

    // Inicio implementar actualizar cuenta iva pantalla de inicio
    if (
      filtros.value.formaPago == "" &&
      filtros.value.tipo == "" &&
      filtros.value.nroComprobanteDesde == 0 &&
      filtros.value.nroComprobanteHasta == 0 &&
      filtros.value.cliente == null &&
      filtros.value.cfeEstadoDGI == "" &&
      filtros.value.moneda == "" &&
      filtros.value.vendedor == "" &&
      filtros.value.locales.length == 0 &&
      filtros.value.productosYservicios.length == 0
    ) {
      let fechaInicioComprobante: number | string = "";
      let fechaFinComprobante: number | string = "";

      if (filtros.value.fechaInicioComprobante != "" && filtros.value.fechaInicioComprobante != "null") {
        fechaInicioComprobante = utilFechaGetTimestampFromDate(
          formatearFechaAAAAMMDD(filtros.value.fechaInicioComprobante)
        );
      }

      if (filtros.value.fechaFinComprobante != "" && filtros.value.fechaFinComprobante != "null") {
        fechaFinComprobante = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtros.value.fechaFinComprobante));
      }

      if (filtros.value.fechaFinComprobante && filtros.value.fechaFinComprobante != "null") {
        let fechaSplit = filtros.value.fechaFinComprobante.split("/");

        let fecha = getFirstDayOfMonthTimestamp(mesesNumero[fechaSplit[1]].numero, Number(fechaSplit[2]));

        let fechaAux = getFirstAndLastOfMonthTimestamps(fecha);

        if (fechaInicioComprobante == fechaAux.firstOfMonth && fechaFinComprobante == fechaAux.lastOfMonth) {
          const cuentaIVAStore = useCuentaIVAStore();

          cuentaIVAStore.recalcularCuentaIVA(mesesNumero[fechaSplit[1]].nombre, fechaSplit[2], "venta");
        }
      }
      // Fin implementar para actualizar cuenta iva pantalla de inicio
    }
  };

  const buscarFacturasDevolucion = async (filtro: any) => {
    let fechaInicio: number | string = "";
    let fechaFin: number | string = "";

    if (filtro.fechaFiltroInicio && filtro.fechaFiltroInicio != "") {
      fechaInicio = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtro.fechaFiltroInicio));
    }

    if (filtro.fechaFiltroFin && filtro.fechaFiltroFin != "") {
      fechaFin = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtro.fechaFiltroFin));
    }

    const q = query(
      collection(db, collectionRoute),
      ...(fechaInicio != "" ? [where("fechaComprobante", ">=", fechaInicio)] : []),
      ...(fechaFin != "" ? [where("fechaComprobante", "<=", fechaFin)] : []),
      ...(filtro.cliente != "" && filtro.cliente != null
        ? [where("cliente.id", "==", filtro.cliente)]
        : [where("cliente", "==", null)]),
      ...(filtro.formaDePago != "" ? [where("formaDePago", "==", filtro.formaDePago)] : []),
      ...(filtro.moneda != "" ? [where("moneda", "==", filtro.moneda)] : []),
      // ...(filtro.tipo != "" ? [where("tipo", "==", "venta")] : []),
      where("tipo", "in", filtro.tipo),
      ...(filtro.local != "" ? [where("local", "==", filtro.local)] : []),
      // where("cfeEstadoDGI", "in", ["aceptado"]),
      where("cfeEstadoDGI", "in", ["aceptado", "enviado", "emitido"]),
      where("activo", "==", true),
      orderBy("fechaComprobante", "desc"),
      orderBy("fechaCreacion", "desc")
    ).withConverter(VentaConverter);
    const querySnapshot = await getDocs(q);

    const devolverVentas: Ref<Venta[]> = ref([]);

    querySnapshot.forEach(doc => {
      const venta = doc.data() as Venta;
      if (venta.saldo > 0) {
        devolverVentas.value.push(venta);
      }
    });

    // calcularMontosTotales();

    return devolverVentas.value;
  };

  const buscarFacturasDevolucionNotaDebito = async (filtro: any) => {
    // console.log(filtro);
    let fechaInicio: number | string = "";
    let fechaFin: number | string = "";

    if (filtro.fechaInicioFiltro && filtro.fechaInicioFiltro != "") {
      fechaInicio = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtro.fechaInicioFiltro));
    }

    if (filtro.fechaFinFiltro && filtro.fechaFinFiltro != "") {
      fechaFin = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtro.fechaFinFiltro));
    }

    const q = query(
      collection(db, collectionRoute),
      ...(fechaInicio != "" ? [where("fechaComprobante", ">=", fechaInicio)] : []),
      ...(fechaFin != "" ? [where("fechaComprobante", "<=", fechaFin)] : []),
      ...(filtro.cliente != "" && filtro.cliente != null && filtro.cliente != undefined
        ? [where("cliente.id", "==", filtro.cliente)]
        : [where("cliente", "==", null)]),
      ...(filtro.formaDePago != "" ? [where("formaDePago", "==", filtro.formaDePago)] : []),
      ...(filtro.moneda != "" ? [where("moneda", "==", filtro.moneda)] : []),
      // where("tipo", "in", ["venta", "devolucion"]),
      where("tipo", "in", filtro.tipo),
      ...(filtro.local != "" ? [where("local", "==", filtro.local)] : []),
      // where("cfeEstadoDGI", "in", ["aceptado"]),
      where("cfeEstadoDGI", "in", ["aceptado", "enviado", "emitido"]),
      where("activo", "==", true),
      orderBy("fechaComprobante", "desc"),
      orderBy("fechaCreacion", "desc")
    ).withConverter(VentaConverter);
    const querySnapshot = await getDocs(q);

    const devolverVentas: Ref<Venta[]> = ref([]);

    querySnapshot.forEach(doc => {
      const venta = doc.data();
      if ((venta.tipo == "devolucion" && venta.saldo != 0) || venta.tipo == "venta") {
        devolverVentas.value.push(venta);
      }
    });

    // calcularMontosTotales();

    return devolverVentas.value;
  };

  const actualizarSaldo = async (ventaId: string, saldo: number) => {
    const docRef = doc(db, collectionRoute, ventaId);

    // Obtengo el saldo actual y le resto lo que viene por parametros
    const docSnapshot = await getDoc(docRef);
    const saldoActual = docSnapshot.data()?.saldo;
    const nuevoSaldo = saldoActual - saldo;

    await setDoc(
      docRef,
      {
        saldo: nuevoSaldo
      },
      {merge: true}
    );

    for (let i: number = 0; i < ventas.value.length; i++) {
      if (ventas.value[i].id == ventaId) {
        ventas.value[i].saldo = nuevoSaldo;
        break;
      }
    }

    return true;
  };

  const getVentaById = async (id: string) => {
    try {
      const docRef = doc(db, collectionRoute, id).withConverter(VentaConverter);

      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        return docSnapshot.data();
      } else {
        return null;
      }
    } catch (e) {
      console.error("Error en la consulta: ", e);
      throw e;
    }
  };

  const transferirVentas = async (clienteOriginalId: string, clienteNuevoId: string) => {
    const clientesStore = useClientesStore();
    const documentosRef = collection(getFirestore(), `empresas/${userStore.empresa!.id}/ventas/`).withConverter(
      VentaConverter
    );
    const q = query(documentosRef, where("cliente.id", "==", clienteOriginalId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      querySnapshot.forEach(async doc => {
        let venta = doc.data();
        const cliente = await clientesStore.geClienteById(clienteNuevoId);
        venta = {...venta, cliente: cliente};
        editarVenta(venta);
      });
    }
  };

  const getVentaBorradorById = async (id: string) => {
    try {
      const docRef = doc(db, `empresas/${userStore.empresa!.id}/ventas-borradores/`, id).withConverter(VentaConverter);

      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        return docSnapshot.data();
      } else {
        return null;
      }
    } catch (e) {
      console.error("Error en la consulta: ", e);
      throw e;
    }
  };

  const getVentasEstadisticas = async (filtro: any) => {
    // let fechaInicio: number | string = "";
    // let fechaFin: number | string = "";
    await vaciarFiltros();

    if (filtro.locales.length > 0) {
      filtros.value.locales = filtro.locales;
    }

    if (filtro.monedasFiltro.length > 0) {
      monedaEstadisticas.value = filtro.monedasFiltro;
    }

    await filtrarVenta();

    return true;
  };

  const vaciarFiltros = async () => {
    (filtros.value.fechaInicioComprobante = ""),
      (filtros.value.fechaFinComprobante = ""),
      (filtros.value.fechaInicioGeneracion = ""),
      (filtros.value.fechaFinGeneracion = ""),
      (filtros.value.productosYservicios = []),
      (filtros.value.formaPago = ""),
      (filtros.value.tipo = ""),
      (filtros.value.nroComprobanteDesde = 0),
      (filtros.value.nroComprobanteHasta = 0),
      (filtros.value.cliente = null),
      (filtros.value.cfeEstadoDGI = ""),
      (filtros.value.moneda = ""),
      (filtros.value.vendedor = ""),
      (filtros.value.locales = []),
      (filtros.value.monedaReporte = "pesos");
  };

  const filtrosVacios = () => {
    if (
      filtros.value.fechaInicioComprobante === "" &&
      filtros.value.fechaFinComprobante === "" &&
      filtros.value.fechaInicioGeneracion === "" &&
      filtros.value.fechaFinGeneracion === "" &&
      filtros.value.productosYservicios.length === 0 &&
      filtros.value.formaPago === "" &&
      filtros.value.tipo === "" &&
      filtros.value.nroComprobanteDesde === 0 &&
      filtros.value.nroComprobanteHasta === 0 &&
      !filtros.value.cliente &&
      filtros.value.cfeEstadoDGI === "" &&
      filtros.value.moneda === "" &&
      filtros.value.vendedor === "" &&
      filtros.value.locales.length === 0 &&
      filtros.value.monedaReporte === "pesos"
    ) {
      return true;
    } else return false;
  };

  const recalcularIVAVentasPorMes = async () => {
    try {
      let totalIVA = 0;

      if (ventas.value.length > 0) {
        ventas.value.forEach(venta => {
          if (venta.cfeEstadoDGI == "aceptado" || venta.cfeEstadoDGI == "emitido" || venta.cfeEstadoDGI == "enviado") {
            if (venta.tipo == "venta" || venta.tipo == "notadebito") {
              totalIVA += venta.montoIVA * venta.cotizacion;
            } else if (venta.tipo == "devolucion") {
              totalIVA -= venta.montoIVA * venta.cotizacion;
            }
          }
        });
      }
      // console.log("Total IVA calculado: ", totalIVA);
      return totalIVA;
    } catch (e) {
      console.error("Error en la consulta: ", e);
      throw e;
    }
  };

  const getCantidadCFE = async (fechaInicio: number, fechaFin: number) => {
    const firestoreQuery = query(
      collection(db, collectionRoute),
      ...(fechaInicio ? [where("fechaComprobante", ">=", fechaInicio)] : []),
      ...(fechaFin ? [where("fechaComprobante", "<=", fechaFin)] : []),
      ...[where("cfeEstadoDGI", "==", "aceptado")],
      ...[where("tipo", "==", "venta")]
    ).withConverter(VentaConverter);

    const snapshot = await getCountFromServer(firestoreQuery);
    return snapshot.data().count;
  };

  return {
    init,
    filtros,
    venta,
    ventaId,
    ventaBorradorId,
    ventas,
    getVentas,
    cargando,
    addVenta,
    editarVendedor,
    addDevolucion,
    addNotaDebito,
    showExitoMsg,
    buscarVentas,
    editarVenta,
    buscar,
    verInactivo,
    filtrarVenta,
    buscarFacturasDevolucion,
    actualizarSaldo,
    getVentaById,
    transferirVentas,
    getVentaBorradorById,
    buscarFacturasDevolucionNotaDebito,
    filtrado,
    ejecutarVaciarFiltros,
    getVentasEstadisticas,
    monedaReporteEstadisticasVenta,
    recalcularIVAVentasPorMes,
    clientesCreados,
    cantVentasExitosas,
    cantVentasFallidas,
    errores,
    mostrarInforme,
    addVentaImportada,
    getCantidadCFE
  };
});
