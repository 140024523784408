import { defineStore } from "pinia";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  setDoc,
  doc,
  addDoc
} from "firebase/firestore";
import { userUserStore } from "../stores/user";
import {
  FormasDePagoPlazos,
  FormasDePagoPlazosConverter
} from "@fixeduy/fixedtypes";

export const usePagoEnPlazosStore = defineStore({
  id: "pagoEnPlazos",
  persist: true,
  state: () => {
    return {
      _plazos: [] as FormasDePagoPlazos[],
      cargando: false
    };
  },
  getters: {
    plazos(): FormasDePagoPlazos[] {
      return this._plazos;
    },
    plazosActivos(): FormasDePagoPlazos[] {
      return this._plazos.filter(p => p.activo);
    },
    getUltimaPagoEnPlazosEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this._plazos.map(r => {
        if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
          mayorFechaUltimaEdicion = r.ultimaEdicion;
        }
      });
      return mayorFechaUltimaEdicion;
    }
  },
  actions: {
    async getPagoEnPlazosFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/formas-pago-plazos/`;

      const mayorFechaUltimaEdicion = this.getUltimaPagoEnPlazosEdicion;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(
            collection(db, collectionRoute),
            where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)
          ).withConverter(FormasDePagoPlazosConverter)
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addPagoEnPlazosToStore(doc.data());
          });
          resolve();
        });
      });
    },
    addPagoEnPlazosToStore(formaDePago: FormasDePagoPlazos) {
      // reviso que no exista
      for (var i = 0; i < this._plazos.length; i++) {
        if (this._plazos[i].id == formaDePago.id) {
          this._plazos[i] = formaDePago;
          return; // corto ejecucion de la funcion
        }
      }

      // lo inserto y ordeno
      this._plazos.push(formaDePago);
      this._plazos.sort((a, b) => (b.nombre > a.nombre ? -1 : 1));
    },
    async crearPlazo(formaDePago: FormasDePagoPlazos) {
      try {
        this.cargando = true;
        const db = getFirestore();
        const userStore = userUserStore();
        const collectionRoute = `empresas/${
          userStore.empresa!.id
        }/formas-pago-plazos/`;

        const docRef = doc(collection(db, collectionRoute));
        formaDePago.id = docRef.id;
        await setDoc(docRef, formaDePago);
        this.addPagoEnPlazosToStore(formaDePago);

        setDoc(
          doc(db, "empresas", userStore.empresa!.id),
          {
            ultimaEdicionPagoEnPlazos: new Date().getTime()
          },
          { merge: true }
        );
        this.cargando = false;

        return true;
      } catch (error) {
        console.error(error);
      }
    },
    getPagoEnPlazosById(id: string): FormasDePagoPlazos | null {
      for (var i = 0; i < this._plazos.length; i++) {
        if (this._plazos[i].id == id) {
          return this._plazos[i];
        }
      }
      return null;
    },
    async editarPagoEnPlazos(formaDePago: FormasDePagoPlazos) {
      this.cargando = true;
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${
        userStore.empresa!.id
      }/formas-pago-plazos/`;

      const docRef = doc(db, collectionRoute, formaDePago.id);

      await setDoc(
        docRef,
        {
          nombre: formaDePago.nombre,
          cantDias: formaDePago.cantDias,
          activo: formaDePago.activo,
          ultimaEdicion: formaDePago.ultimaEdicion
        },
        { merge: true }
      );

      if (!formaDePago.activo) {
        this._plazos = this._plazos.filter(formaDePago => formaDePago.activo);
      } else {
        for (let i = 0; i < this._plazos.length; i++) {
          if (this._plazos[i].id == formaDePago.id) {
            this._plazos[i] = formaDePago;
            break;
          }
        }
      }

      setDoc(
        doc(db, "empresas", userStore.empresa!.id),
        {
          ultimaEdicionPagoEnPlazos: new Date().getTime()
        },
        { merge: true }
      );

      this.cargando = false;

      return true;
    }
  }
});
