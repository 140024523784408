import { defineStore } from "pinia";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  addDoc,
  doc,
  setDoc
} from "firebase/firestore";
import { userUserStore } from "../../stores/user";
import {
  ListasDePreciosAEditar,
  ProductosListasPrecio,
  productosListasPrecioConverter
} from "@fixeduy/fixedtypes";
import { productosEdicionMasiva } from "../cloudFunctions/productosEdicionMasiva";

export const useProductosListasPreciosStore = defineStore({
  id: "productosListasPrecios",
  persist: true,
  state: () => {
    return {
      productosListasPrecio: [] as ProductosListasPrecio[],
      verInactivo: false
    };
  },
  getters: {
    getProductosListasPrecios(): ProductosListasPrecio[] {
      return this.productosListasPrecio;
    },
    getProductosListasPreciosActivas(): ProductosListasPrecio[] {
      return this.productosListasPrecio.filter(l => l.activo);
    },
    getUltimaProductosListasPreciosEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this.productosListasPrecio.map(r => {
        if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
          mayorFechaUltimaEdicion = r.ultimaEdicion;
        }
      });
      return mayorFechaUltimaEdicion;
    }
  },
  actions: {
    async getProductosListasPreciosFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/productos-listas-precios/`;

      const mayorFechaUltimaEdicion =
        this.getUltimaProductosListasPreciosEdicion;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(
            collection(db, collectionRoute),
            where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)
          ).withConverter(productosListasPrecioConverter)
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addProductosListasPrecios(doc.data());
          });
          resolve();
        });
      });
    },
    addProductosListasPrecios(listaPrecio: ProductosListasPrecio) {
      // reviso que no exista
      for (var i = 0; i < this.productosListasPrecio.length; i++) {
        if (this.productosListasPrecio[i].id == listaPrecio.id) {
          this.productosListasPrecio[i] = listaPrecio;
          return; // corto ejecucion de la funcion
        }
      }

      // lo inserto y ordeno
      this.productosListasPrecio.push(listaPrecio);
      this.productosListasPrecio.sort((a, b) => (b.nombre > a.nombre ? -1 : 1));
    },
    async crearListaPrecio(listaPrecio: ProductosListasPrecio) {
      // const mensajesStore = useMensajesStore();
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${
        userStore.empresa!.id
      }/productos-listas-precios/`;

      let clonar = "";
      if (listaPrecio.clonarId) {
        clonar = listaPrecio.clonarId;
      }

      const docRef = doc(collection(db, collectionRoute));
      listaPrecio.id = docRef.id;
      await setDoc(docRef, listaPrecio);
      this.addProductosListasPrecios(listaPrecio);

      setDoc(
        doc(db, "empresas", userStore.empresa!.id),
        {
          ultimaEdicionProductosListasPrecios: new Date().getTime()
        },
        { merge: true }
      );

      return true;
    },
    getProductosListasPrecioById(id: string): ProductosListasPrecio | null {
      for (var i = 0; i < this.productosListasPrecio.length; i++) {
        if (this.productosListasPrecio[i].id == id) {
          return this.productosListasPrecio[i];
        }
      }
      return null;
    },
    async editarProductosListasPrecio(listaPrecio: ProductosListasPrecio) {
      // const mensajesStore = useMensajesStore();
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${
        userStore.empresa!.id
      }/productos-listas-precios/`;

      const docRef = doc(db, collectionRoute, listaPrecio.id);
      await setDoc(
        docRef,
        {
          version: listaPrecio.version,
          nombre: listaPrecio.nombre,
          locales: listaPrecio.locales,
          clonarId: listaPrecio.clonarId,
          activo: listaPrecio.activo,
          ultimaEdicion: listaPrecio.ultimaEdicion
        },
        { merge: true }
      );

      setDoc(
        doc(db, "empresas", userStore.empresa!.id),
        {
          ultimaEdicionProductosListasPrecios: new Date().getTime()
        },
        { merge: true }
      );

      // mensajesStore.mostrarToast(
      //   "Lista de precios editada con éxito",
      //   listaPrecio.nombre,
      //   mensajesStore.mensajeType.exito
      // );

      return true;
    },
    async editarListasPrecioMasiva(
      listasPrecioAEditar: ListasDePreciosAEditar
    ) {
      try {
        const resultado = await productosEdicionMasiva(listasPrecioAEditar);

        return resultado;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
  }
});
