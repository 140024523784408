import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { parseColor } from "tailwindcss/lib/util/color";
import { userUserStore } from "../stores/user";

dayjs.extend(duration);

const cutText = (text: string, length: number) => {
  if (text.split(" ").length > 1) {
    const string = text.substring(0, length);
    const splitText = string.split(" ");
    splitText.pop();
    return splitText.join(" ") + "...";
  } else {
    return text;
  }
};

const formatDate = (date: string, format: string) => {
  return dayjs(date).format(format);
};

const calculateFutureDate = (days: number) => {
  const currentDate = dayjs(new Date());
  const futureDate = currentDate.add(days, "day");
  return futureDate.format("DD/MM/YYYY");
};

const stringToDate = (date: string) => {
  const [dia, mes, ano] = date.split("/");
  return new Date(+ano, Number(mes) - 1, +dia);
};

const capitalizeFirstLetter = (string: string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return "";
  }
};

const capitalize = (text: string | undefined): string => {
  if (!!text) {
    return text
      .toLowerCase()
      ?.split(" ")
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  return "";
};

const capitalizeOnlyFirstLetter = (text: string | undefined): string => {
  if (!!text) {
    const firstLetter = text[0].toUpperCase();
    const restOfSentence = text.slice(1).toLowerCase();
    return firstLetter + restOfSentence;
  }
  return "";
};

const onlyNumber = (string: string) => {
  if (string) {
    return string.replace(/\D/g, "");
  } else {
    return "";
  }
};

const formatCurrency = (number: number) => {
  if (number) {
    const formattedNumber = number.toString().replace(/\D/g, "");
    const rest = formattedNumber.length % 3;
    let currency = formattedNumber.substr(0, rest);
    const thousand = formattedNumber.substr(rest).match(/\d{3}/g);
    let separator;

    if (thousand) {
      separator = rest ? "." : "";
      currency += separator + thousand.join(".");
    }

    return currency;
  } else {
    return "";
  }
};

const timeAgo = (time: string) => {
  const date = new Date((time || "").replace(/-/g, "/").replace(/[TZ]/g, " "));
  const diff = (new Date().getTime() - date.getTime()) / 1000;
  const dayDiff = Math.floor(diff / 86400);

  if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
    return dayjs(time).format("MMMM DD, YYYY");
  }

  return (
    (dayDiff === 0 &&
      ((diff < 60 && "just now") ||
        (diff < 120 && "1 minute ago") ||
        (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
        (diff < 7200 && "1 hour ago") ||
        (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
    (dayDiff === 1 && "Yesterday") ||
    (dayDiff < 7 && dayDiff + " days ago") ||
    (dayDiff < 31 && Math.ceil(dayDiff / 7) + " weeks ago")
  );
};

const diffTimeByNow = (time: string) => {
  const startDate = dayjs(dayjs().format("YYYY-MM-DD HH:mm:ss").toString());
  const endDate = dayjs(dayjs(time).format("YYYY-MM-DD HH:mm:ss").toString());

  const duration = dayjs.duration(endDate.diff(startDate));
  const milliseconds = Math.floor(duration.asMilliseconds());

  const days = Math.round(milliseconds / 86400000);
  const hours = Math.round((milliseconds % 86400000) / 3600000);
  let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000);
  const seconds = Math.round(
    (((milliseconds % 86400000) % 3600000) % 60000) / 1000
  );

  if (seconds < 30 && seconds >= 0) {
    minutes += 1;
  }

  return {
    days: days.toString().length < 2 ? "0" + days : days,
    hours: hours.toString().length < 2 ? "0" + hours : hours,
    minutes: minutes.toString().length < 2 ? "0" + minutes : minutes,
    seconds: seconds.toString().length < 2 ? "0" + seconds : seconds
  };
};

const isset = (obj: object | string) => {
  if (obj !== null && obj !== undefined) {
    if (typeof obj === "object" || Array.isArray(obj)) {
      return Object.keys(obj).length;
    } else {
      return obj.toString().length;
    }
  }

  return false;
};

const toRaw = (obj: object) => {
  return JSON.parse(JSON.stringify(obj));
};

const randomNumbers = (from: number, to: number, length: number) => {
  const numbers = [0];
  for (let i = 1; i < length; i++) {
    numbers.push(Math.ceil(Math.random() * (from - to) + to));
  }

  return numbers;
};

const toRGB = (value: string) => {
  return parseColor(value).color.join(" ");
};

const stringToHTML = (arg: string) => {
  const parser = new DOMParser(),
    DOM = parser.parseFromString(arg, "text/html");
  return DOM.body.childNodes[0] as HTMLElement;
};

const slideUp = (
  el: HTMLElement,
  duration = 300,
  callback = (el: HTMLElement) => { }
) => {
  el.style.transitionProperty = "height, margin, padding";
  el.style.transitionDuration = duration + "ms";
  el.style.height = el.offsetHeight + "px";
  el.offsetHeight;
  el.style.overflow = "hidden";
  el.style.height = "0";
  el.style.paddingTop = "0";
  el.style.paddingBottom = "0";
  el.style.marginTop = "0";
  el.style.marginBottom = "0";
  window.setTimeout(() => {
    el.style.display = "none";
    el.style.removeProperty("height");
    el.style.removeProperty("padding-top");
    el.style.removeProperty("padding-bottom");
    el.style.removeProperty("margin-top");
    el.style.removeProperty("margin-bottom");
    el.style.removeProperty("overflow");
    el.style.removeProperty("transition-duration");
    el.style.removeProperty("transition-property");
    callback(el);
  }, duration);
};

const slideDown = (
  el: HTMLElement,
  duration = 300,
  callback = (el: HTMLElement) => { }
) => {
  el.style.removeProperty("display");
  let display = window.getComputedStyle(el).display;
  if (display === "none") display = "block";
  el.style.display = display;
  let height = el.offsetHeight;
  el.style.overflow = "hidden";
  el.style.height = "0";
  el.style.paddingTop = "0";
  el.style.paddingBottom = "0";
  el.style.marginTop = "0";
  el.style.marginBottom = "0";
  el.offsetHeight;
  el.style.transitionProperty = "height, margin, padding";
  el.style.transitionDuration = duration + "ms";
  el.style.height = height + "px";
  el.style.removeProperty("padding-top");
  el.style.removeProperty("padding-bottom");
  el.style.removeProperty("margin-top");
  el.style.removeProperty("margin-bottom");
  window.setTimeout(() => {
    el.style.removeProperty("height");
    el.style.removeProperty("overflow");
    el.style.removeProperty("transition-duration");
    el.style.removeProperty("transition-property");
    callback(el);
  }, duration);
};
/**
 * Devuelve la fecha en formato YYYY-mm-dd
 * @param fecha fecha
 * @returns La fecha en aaaa-mm-dd
 */
const fechaFormateada = (fecha: Date): string => {
  const dia = fecha.getUTCDate();
  const mes = fecha.getUTCMonth() + 1;
  const ano = fecha.getFullYear();
  if (dia < 10 && mes < 10) {
    return `${ano}-0${mes}-0${dia}`;
  } else if (dia >= 10 && mes < 10) {
    return `${ano}-0${mes}-${dia}`;
  } else if (dia < 10 && mes >= 10) {
    return `${ano}-${mes}-0${dia}`;
  } else {
    return `${ano}-${mes}-${dia}`;
  }
};

/**
 *
 * @returns Fecha actual en formato dd/mm/aaaa
 */
const getCurrentDate = () => {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const getFirstDayOfMonth = () => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedMonth = String(month).padStart(2, "0");
  return `01/${formattedMonth}/${year}`;
};

// Devuelve rango de fechas del primer dia del mes corriente al dia actual
function getCurrentDateMonth() {
  const date = new Date();
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const day = String(firstDayOfMonth.getDate()).padStart(2, "0");
  const month = String(firstDayOfMonth.getMonth() + 1).padStart(2, "0");
  const year = firstDayOfMonth.getFullYear();
  const startDate = `${day}/${month}/${year}`;
  const today = String(date.getDate()).padStart(2, "0");
  const currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  const currentYear = date.getFullYear();
  const endDate = `${today}/${currentMonth}/${currentYear}`;
  return `${startDate} - ${endDate}`;
}

// Devuelve rango de fechas de hoy a hoy
function getCurrentDateRange() {
  const date = new Date();
  const today = String(date.getDate()).padStart(2, "0");
  const currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  const currentYear = date.getFullYear();
  const endDate = `${today}/${currentMonth}/${currentYear}`;
  return `${endDate} - ${endDate}`;
}

const obtenerSerieFactura = (comprobante: string) => {
  const regex = /^[^\d]*/;
  const resultado = comprobante.match(regex);

  if (resultado) {
    return resultado[0];
  } else {
    return "";
  }
};

const obtenerNumeroFactura = (comprobante: string) => {
  const regex = /\d+/g;
  const numerosEncontrados = comprobante.match(regex);

  if (numerosEncontrados) {
    return numerosEncontrados.join("");
  } else {
    return "";
  }
};

const numeroFormateado = (numero: any, cantDecimales = 2) => {
  if (numero === "" || numero === null) {
    return;
  }
  const formatoLocalizado = numero.toLocaleString("es", {
    style: "decimal",
    minimumFractionDigits: cantDecimales,
    maximumFractionDigits: cantDecimales
  });

  const separadorMiles = ".";
  const separadorDecimal = ",";

  const partes = formatoLocalizado.split(separadorDecimal);
  partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, separadorMiles);

  return partes.join(separadorDecimal);
};

const convertString = (value: string | number | undefined) => {
  if (value === undefined) return undefined;
  return typeof value === "string"
    ? value?.trim().toLowerCase()
    : value?.toString().trim().toLowerCase();
};

const removeAccents = (value: string): string => {
  return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const invalidNumber = (val: string | number | undefined) => {
  return !convertString(val) || typeof val !== "number" || isNaN(val);
};

const isValidDateNumber = (dateNumber: number) => {
  const dateString = dateNumber.toString();

  // Check if the length is 8
  if (dateString.length !== 8) {
    return false;
  }

  // Extract year, month, and day components
  const year = +dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6);

  // Check if the year is valid (01 to 12)
  if (year < 1900 || year > 2100) {
    return false;
  }

  // Check if the month is valid (01 to 12)
  if (!/^(0[1-9]|1[0-2])$/.test(month)) {
    return false;
  }

  // Check if the day is valid (01 to 31)
  if (!/^(0[1-9]|[12][0-9]|3[01])$/.test(day)) {
    return false;
  }

  // If all checks pass, the date is valid
  return true;
};

const possibleColumns = [
  " ",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "AA",
  "AB",
  "AC",
  "AD",
  "AE",
  "AF",
  "AG",
  "AH",
  "AI",
  "AJ",
  "AK",
  "AL",
  "AM",
  "AN"
];

async function aplicarSHA256(input: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  console.log("hash: ")
  console.log(hashHex)
  return hashHex;
}

const litePickerConfig = {
  autoApply: true,
  lang: 'es-ES',
  showWeekNumbers: false,
  dropdowns: {
    minYear: 2014,
    maxYear: null,
    months: true,
    years: true
  },
  format: 'DD/MM/YYYY'
}

// const formatearNumero = (numero: number) => {
//   numero = Math.round(numero * 100) / 100;

//   var numeroString = numero.toString();
//   var partes = numeroString.split(".");

//   if (partes.length === 1 || partes[1] === "00") {
//     return partes[0];
//   } else if (partes[1].charAt(1) === "0" && partes[1].charAt(0) !== "0") {
//     return partes[0] + "." + partes[1].charAt(0);
//   } else {
//     return parseFloat(numero.toFixed(2));
//   }
// };

const formatearNumero = (numero: number) => {
  numero = Math.round(numero * 100) / 100;

  var numeroString = numero.toString();
  var partes = numeroString.split(".");

  if (partes.length === 1 || partes[1] === "00") {
    return addThousandSeparator(partes[0]);
  } else if (partes[1].charAt(1) === "0" && partes[1].charAt(0) !== "0") {
    return addThousandSeparator(partes[0]) + "." + partes[1].charAt(0);
  } else {
    return addThousandSeparator(parseFloat(numero.toFixed(2)).toString());
  }
};

const addThousandSeparator = (num: string) => {
  var numParts = num.split(".");
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return numParts.join(".");
};

export {
  litePickerConfig,
  cutText,
  aplicarSHA256,
  formatDate,
  stringToDate,
  capitalizeFirstLetter,
  fechaFormateada,
  capitalize,
  capitalizeOnlyFirstLetter,
  onlyNumber,
  formatCurrency,
  timeAgo,
  diffTimeByNow,
  isset,
  toRaw,
  randomNumbers,
  toRGB,
  stringToHTML,
  slideUp,
  slideDown,
  obtenerSerieFactura,
  obtenerNumeroFactura,
  getCurrentDate,
  getCurrentDateRange,
  getCurrentDateMonth,
  numeroFormateado,
  convertString,
  removeAccents,
  invalidNumber,
  isValidDateNumber,
  possibleColumns,
  formatearNumero,
  calculateFutureDate,
  getFirstDayOfMonth
};
