import { defineStore } from "pinia";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  limit,
  orderBy,
  doc,
  setDoc,
  deleteDoc
} from "firebase/firestore";
import { ref } from "vue";
import { userUserStore } from "../stores/user";
import {
  CAE,
  CAEConverter,
  utilFechaGetCurrentTime
} from "@fixeduy/fixedtypes";

export const useCAEStore = defineStore({
  id: "caes",
  persist: true,
  state: () => {
    return {
      _caes: [] as CAE[],
      cargando: false,
      buscar: ""
    };
  },
  getters: {
    caes(): CAE[] {
      return this._caes;
    },
    getUltimaCaeEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this._caes.map(r => {
        if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
          mayorFechaUltimaEdicion = r.ultimaEdicion;
        }
      });
      return mayorFechaUltimaEdicion;
    }
  },
  actions: {
    async getCAEsFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/caes/`;
      const mayorFechaUltimaEdicion = this.getUltimaCaeEdicion;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(
            collection(db, collectionRoute),
            where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)
          ).withConverter(CAEConverter)
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addCAEToStore(doc.data());
          });
          resolve();
        });
      });
    },
    async crearCAE(cae: CAE) {
      try {
        this.cargando = true;
        const db = getFirestore();
        const userStore = userUserStore();
        const collectionRoute = `empresas/${userStore.empresa!.id}/caes/`;

        const docRef = doc(collection(db, collectionRoute));
        cae.id = docRef.id;
        await setDoc(docRef, cae);
        this.addCAEToStore(cae);

        setDoc(
          doc(db, "empresas", userStore.empresa!.id),
          {
            ultimaEdicionCAE: new Date().getTime()
          },
          { merge: true }
        );

        this.cargando = false;
        return true;
      } catch (error) {
        console.error(error);
      }
    },

    addCAEToStore(cae: CAE) {
      // reviso que no exista
      for (var i = 0; i < this._caes.length; i++) {
        if (this._caes[i].id == cae.id) {
          this._caes[i] = cae;
          return; // corto ejecucion de la funcion
        }
      }
      // lo inserto y ordeno
      this._caes.push(cae);
      this._caes.sort((a, b) =>
        b.fechaVencimiento > a.fechaVencimiento ? -1 : 1
      );
    },

    async buscarCAEs(buscarCliente: string) {
      let timerBuscar: NodeJS.Timeout | null = null;
      const buscar = ref("");
      let last: any = null;
      if (timerBuscar != null) clearTimeout(timerBuscar);

      timerBuscar = setTimeout(() => {
        buscar.value = buscarCliente;
        this._caes = [];
        last = null;
        this.getCAEsFromFirestore;
      }, 500);
    },

    async editarCAE(cae: CAE) {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/caes/`;
      const cargando = ref(false);

      try {
        cargando.value = true;

        const docRef = doc(db, collectionRoute, cae.id).withConverter(
          CAEConverter
        );

        await setDoc(
          docRef,
          {
            version: cae.version,
            id: cae.id,
            fechaCreacion: cae.fechaCreacion,
            ruce: cae.ruce,
            disponible: cae.disponible,
            tcfe: cae.tcfe,
            serie: cae.serie,
            desdeNumero: cae.desdeNumero,
            hastaNumero: cae.hastaNumero,
            numeroAutorizacion: cae.numeroAutorizacion,
            fechaAutorizacion: cae.fechaAutorizacion,
            fechaVencimiento: cae.fechaVencimiento,
            caeEspecial: cae.caeEspecial,
            usuarioCreador: cae.usuarioCreador,
            xml: cae.xml,
            ultimoUsado: cae.ultimoUsado
          },
          { merge: true }
        );

        /*    setDoc(
      doc(db, "empresas", userStore.empresa!.id),
      {
        ultimaEdicionCAES: new Date().getTime()
      },
      { merge: true }
    ); */
        cargando.value = false;
        this.init();
        return true;
      } catch (e) {
        console.log(e);
      }
    },
    init() {
      let last: any = null;
      last = null;
      this._caes = [];
      this.getCAEsFromFirestore();
    },
    async validarCaeRepetido(numAutorizacion: string) {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/caes/`;
      const querySnapshot = await getDocs(
        query(
          collection(db, collectionRoute),
          where("numeroAutorizacion", "==", numAutorizacion)
        )
      );
      return querySnapshot.docs.length !== 0;
    },
    async eliminarCAE(id: string) {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/caes/`;
      let cae = await getDocs(
        query(collection(db, collectionRoute), where("id", "==", id))
      );
      await deleteDoc(cae.docs[0].ref);
      this.init();
    },
    async validarCae(tcfe: number) {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/caes/`;

      let querySnapshot = await getDocs(
        query(
          collection(db, collectionRoute),
          where("tcfe", "==", tcfe),
          where("disponible", "==", true),
          where("fechaVencimiento", ">", utilFechaGetCurrentTime()),
          where(
            "caeEspecial",
            "==",
            userStore.opcionesGeneralesFacturacionElectronica.regimenEspecial
          ),
          limit(1),
          orderBy("fechaVencimiento", "asc"),
          orderBy("desdeNumero", "asc")
        ).withConverter(CAEConverter)
      );
      if (!querySnapshot.empty) {
        const cae = querySnapshot.docs[0].data();
        return cae;
      } else return false;
    }
  }
});
