<script setup lang="ts">
import { Dialog } from "../../../base-components/Headless";
import Button from "../../../base-components/Button";
import { onBeforeMount, ref } from "vue";
import LoadingIcon from "../../../base-components/LoadingIcon";
import Lucide from "../../../base-components/Lucide";
import { CAE } from "@fixeduy/fixedtypes";
import { useMensajesStore } from "../../../services/mensajes";
import { FormInput, FormLabel } from "../../../base-components/Form";

interface Props {
  imagen: any;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "cerrar"): void;
}>();

const sendButtonRef = ref(null);

const cargando = ref(false);
</script>

<template>
  <Dialog
    :open="true"
    @close="
      () => {
        emit('cerrar');
      }
    "
    :initialFocus="sendButtonRef"
    size="xl"
  >
    <Dialog.Panel class="overflow-y-auto max-h-[80vh]">
      <Dialog.Description class="">
        <img alt="Foto perfil" class="w-full" :src="props.imagen" />
      </Dialog.Description>
      <Dialog.Footer class="mt-6 text-right">
        <div v-if="cargando">
          <div class="flex justify-center my-2">
            <LoadingIcon icon="oval" class="w-8 h-8" />
          </div>
        </div>
        <div v-else class="flex">
          <div class="flex-1 text-left">
            <Button
              variant="secondary"
              class="mb-2 mr-3"
              @click="
                () => {
                  emit('cerrar');
                }
              "
            >
              Cancelar</Button
            >
          </div>
        </div>
      </Dialog.Footer>
    </Dialog.Panel>
  </Dialog>
</template>
