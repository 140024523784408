import { defineStore } from "pinia";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  setDoc,
  doc,
  addDoc
} from "firebase/firestore";
import { userUserStore } from "../stores/user";
import { CuentaAjena, cuentaAjenaConverter } from "@fixeduy/fixedtypes";

export const useCuentaAjenaStore = defineStore({
  id: "cuentaAjena",
  persist: true,
  state: () => {
    return {
      _cuentas: [] as CuentaAjena[],
      cargando: false
    };
  },
  getters: {
    cuentas(): CuentaAjena[] {
      return this._cuentas;
    },
    getUltimaCuentaAjenaEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this._cuentas.map(r => {
        if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
          mayorFechaUltimaEdicion = r.ultimaEdicion;
        }
      });
      return mayorFechaUltimaEdicion;
    }
  },
  actions: {
    async getCuentasFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/cuenta-ajena/`;
      const mayorFechaUltimaEdicion = this.getUltimaCuentaAjenaEdicion;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(
            collection(db, collectionRoute),
            where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)
          ).withConverter(cuentaAjenaConverter)
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addCuentaToStore(doc.data());
          });
          resolve();
        });
      });
    },
    addCuentaToStore(cuenta: CuentaAjena) {
      // reviso que no exista
      for (var i = 0; i < this._cuentas.length; i++) {
        if (this._cuentas[i].id == cuenta.id) {
          this._cuentas[i] = cuenta;
          return; // corto ejecucion de la funcion
        }
      }

      // lo inserto y ordeno
      this._cuentas.push(cuenta);
      this._cuentas.sort((a, b) =>
        b.nombreFantasia > a.nombreFantasia ? -1 : 1
      );
    },
    async crearCuenta(cuenta: CuentaAjena) {
      try {
        this.cargando = true;
        // const mensajesStore = useMensajesStore();
        const db = getFirestore();
        const userStore = userUserStore();
        const collectionRoute = `empresas/${
          userStore.empresa!.id
        }/cuenta-ajena/`;

        const docRef = doc(collection(db, collectionRoute));
        cuenta.id = docRef.id;
        await setDoc(docRef, cuenta);
        this.addCuentaToStore(cuenta);

        setDoc(
          doc(db, "empresas", userStore.empresa!.id),
          {
            ultimaEdicionCuentaAjena: new Date().getTime()
          },
          { merge: true }
        );
        this.cargando = false;

        return true;
      } catch (error) {
        console.error(error);
      }
    },
    getCentroCuentasById(id: string): CuentaAjena | null {
      for (var i = 0; i < this._cuentas.length; i++) {
        if (this._cuentas[i].id == id) {
          return this._cuentas[i];
        }
      }
      return null;
    },
    async editarCuentaAjena(cuenta: CuentaAjena) {
      this.cargando = true;
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/cuenta-ajena/`;

      const docRef = doc(db, collectionRoute, cuenta.id);

      await setDoc(
        docRef,
        {
          nombreFantasia: cuenta.nombreFantasia,
          tipoDocumento: cuenta.tipoDocumento,
          documento: cuenta.documento,
          razonSocial: cuenta.razonSocial,
          pais: cuenta.pais,
          activo: cuenta.activo,
          ultimaEdicion: cuenta.ultimaEdicion
        },
        { merge: true }
      );

      if (!cuenta.activo) {
        this._cuentas = this._cuentas.filter(cuenta => cuenta.activo);
      } else {
        for (let i = 0; i < this._cuentas.length; i++) {
          if (this._cuentas[i].id == cuenta.id) {
            this._cuentas[i] = cuenta;
            break;
          }
        }
      }

      setDoc(
        doc(db, "empresas", userStore.empresa!.id),
        {
          ultimaEdicionCuentaAjena: new Date().getTime()
        },
        { merge: true }
      );

      this.cargando = false;

      return true;
    }
  }
});
