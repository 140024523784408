import { defineStore } from "pinia";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  doc,
  setDoc
} from "firebase/firestore";
import { userUserStore } from "../stores/user";
import { CajaMediosCobro, cajaMediosCobroConverter } from "@fixeduy/fixedtypes";
import { useCajasStore } from "./cajas";

export const useCajasMediosCobroStore = defineStore({
  id: "cajasMediosCobro",
  persist: true,
  state: () => {
    return {
      _mediosCobro: [] as CajaMediosCobro[],
      cargando: false,
      cajas: [] as string[]
    };
  },
  getters: {
    mediosCobro(): CajaMediosCobro[] {
      return this._mediosCobro;
    },
    mediosCobroActivo(): CajaMediosCobro[] {
      return this._mediosCobro.filter(medio => medio.activo);
    },
    getUltimoMedioCobroEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this._mediosCobro.map(r => {
        if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
          mayorFechaUltimaEdicion = r.ultimaEdicion;
        }
      });
      return mayorFechaUltimaEdicion;
    }
  },
  actions: {
    async getMediosCobroFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/medios-cobro/`;
      const mayorFechaUltimaEdicion = this.getUltimoMedioCobroEdicion;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(
            collection(db, collectionRoute),
            where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)
          ).withConverter(cajaMediosCobroConverter)
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addMedioCobroStore(doc.data());
          });
          resolve();
        });
      });
    },
    addMedioCobroStore(medioCobro: CajaMediosCobro) {
      // reviso que no exista
      for (var i = 0; i < this._mediosCobro.length; i++) {
        if (this._mediosCobro[i].id == medioCobro.id) {
          this._mediosCobro[i] = medioCobro;
          return; // corto ejecucion de la funcion
        }
      }

      // lo inserto y ordeno
      this._mediosCobro.push(medioCobro);
      this._mediosCobro.sort((a, b) => (b.nombre > a.nombre ? -1 : 1));
    },
    async crearMedioCobro(medioCobro: CajaMediosCobro) {
      try {
        this.cargando = true;
        const db = getFirestore();
        const userStore = userUserStore();
        const collectionRoute = `empresas/${userStore.empresa!.id
          }/medios-cobro/`;
        const cajasStore = useCajasStore();

        const docRef = doc(collection(db, collectionRoute));
        medioCobro.id = docRef.id;
        await setDoc(docRef, medioCobro);
        this.addMedioCobroStore(medioCobro);

        setDoc(
          doc(db, "empresas", userStore.empresa!.id),
          {
            ultimaEdicionMedioCobro: new Date().getTime()
          },
          { merge: true }
        );

        if (this.cajas.length) {
          for (const caja of this.cajas) {
            let cajaOriginal = cajasStore.getCajaById(caja);
            if (cajaOriginal) {
              const mediosCobro = cajaOriginal.mediosCobro;
              mediosCobro.push(medioCobro.id);
              const cajaEditar = {
                version: cajaOriginal.version,
                id: cajaOriginal.id,
                nombre: cajaOriginal.nombre,
                puedenRetirar: cajaOriginal.puedenRetirar,
                puedenAceptarDepositos: cajaOriginal.puedenAceptarDepositos,
                mediosCobro: mediosCobro,
                mediosPago: cajaOriginal.mediosPago,
                saldoMonedas: cajaOriginal.saldoMonedas,
                saldoMedios: cajaOriginal.saldoMedios,
                depositosPendientes: cajaOriginal.depositosPendientes,
                depositosPendientesImportes:
                  cajaOriginal.depositosPendientesImportes,
                depositosPendientesAceptar:
                  cajaOriginal.depositosPendientesAceptar,
                depositosPendientesAceptarImportes:
                  cajaOriginal.depositosPendientesAceptarImportes,
                locales: cajaOriginal.locales,
                admiteDepositos: cajaOriginal.admiteDepositos,
                ultimaEdicion: cajaOriginal.ultimaEdicion,
                activo: cajaOriginal.activo
              };
              await cajasStore.editarCaja(cajaEditar);
            }
          }
        }

        this.cargando = false;
        return true;
      } catch (error) {
        console.error(error);
      }
    },
    getMedioCobroById(id: string): CajaMediosCobro | null {
      for (var i = 0; i < this._mediosCobro.length; i++) {
        if (this._mediosCobro[i].id == id) {
          return this._mediosCobro[i];
        }
      }
      return null;
    },
    async editarMedioCobro(medioCobro: CajaMediosCobro) {
      this.cargando = true;
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/medios-cobro/`;

      const docRef = doc(db, collectionRoute, medioCobro.id).withConverter(
        cajaMediosCobroConverter
      );

      await setDoc(
        docRef,
        {
          version: 1,
          nombre: medioCobro.nombre,
          camposExtra: medioCobro.camposExtra,
          activo: medioCobro.activo,
          ultimaEdicion: medioCobro.ultimaEdicion
        },
        { merge: true }
      );

      if (!medioCobro.activo) {
        this._mediosCobro = this._mediosCobro.filter(
          medioCobro => medioCobro.activo
        );
      } else {
        for (let i = 0; i < this._mediosCobro.length; i++) {
          if (this._mediosCobro[i].id == medioCobro.id) {
            this._mediosCobro[i] = medioCobro;
            break;
          }
        }
      }

      setDoc(
        doc(db, "empresas", userStore.empresa!.id),
        {
          ultimaEdicionMedioCobro: new Date().getTime()
        },
        { merge: true }
      );

      this.cargando = false;

      return true;
    }
  }
});
