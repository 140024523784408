import { defineStore } from "pinia";
import { Servicio } from "@fixeduy/fixedtypes";

export const useServiciosCacheStore = defineStore({
  id: "serviciosCacheStore",
  persist: true,
  state: () => {
    return {
      _servicios: [] as Servicio[],
    };
  },
  getters: {
    servicios(): Servicio[] {
      return this._servicios;
    },
  },
  actions: {
    agregarServicio(servicio: Servicio) {
      // reviso que no exista
      for (var i = 0; i < this._servicios.length; i++) {
        if (this._servicios[i].id == servicio.id) {
          this._servicios[i] = servicio;
          return; // corto ejecucion de la funcion
        }
      }

      // lo inserto y ordeno
      this._servicios.push(servicio);
      this._servicios.sort((a, b) =>
        b.nombre > a.nombre ? -1 : 1
      );
    },
  }
});
