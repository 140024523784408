import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "./getFunctions";
import {
  CloudFunctionResponse,
  ListasDePreciosAEditar,
  ServiciosProductosStockFuncs
} from "@fixeduy/fixedtypes";

const serviciosEdicionMasiva = async (
  data: ListasDePreciosAEditar
): Promise<CloudFunctionResponse> => {
  const run = httpsCallable<
    ServiciosProductosStockFuncs,
    CloudFunctionResponse
  >(cloudFunctions, "serviciosProducosStock");
  const result = await run({
    func: "serviciosEdicionMasiva",
    params: data
  });
  return result.data;
};

export { serviciosEdicionMasiva };
