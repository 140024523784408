import { defineStore } from "pinia";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  addDoc,
  doc,
  setDoc
} from "firebase/firestore";
import { userUserStore } from "../../stores/user";
import {
  ListasDePreciosAEditar,
  ServiciosListasPrecio,
  serviciosListasPrecioConverter
} from "@fixeduy/fixedtypes";
import { serviciosEdicionMasiva } from "../../services/cloudFunctions/serviciosEdicionMasiva";

export const useServiciosListasPreciosStore = defineStore({
  id: "serviciosListasPrecios",
  persist: true,
  state: () => {
    return {
      serviciosListasPrecio: [] as ServiciosListasPrecio[],
      verInactivo: false
    };
  },
  getters: {
    getServiciosListasPrecios(): ServiciosListasPrecio[] {
      return this.serviciosListasPrecio;
    },
    getServiciosListasPreciosActivas(): ServiciosListasPrecio[] {
      return this.serviciosListasPrecio.filter(l => l.activo);
    },
    getUltimaServiciosListasPreciosEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this.serviciosListasPrecio.map(r => {
        if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
          mayorFechaUltimaEdicion = r.ultimaEdicion;
        }
      });
      return mayorFechaUltimaEdicion;
    }
  },
  actions: {
    async getServiciosListasPreciosFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/servicios-listas-precios/`;
      const mayorFechaUltimaEdicion =
        this.getUltimaServiciosListasPreciosEdicion;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(
            collection(db, collectionRoute),
            where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)
          ).withConverter(serviciosListasPrecioConverter)
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addServiciosListasPrecios(doc.data());
          });
          resolve();
        });
      });
    },
    addServiciosListasPrecios(listaPrecio: ServiciosListasPrecio) {
      // reviso que no exista
      for (var i = 0; i < this.serviciosListasPrecio.length; i++) {
        if (this.serviciosListasPrecio[i].id == listaPrecio.id) {
          this.serviciosListasPrecio[i] = listaPrecio;
          return; // corto ejecucion de la funcion
        }
      }

      // lo inserto y ordeno
      this.serviciosListasPrecio.push(listaPrecio);
      this.serviciosListasPrecio.sort((a, b) => (b.nombre > a.nombre ? -1 : 1));
    },

    async crearListaPrecio(listaPrecio: ServiciosListasPrecio) {
      try {
        // const mensajesStore = useMensajesStore();
        const db = getFirestore();
        const userStore = userUserStore();
        const collectionRoute = `empresas/${
          userStore.empresa!.id
        }/servicios-listas-precios/`;

        let clonar = "";
        if (listaPrecio.clonarId) {
          clonar = listaPrecio.clonarId;
        }

        const docRef = doc(collection(db, collectionRoute)).withConverter(
          serviciosListasPrecioConverter
        );
        listaPrecio.id = docRef.id;
        console.log("Lista precio: ", listaPrecio);
        this.serviciosListasPrecio.push(listaPrecio);
        await setDoc(docRef, listaPrecio);

        setDoc(
          doc(db, "empresas", userStore.empresa!.id),
          {
            ultimaEdicionServiciosListasPrecios: new Date().getTime()
          },
          { merge: true }
        );

        return true;
      } catch (e) {
        console.log("e: ", e);
      }
    },

    getServiciosListasPrecioById(id: string): ServiciosListasPrecio | null {
      for (var i = 0; i < this.serviciosListasPrecio.length; i++) {
        if (this.serviciosListasPrecio[i].id == id) {
          return this.serviciosListasPrecio[i];
        }
      }
      return null;
    },

    async editarServiciosListasPrecio(listaPrecio: ServiciosListasPrecio) {
      // const mensajesStore = useMensajesStore();
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${
        userStore.empresa!.id
      }/servicios-listas-precios/`;

      const docRef = doc(db, collectionRoute, listaPrecio.id);
      await setDoc(
        docRef,
        {
          version: listaPrecio.version,
          nombre: listaPrecio.nombre,
          locales: listaPrecio.locales,
          clonarId: listaPrecio.clonarId,
          activo: listaPrecio.activo,
          ultimaEdicion: listaPrecio.ultimaEdicion
        },
        { merge: true }
      );

      setDoc(
        doc(db, "empresas", userStore.empresa!.id),
        {
          ultimaEdicionServiciosListasPrecios: new Date().getTime()
        },
        { merge: true }
      );

      // mensajesStore.mostrarToast(
      //   "Lista de precios editada con éxito",
      //   listaPrecio.nombre,
      //   mensajesStore.mensajeType.exito
      // );

      return true;
    },
    async editarListasPrecioMasiva(
      listasPrecioAEditar: ListasDePreciosAEditar
    ) {
      try {
        const resultado = await serviciosEdicionMasiva(listasPrecioAEditar);

        return resultado;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
  }
});
