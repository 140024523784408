import { defineStore } from "pinia";
import {
  collection,
  query,
  getFirestore,
  getDocs,
  where
} from "firebase/firestore";
import { userUserStore } from "../stores/user";
import { Usuario, usuarioConverter } from "@fixeduy/fixedtypes";
import { actualizarUsuarioDeEmpresa } from "./cloudFunctions/actualizarUsuarioDeEmpresa";
import { establecerContrasena } from "./cloudFunctions/establecerContrasena";

export const useUsuariosStore = defineStore({
  id: "usuarios",
  persist: true,
  state: () => {
    return {
      _usuarios: [] as Usuario[],
      cargando: false
    };
  },
  getters: {
    usuarios(): Usuario[] {
      return this._usuarios.filter(user => {
        return !user.mail.endsWith("@fixed.uy");
      });
    },
    vendedores(): Usuario[] {
      return this._usuarios.filter(
        usuario =>
          usuario.vendedor &&
          usuario.activo &&
          !usuario.mail.endsWith("@fixed.uy")
      );
    },
    getUltimaUsuariosEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this._usuarios
        .filter(user => {
          return !user.mail.endsWith("@fixed.uy");
        })
        .map(r => {
          if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
            mayorFechaUltimaEdicion = r.ultimaEdicion;
          }
        });
      return mayorFechaUltimaEdicion;
    }
  },
  actions: {
    async actualizar(
      usuario: Usuario,
      nombre: string,
      activo: boolean,
      vendedorRestringido: boolean,
      vendedor: boolean,
      rolId: string,
      locales: string[]
    ) {
      return actualizarUsuarioDeEmpresa({
        usuarioId: usuario.id,
        nombre: nombre,
        activo: activo,
        vendedorRestringido: vendedorRestringido,
        vendedor: vendedor,
        rolId: rolId,
        locales: locales
      });
    },
    async establecerContrasena(usuario: Usuario, contrasena: string) {
      return establecerContrasena({
        usuario: usuario,
        contrasena: contrasena
      });
    },
    async getFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/usuarios/`;
      const mayorFechaUltimaEdicion = this.getUltimaUsuariosEdicion;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(
            collection(db, collectionRoute),
            where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)
          ).withConverter(usuarioConverter)
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addUsuarioStore(doc.data());
          });
          resolve();
        });
      });
    },
    addUsuarioStore(usuario: Usuario) {
      // reviso que no exista
      for (var i = 0; i < this._usuarios.length; i++) {
        if (this._usuarios[i].id == usuario.id) {
          this._usuarios[i] = usuario;
          return; // corto ejecucion de la funcion
        }
      }

      // lo inserto y ordeno
      this._usuarios.push(usuario);
      this._usuarios.sort((a, b) => (b.nombre > a.nombre ? -1 : 1));
    },
    getById(id: string): Usuario | null {
      for (var i = 0; i < this._usuarios.length; i++) {
        if (this._usuarios[i].id == id) {
          return this._usuarios[i];
        }
      }

      return null;
    }
  }
});
