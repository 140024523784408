import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "./getFunctions";
import {
  FacturasPendientesFixedFunctionResponse,
  CallablesGeneral
} from "@fixeduy/fixedtypes";

const facturasPendientesFixed = async (
): Promise<FacturasPendientesFixedFunctionResponse> => {
  const run = httpsCallable<CallablesGeneral, FacturasPendientesFixedFunctionResponse>(
    cloudFunctions,
    "callablesGenerales"
  );
  const result = await run({
    func: "facturasPendientesFixed",
    params: null
  });

  return result.data;
};

export { facturasPendientesFixed };
