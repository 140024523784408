import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "./getFunctions";
import {
  CloudFunctionResponse,
  CallablesGeneral,
  EnviarMensajeChat
} from "@fixeduy/fixedtypes";

const enviarMensajeChat = async (
  data: EnviarMensajeChat
): Promise<CloudFunctionResponse> => {
  const run = httpsCallable<CallablesGeneral, CloudFunctionResponse>(
    cloudFunctions,
    "callablesGenerales"
  );
  const result = await run({
    func: "enviarMensajeChat",
    params: data
  });
  return result.data;
};

export { enviarMensajeChat };
