export interface DevolverMesString {
  [mes: string]: {
    nombre: string;
    numero: number;
  };
}

export const mesesNumero: DevolverMesString = {
  "01": {
    nombre: "Enero",
    numero: 1
  },
  "02": {
    nombre: "Febrero",
    numero: 2
  },
  "03": {
    nombre: "Marzo",
    numero: 3
  },
  "04": {
    nombre: "Abril",
    numero: 4
  },
  "05": {
    nombre: "Mayo",
    numero: 5
  },
  "06": {
    nombre: "Junio",
    numero: 6
  },
  "07": {
    nombre: "Julio",
    numero: 7
  },
  "08": {
    nombre: "Agosto",
    numero: 8
  },
  "09": {
    nombre: "Setiembre",
    numero: 9
  },
  "10": {
    nombre: "Octubre",
    numero: 10
  },
  "11": {
    nombre: "Noviembre",
    numero: 11
  },
  "12": {
    nombre: "Diciembre",
    numero: 12
  }
};

export const mesesNombre: DevolverMesString = {
  Enero: {
    nombre: "Enero",
    numero: 1
  },
  Febrero: {
    nombre: "Febrero",
    numero: 2
  },
  Marzo: {
    nombre: "Marzo",
    numero: 3
  },
  Abril: {
    nombre: "Abril",
    numero: 4
  },
  Mayo: {
    nombre: "Mayo",
    numero: 5
  },
  Junio: {
    nombre: "Junio",
    numero: 6
  },
  Julio: {
    nombre: "Julio",
    numero: 7
  },
  Agosto: {
    nombre: "Agosto",
    numero: 8
  },
  Setiembre: {
    nombre: "Setiembre",
    numero: 9
  },
  Octubre: {
    nombre: "Octubre",
    numero: 10
  },
  Noviembre: {
    nombre: "Noviembre",
    numero: 11
  },
  Diciembre: {
    nombre: "Diciembre",
    numero: 12
  }
};
