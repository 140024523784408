import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "./getFunctions";
import {
  AfectacionDocumentos,
  CloudFunctionResponse,
  CuentasCorrientesFuncs,
  InterfazDocumentoAfectado
} from "@fixeduy/fixedtypes";

const documentoAfectar = async (
  data: AfectacionDocumentos
): Promise<CloudFunctionResponse> => {
  const run = httpsCallable<CuentasCorrientesFuncs, CloudFunctionResponse>(
    cloudFunctions,
    "cuentasCorrientes"
  );
  const result = await run({
    func: "ccDocumentoAfectar",
    params: data
  });
  return result.data;
};

export { documentoAfectar };
