<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import Tippy from "../../base-components/Tippy";
import { MessageCircleQuestion } from "lucide-vue-next";
import { userUserStore } from "../../stores/user";
import ChatVentana from "./ChatVentana.vue";

const userStore = userUserStore();
onBeforeMount(() => {
  userStore.listenerChatMensajes();
});
</script>

<template>
  <div
    v-if="!userStore.chatAbierto"
    class="fixed bottom-0 right-0 z-50 flex items-center justify-center w-16 h-16 mb-1 mr-1 border rounded-full shadow-md cursor-pointer box"
    @click="() => userStore.abrirChat()"
    style="background: #3379af"
  >
    <Tippy variant="primary" content="Click para abrir el Chat de Ayuda">
      <div class="p-4" title="Click para abrir el chat de ayuda">
        <div
          class="absolute top-1 right-2 flex items-center justify-center w-7 h-7 -mt-4 text-xs font-small text-white rounded-full bg-danger"
          v-if="
            userStore.chatMensajes.length > 0 &&
            !userStore.chatMensajes[userStore.chatMensajes.length - 1]
              .vistoCliente
          "
        >
          ( ! )
        </div>
        <MessageCircleQuestion color="white" :size="40" />
      </div>
    </Tippy>
  </div>
  <ChatVentana v-else />
</template>
