import { defineStore } from "pinia";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  addDoc,
  doc,
  setDoc
} from "firebase/firestore";
import { userUserStore } from "../stores/user";
import { Unidad, UnidadConverter } from "@fixeduy/fixedtypes";

export const useUnidadesStore = defineStore({
  id: "unidades",
  persist: true,
  state: () => {
    return {
      _unidades: [] as Unidad[],
      cargando: false
    };
  },
  getters: {
    unidades(): Unidad[] {
      return this._unidades;
    },
    getUltimaUnidadesEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this._unidades.map(r => {
        if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
          mayorFechaUltimaEdicion = r.ultimaEdicion;
        }
      });
      return mayorFechaUltimaEdicion;
    }
  },
  actions: {
    async getUnidadesFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/unidades/`;
      const mayorFechaUltimaEdicion = this.getUltimaUnidadesEdicion;

      console.log("mayorFechaUltimaEdicion de unidades", mayorFechaUltimaEdicion);

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(
            collection(db, collectionRoute),
            where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)
          ).withConverter(UnidadConverter)
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addUnidadToStore(doc.data());
          });
          resolve();
        });
      });
    },
    addUnidadToStore(unidad: Unidad) {
      // reviso que no exista
      for (var i = 0; i < this._unidades.length; i++) {
        if (this._unidades[i].id == unidad.id) {
          this._unidades[i] = unidad;
          return; // corto ejecucion de la funcion
        }
      }

      // lo inserto y ordeno
      this._unidades.push(unidad);
      this._unidades.sort((a, b) => (b.nombre > a.nombre ? -1 : 1));
    },
    async crearUnidad(unidad: Unidad) {
      this.cargando = true;
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/unidades/`;

      const docRef = doc(collection(db, collectionRoute)).withConverter(
        UnidadConverter
      );
      unidad.id = docRef.id;
      this.addUnidadToStore(unidad);
      setDoc(docRef, unidad);
      this.cargando = false;

      return true;
    },
    getUnidadById(id: string): Unidad | null {
      for (var i = 0; i < this._unidades.length; i++) {
        if (this._unidades[i].id == id) {
          return this._unidades[i];
        }
      }
      return null;
    },
    async editarUnidad(unidad: Unidad) {
      this.cargando = true;
      // const mensajesStore = useMensajesStore();
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/unidades/`;

      const docRef = doc(db, collectionRoute, unidad.id);

      await setDoc(
        docRef,
        {
          version: 1,
          nombre: unidad.nombre,
          activo: unidad.activo,
          ultimaEdicion: unidad.ultimaEdicion
        },
        { merge: true }
      );

      if (!unidad.activo) {
        this._unidades = this._unidades.filter(unidad => unidad.activo);
      } else {
        for (let i = 0; i < this._unidades.length; i++) {
          if (this._unidades[i].id == unidad.id) {
            this._unidades[i] = unidad;
            break;
          }
        }
      }

      setDoc(
        doc(db, "empresas", userStore.empresa!.id),
        {
          ultimaEdicionUnidades: new Date().getTime()
        },
        { merge: true }
      );

      this.cargando = false;

      return true;
    }
  }
});
