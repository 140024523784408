<script setup lang="ts">
import {Dialog} from "../../../../base-components/Headless";
import {FormLabel, FormInput} from "../../../../base-components/Form";
import Button from "../../../../base-components/Button";
import {ref, reactive, toRefs, computed, onBeforeMount} from "vue";
import LoadingIcon from "../../../../base-components/LoadingIcon";
import {Local, Usuario} from "@fixeduy/fixedtypes";
import router from "../../../../router";
import {userUserStore} from "../../../../stores/user";
import {useUsuariosStore} from "../../../../services/usuarios";
import {useLocalesStore} from "../../../../services/locales";

interface Props {
  locales: Local[];
}

const props = defineProps<Props>();
const userStore = userUserStore();
const usuarioLogueado = ref<Usuario>();
const usuariosStore = useUsuariosStore();
const localesStore = useLocalesStore();

const emit = defineEmits<{
  (e: "cambiarLocal", value: Local): void;
  (e: "cerrar"): void;
}>();

const handlerSelectLocal = (local: Local) => {
  emit("cambiarLocal", local);
  emit("cerrar");
};

const sendButtonRef = ref(null);
const openDialog = ref(true);
const cargando = ref(false);
const tieneLocalesActivos = computed(() => props.locales.length > 0);

const redirectToLocales = () => {
  router.push("/locales");
  openDialog.value = false;
};
</script>

<template>
  <Dialog :open="openDialog" @close="() => emit('cerrar')" size="lg" :initialFocus="sendButtonRef">
    <Dialog.Panel class="overflow-y-auto max-h-[80vh]">
      <!-------------------- DIALOG DESCRIPTION ------------------------>
      <Dialog.Description>
        <div v-if="tieneLocalesActivos" class="col-span-12 mt-3 md:col-span-6 xl:col-span-4 2xl:col-span-12">
          <div class="flex items-center h-10 intro-x">
            <h2 class="mr-5 text-lg font-medium truncate">Selecciona qué local quieres definir por defecto</h2>
          </div>

          <div
            class="mt-5 relative before:block before:absolute before:w-px before:h-[85%] before:bg-slate-200 before:dark:bg-darkmode-400 before:ml-5 before:mt-5"
          >
            <div
              v-for="local in props.locales"
              :key="local.id"
              class="relative flex items-center mb-3 intro-x"
              @click="handlerSelectLocal(local)"
            >
              <div class="flex-1 px-5 py-3 ml-4 box zoom-in">
                <div class="font-medium">{{ local.nombre }}</div>
                <div class="mt-1 text-slate-500">{{ local.direccion }}</div>
                <div class="mt-1 text-slate-500">{{ local.localidad }}, {{ local.departamento }}</div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="flex items-center h-10 intro-x">
            <h2 class="mr-5 text-lg font-medium truncate">No tienes locales activos</h2>
          </div>

          <div
            class="mt-5 relative before:block before:absolute before:w-px before:h-[85%] before:bg-slate-200 before:dark:bg-darkmode-400 before:ml-5 before:mt-5"
          >
            <div class="relative flex items-center mb-3 intro-x">
              <div class="flex-1 px-5 py-3 ml-4 box zoom-in">
                <div class="font-medium" @click="redirectToLocales">
                  Ve a la sección de locales para crear uno nuevo o activar uno ya existente
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog.Description>
    </Dialog.Panel>
  </Dialog>
</template>
