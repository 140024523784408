<script setup lang="ts">
import Preview from "../../base-components/Preview";
import Alert from "../../base-components/Alert";
import Lucide from "../../base-components/Lucide";
import {userUserStore} from "../../stores/user";
import {utilFechaConvertirNumeroString} from "@fixeduy/fixedtypes";
const userStore = userUserStore();

const currentDate = new Date();
const fechaActual = currentDate.setDate(currentDate.getDate());
currentDate.setDate(currentDate.getDate() + 60);
const timestampPlus60Days = currentDate.getTime();
</script>

<template>
  <template v-if="userStore.vencimientoFirma == 0 || userStore.vencimientoFirma < fechaActual">
    <Alert variant="danger" class="mb-2 my-4">
      <div class="flex items-center">
        <div class="flex">
          <div class="flex-shrink-0">
            <Lucide icon="AlertOctagon" class="w-6 h-6 mr-2" />
          </div>
          <div class="flex-grow">
            <div class="text-lg font-medium">Sin firma electrónica</div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        No cuentas con una firma electrónica válida para poder facturar. Si recién te registraste en Fixed, sigue los
        pasos que te enviamos desde certifcados@fixed.uy
      </div>
    </Alert>
  </template>
  <template v-else-if="userStore.vencimientoFirma < timestampPlus60Days">
    <Alert variant="danger" class="mb-2 my-4">
      <div class="flex items-center">
        <div class="flex">
          <div class="flex-shrink-0">
            <Lucide icon="AlertOctagon" class="w-6 h-6 mr-2" />
          </div>
          <div class="flex-grow">
            <div class="text-lg font-medium">
              Vencimiento firma electrónica:
              {{ utilFechaConvertirNumeroString(userStore.vencimientoFirma, false, "/") }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        Con la firma electrónica vencida es imposible facturar, y además no se podrán enviar los reportes diarios a DGI,
        por lo que tu empresa estará en falta. Imprescindible renovarla a tiempo. Puedes ponerte en contacto con
        soporte@fixed.uy o certificados@fixed.uy
      </div>
    </Alert>
  </template>
</template>
