import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { NotificationElement } from "../base-components/Notification";

export const useMensajesStore = defineStore("mensajes", () => {
  enum mensajeType {
    exito,
    error
  }

  // Toast
  const toastNotification = ref<NotificationElement>();
  const toastTitulo = ref<string>("");
  const toastDetalle = ref<string>("");
  const tipoToast = ref<mensajeType>(mensajeType.exito);
  const duracion = 5000;

  // Modal
  const showModal = ref(false);
  const modalTitulo = ref<string>("");
  const modalDetalle = ref<string>("");
  const modalTipo = ref<mensajeType>(mensajeType.exito);

  const mostrarToast = (titulo: string, detalle: string, tipo: mensajeType) => {
    // Show notification
    toastTitulo.value = titulo;
    toastDetalle.value = detalle;
    tipoToast.value = tipo;
    toastNotification.value?.showToast();
  };

  const mostrarModal = (titulo: string, detalle: string, tipo: mensajeType) => {
    // Show notification
    modalTitulo.value = titulo;
    modalDetalle.value = detalle;
    modalTipo.value = tipo;
    showModal.value = true;
  };

  const cerrarModal = () => {
    showModal.value = false;
  };

  return {
    toastNotification,
    toastTitulo,
    toastDetalle,
    tipoToast,
    duracion,
    mensajeType,
    mostrarToast,
    modalTitulo,
    modalDetalle,
    modalTipo,
    showModal,
    mostrarModal,
    cerrarModal
  };
});
