import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "./getFunctions";
import {
  CloudFunctionResponse,
  CuentasCorrientesFuncs,
  InterfazDesafectarDocumento
} from "@fixeduy/fixedtypes";

const documentoDesafectar = async (
  data: InterfazDesafectarDocumento
): Promise<CloudFunctionResponse> => {
  const run = httpsCallable<CuentasCorrientesFuncs, CloudFunctionResponse>(
    cloudFunctions,
    "cuentasCorrientes"
  );
  const result = await run({
    func: "ccDocumentoDesafectar",
    params: data
  });
  return result.data;
};

export { documentoDesafectar };
