import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "./getFunctions";
import { CloudFunctionResponseVenta, Venta, VentasFuncs } from "@fixeduy/fixedtypes";

const ventasAgregarNotaDebito = async (
  data: Venta
): Promise<CloudFunctionResponseVenta> => {
  const run = httpsCallable<VentasFuncs, CloudFunctionResponseVenta>(
    cloudFunctions,
    "ventas"
  );
  const result = await run({
    func: "ventasAgregarNotaDebito",
    params: data
  });
  return result.data;
};

export { ventasAgregarNotaDebito };
