import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "./getFunctions";
import { CloudFunctionResponseVenta, VentasFuncs, Venta } from "@fixeduy/fixedtypes";

const ventasAgregar = async (data: Venta): Promise<CloudFunctionResponseVenta> => {
  const run = httpsCallable<VentasFuncs, CloudFunctionResponseVenta>(
    cloudFunctions,
    "ventas"
  );
  const result = await run({
    func: "ventasAgregar",
    params: data
  });
  return result.data;
};

export { ventasAgregar };
