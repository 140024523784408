import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "./getFunctions";
import { CloudFunctionResponseVenta, Venta, VentasFuncs } from "@fixeduy/fixedtypes";

const ventasAgregarDevolucion = async (
  data: Venta
): Promise<CloudFunctionResponseVenta> => {
  const run = httpsCallable<VentasFuncs, CloudFunctionResponseVenta>(
    cloudFunctions,
    "ventas"
  );
  const result = await run({
    func: "ventasAgregarDevolucion",
    params: data
  });
  return result.data;
};

export { ventasAgregarDevolucion };
