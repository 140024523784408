import { defineStore } from "pinia";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs
} from "firebase/firestore";
import { userUserStore } from "../../stores/user";
import { ComprasRubro, comprasRubroConverter } from "@fixeduy/fixedtypes";

export const useComprasRubrosStore = defineStore({
  id: "comprasRubro",
  persist: true,
  state: () => {
    return {
      _rubros: [] as ComprasRubro[],
      _rubrosCuenta: 0 as number
    };
  },
  getters: {
    rubros(): ComprasRubro[] {
      return this._rubros;
    },
    getUltimoRubroEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this._rubros.map(r => {
        if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
          mayorFechaUltimaEdicion = r.ultimaEdicion;
        }
      });
      return mayorFechaUltimaEdicion;
    }
  },
  actions: {
    async getRubrosFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/compras-rubros/`;

      const mayorFechaUltimaEdicion = this.getUltimoRubroEdicion;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(
            collection(db, collectionRoute),
            where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)
          ).withConverter(comprasRubroConverter)
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addRubroToStore(doc.data());
          });
          resolve();
        });
      });
    },
    addRubroToStore(rubro: ComprasRubro) {
      // reviso que no exista
      for (var i = 0; i < this._rubros.length; i++) {
        if (this._rubros[i].id == rubro.id) {
          this._rubros[i] = rubro;
          return; // corto ejecucion de la funcion
        }
      }

      // lo inserto y ordeno
      this._rubros.push(rubro);
      this._rubros.sort((a, b) =>
        b.nombreCompleto > a.nombreCompleto ? -1 : 1
      );
    },
    getRubroById(id: string): ComprasRubro | null {
      for (var i = 0; i < this._rubros.length; i++) {
        if (this._rubros[i].id == id) {
          return this._rubros[i];
        }
      }
      return null;
    },
    getRubroByUltimaEdicion(ultimaEdicion: number): ComprasRubro | null {
      for (var i = 0; i < this._rubros.length; i++) {
        if (this._rubros[i].ultimaEdicion == ultimaEdicion) {
          return this._rubros[i];
        }
      }

      return null;
    }
  }
});
