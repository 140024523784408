import { defineStore } from "pinia";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  startAfter,
  limit,
  orderBy,
  doc,
  setDoc,
  getDoc,
  updateDoc
} from "firebase/firestore";
import { Ref, ref } from "vue";
import { userUserStore } from "../../stores/user";
import { useTasasIVAStore } from "../tasas";
import {
  Compra,
  CompraConverter,
  DocumentoConverter,
  Producto,
  utilFechaGetTimestampFromDate
} from "@fixeduy/fixedtypes";
import { comprasAgregar } from "../cloudFunctions/comprasAgregar";
import { formatearFechaAAAAMMDD, getFirstAndLastOfMonthTimestamps, getFirstDayOfMonthTimestamp } from "../../utils/fecha";
import { FiltroCompras } from "./interfaceComprasFiltros";
import { useDocumentosStore } from "../documentos";
import { mesesNumero } from "../../utils/devolverMesString";
import { useCuentaIVAStore } from "../cuentaIVA";

export const useComprasStore = defineStore("compras", () => {
  const db = getFirestore();
  const userStore = userUserStore();
  const compras: Ref<Compra[]> = ref([]);
  const compra: Ref<Compra | null> = ref(null);
  let last: any = null;
  let mostrar = 10;
  const cargando = ref(false);
  let procesandoGetCompras = false;
  const showExitoMsg = ref(false);
  const buscar = ref("");
  const collectionRoute = `empresas/${userStore.empresa!.id}/compras/`;
  let timerBuscar: NodeJS.Timeout | null = null;
  const verInactivo = ref(false);
  const montoTotal = ref([] as { moneda: string; importe: number }[]);
  const montoIVA = ref([] as { moneda: string; importe: number }[]);
  const montoSubtotal = ref([] as { moneda: string; importe: number }[]);
  const filtrado = ref(false);
  const filtros: Ref<FiltroCompras> = ref({
    fechaInicio: "null",
    fechaFin: "null",
    local: "",
    proveedor: "",
    producto: [] as Producto[],
    formaPago: "",
    mostrar: "noAnuladas",
    nroComprobante: "",
    moneda: "",
    tipoCompra: "true",
    tipoGasto: "true",
    tipoNC: "true",
    tipoDev: "true",
    locales: [] as string[],
    monedaReporte: "pesos"
  });

  let ultimaCargaVacia = false;
  // const totalesPorTasasOrigen = ref(
  //   [] as { tasa: string; moneda: string; importe: number; montoIVA: number }[]
  // );
  const totalesPorTasasNacional = ref([] as { tasa: string; importe: number; montoIVA: number }[]);
  interface MonedaInfo {
    moneda: string;
    importe: number;
    montoIVA: number;
  }

  interface TasaInfo {
    tasa: string;
    monedas: MonedaInfo[];
  }

  // Inicialización de datos
  const totalesPorTasasOrigen = ref<TasaInfo[]>([]);
  const importeTotalSinIVA = ref(0);
  const importeIVAtotal = ref(0);
  const tasasStore = useTasasIVAStore();

  const firestoreQuery = () => {
    let fechaInicio: number | string = "";
    let fechaFin: number | string = "";

    if (filtros.value.fechaInicio != "" && filtros.value.fechaInicio != "null") {
      fechaInicio = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtros.value.fechaInicio));
    }

    if (filtros.value.fechaFin != "" && filtros.value.fechaFin != "null") {
      fechaFin = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtros.value.fechaFin));
    }

    let filtroProductos: string[] = [];
    if (filtros.value.producto.length > 0) {
      for (const producto of filtros.value.producto) {
        filtroProductos.push(producto.id);
      }
    }

    // if (fechaInicio) {
    //   // console.log("fechaComprobante", ">=", fechaInicio);
    // }

    // if (!verInactivo.value) {
    //   // console.log("activo", "==", true);
    // }
    // if (filtros.value.formaPago != "") {
    //   // console.log("formaDePago", "==", filtros.value.formaPago);
    // }
    // if (filtros.value.proveedor && filtros.value.proveedor != null) {
    //   // console.log("proveedor.id", "==", filtros.value.proveedor);
    // }

    // if (filtros.value.moneda.length) {
    //   // console.log("moneda", "in", filtros.value.moneda);
    // }

    // if (filtros.value.locales.length) {
    //   // console.log("local", "in", filtros.value.locales);
    // }

    // Filtro mostrar
    let activo = true;
    if (filtros.value.mostrar == "anuladas") {
      activo = false;
    }

    // Filtro tipo
    let tipo = [];
    if (filtros.value.tipoCompra === "true") {
      tipo.push("compra");
    }
    if (filtros.value.tipoGasto === "true") {
      tipo.push("gasto");
    }
    if (filtros.value.tipoNC === "true") {
      tipo.push("devolucion");
    }
    if (filtros.value.tipoDev === "true") {
      tipo.push("devolucion");
    }

    return query(
      collection(db, collectionRoute),
      ...(fechaInicio ? [where("fechaComprobante", ">=", fechaInicio)] : []),
      ...(fechaFin ? [where("fechaComprobante", "<=", fechaFin)] : []),
      ...(filtros.value.local != "" ? [where("local", "in", filtros.value.local)] : []),
      ...(filtros.value.proveedor && filtros.value.proveedor != null
        ? [where("proveedor.id", "==", filtros.value.proveedor)]
        : []),
      ...(filtros.value.producto.length > 0 ? [where("productos", "array-contains-any", filtroProductos)] : []),
      ...(!verInactivo.value ? [where("activo", "==", true)] : []),
      ...(filtros.value.formaPago != "" ? [where("formaDePago", "==", filtros.value.formaPago)] : []),
      ...(filtros.value.mostrar != "todas" ? [where("activo", "==", activo)] : []),
      ...(filtros.value.nroComprobante != "" ? [where("numero", "==", Number(filtros.value.nroComprobante))] : []),
      ...(filtros.value.moneda.length ? [where("moneda", "==", filtros.value.moneda)] : []),
      ...(tipo.length > 0 ? [where("tipo", "in", tipo)] : [where("tipo", "in", ["compra", "gasto", "devolucion"])]),
      ...(filtros.value.locales.length ? [where("local", "in", filtros.value.locales)] : []),
      orderBy("fechaComprobante", "desc"),
      orderBy("fechaCreacion", "desc"),
      ...(last != null ? [startAfter(last)] : []),
      ...(mostrar > 0 ? [limit(mostrar)] : [])
    ).withConverter(CompraConverter);
  };

  const init = () => {
    last = null;
    filtrado.value = false;
    ultimaCargaVacia = false;
    compras.value = [];
    vaciarFiltros();
    getCompras();
  };

  const vaciarFiltros = () => {
    (filtros.value.fechaInicio = "null"),
      (filtros.value.fechaFin = "null"),
      (filtros.value.local = ""),
      (filtros.value.proveedor = ""),
      (filtros.value.producto = [] as Producto[]),
      (filtros.value.formaPago = ""),
      (filtros.value.mostrar = "noAnuladas"),
      (filtros.value.nroComprobante = ""),
      (filtros.value.moneda = ""),
      (filtros.value.tipoCompra = "true"),
      (filtros.value.tipoGasto = "true"),
      (filtros.value.tipoNC = "true"),
      (filtros.value.tipoDev = "true"),
      (filtros.value.locales = [] as string[]);
  };

  const getCompras = async () => {
    calcularMontosTotales();
    if (procesandoGetCompras || ultimaCargaVacia) return;
    procesandoGetCompras = true;

    const querySnapshot = await getDocs(firestoreQuery());
    if (querySnapshot.docs[querySnapshot.docs.length - 1] != null) {
      last = querySnapshot.docs[querySnapshot.docs.length - 1];
    }
    ultimaCargaVacia = querySnapshot.docs.length == 0;
    querySnapshot.forEach(doc => {
      const compra = doc.data();
      const encontrada = compras.value.find(x => x.id === compra.id);
      if (encontrada == null) {
        compras.value.push(compra);
      }
    });
    calcularMontosTotales();
    procesandoGetCompras = false;
  };

  const getUltimaCompraProducto = async (productoId: string, proveedor: string | null): Promise<Compra | null> => {
    const q = query(
      collection(db, collectionRoute),
      ...(proveedor != null
        ? [where("proveedor.id", "==", proveedor)]
        : []),
      where("productos", "array-contains", productoId),
      orderBy("fechaCreacion", "desc"),
      limit(1)
    ).withConverter(CompraConverter);
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      const ultimaCompra = querySnapshot.docs[0].data();
      return ultimaCompra;
    } else {
      return null;
    }
  }

  const buscarCompras = async (buscarCompra: string) => {
    if (timerBuscar != null) clearTimeout(timerBuscar);

    timerBuscar = setTimeout(() => {
      buscar.value = buscarCompra;
      compras.value = [];
      last = null;
      getCompras();
    }, 500);
  };

  const addCompra = async (compra: Compra) => {
    try {
      const resultado = await comprasAgregar({ compra: compra });
      ultimaCargaVacia = false;
      compras.value.unshift(compra);
      return resultado;
    } catch (e) {
      throw e;
    }
  };

  const actualizarSaldo = async (compraId: string, saldo: number) => {
    const docRef = doc(db, collectionRoute, compraId);

    // Obtengo el saldo actual y le resto lo que viene por parametros
    const docSnapshot = await getDoc(docRef);
    const saldoActual = docSnapshot.data()?.saldo;
    const nuevoSaldo = saldoActual - saldo;

    await setDoc(
      docRef,
      {
        saldo: nuevoSaldo
      },
      { merge: true }
    );

    for (let i: number = 0; i < compras.value.length; i++) {
      if (compras.value[i].id == compraId) {
        compras.value[i].saldo = nuevoSaldo;
        break;
      }
    }

    return true;
  };

  const filtrarCompra = async () => {
    ultimaCargaVacia = false;
    cargando.value = true;
    compras.value = [];
    last = null;
    mostrar = 200;
    let i = 0;
    while (ultimaCargaVacia == false) {
      await getCompras();
      i++;
      if (i >= 50) {
        // console.log("Corto el while");
        break;
      }
    }
    getComprasReordenarReCalcular();
    filtrado.value = true;
    cargando.value = false;
    mostrar = 10;

    // Inicio implementar actualizar cuenta iva pantalla de inicio
    if (
      filtros.value.formaPago == "" &&
      filtros.value.nroComprobante == "" &&
      filtros.value.proveedor == "" &&
      filtros.value.producto.length == 0
    ) {
      let fechaInicio: number | string = "";
      let fechaFin: number | string = "";

      if (filtros.value.fechaInicio != "" && filtros.value.fechaInicio != "null") {
        fechaInicio = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtros.value.fechaInicio));
      }

      if (filtros.value.fechaFin != "" && filtros.value.fechaFin != "null") {
        fechaFin = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtros.value.fechaFin));

        let fechaSplit = filtros.value.fechaFin.split("/");

        let fecha = getFirstDayOfMonthTimestamp(mesesNumero[fechaSplit[1]].numero, Number(fechaSplit[2]));

        let fechaAux = getFirstAndLastOfMonthTimestamps(fecha);

        if (fechaInicio == fechaAux.firstOfMonth && fechaFin == fechaAux.lastOfMonth) {
          const cuentaIVAStore = useCuentaIVAStore();

          cuentaIVAStore.recalcularCuentaIVA(mesesNumero[fechaSplit[1]].nombre, fechaSplit[2], "compra");
        }
      }
    }
    // Fin implementar para actualizar cuenta iva pantalla de inicio
  };

  const getComprasReordenarReCalcular = () => {
    // Elimino las compras repetidas, por si alguna se cargo 2 veces
    compras.value = Object.values(
      compras.value.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {} as { [key: string]: Compra })
    );

    compras.value.sort((a, b) => {
      if (a.fechaCreacion === b.fechaCreacion) {
        return b.fechaComprobante - a.fechaComprobante;
      }
      return b.fechaCreacion - a.fechaCreacion;
    });
    cargando.value = false;
  };

  const calcularMontosTotales = () => {
    montoTotal.value = [];
    montoIVA.value = [];
    montoSubtotal.value = [];

    const comprasCopy = JSON.parse(JSON.stringify(compras.value));

    for (let i = 0; i < comprasCopy.length; i++) {
      const monedaActual = comprasCopy[i].moneda;
      const monedaExistente = montoTotal.value.find(item => item.moneda === monedaActual);

      if (!monedaExistente) {
        // La moneda no existe, agregar totales para esa moneda
        if (comprasCopy[i].tipo == "compra") {
          montoTotal.value.push({
            moneda: monedaActual,
            importe: comprasCopy[i].total
          });
          montoIVA.value.push({
            moneda: monedaActual,
            importe: comprasCopy[i].montoIVA
          });
          montoSubtotal.value.push({
            moneda: monedaActual,
            importe: comprasCopy[i].subtotal
          });
        } else {
          // Es NC
          montoTotal.value.push({
            moneda: monedaActual,
            importe: comprasCopy[i].total * -1
          });
          montoIVA.value.push({
            moneda: monedaActual,
            importe: comprasCopy[i].montoIVA * -1
          });
          montoSubtotal.value.push({
            moneda: monedaActual,
            importe: comprasCopy[i].subtotal * -1
          });
        }
      } else {
        // Existe, sumar montos totales, IVA y subtotal
        if (comprasCopy[i].tipo == "compra") {
          monedaExistente.importe += comprasCopy[i].total;
          const monedaIVAExistente = montoIVA.value.find(item => item.moneda === monedaActual);
          if (monedaIVAExistente) {
            monedaIVAExistente.importe += comprasCopy[i].montoIVA;
          }
          const monedaSubtotalExistente = montoSubtotal.value.find(item => item.moneda === monedaActual);
          if (monedaSubtotalExistente) {
            monedaSubtotalExistente.importe += comprasCopy[i].subtotal;
          }
        } else {
          // Es NC
          monedaExistente.importe -= comprasCopy[i].total;
          const monedaIVAExistente = montoIVA.value.find(item => item.moneda === monedaActual);
          if (monedaIVAExistente) {
            monedaIVAExistente.importe -= comprasCopy[i].montoIVA;
          }
          const monedaSubtotalExistente = montoSubtotal.value.find(item => item.moneda === monedaActual);
          if (monedaSubtotalExistente) {
            monedaSubtotalExistente.importe -= comprasCopy[i].subtotal;
          }
        }
      }
    }
    calcularTotalesPorTasasYMonedas();
  };

  const calcularTotalesPorTasasYMonedas = () => {
    totalesPorTasasOrigen.value = [];
    totalesPorTasasNacional.value = [];
    importeTotalSinIVA.value = 0;
    importeIVAtotal.value = 0;

    const comprasCopy = JSON.parse(JSON.stringify(compras.value));

    for (let i = 0; i < comprasCopy.length; i++) {
      const monedaActual = comprasCopy[i].moneda;

      for (const linea of comprasCopy[i].compraLinea) {
        const tasaActual = linea.tasaIva;

        // Calculo importe e IVA
        let importe = linea.importe;
        let montoIVA = 0;
        if (linea.montoBruto == 1) {
          importe = importe / (1 + tasasStore.getTasaIVAById(linea.tasaIva)?.valor! / 100);
          montoIVA = (importe * tasasStore.getTasaIVAById(linea.tasaIva)?.valor!) / 100;
        } else {
          montoIVA = (importe * tasasStore.getTasaIVAById(linea.tasaIva)?.valor!) / 100;
        }

        // AGREGO TOTALES EN MONEDA NACIONAL
        let tasaExistenteNacional = totalesPorTasasNacional.value.find(item => item.tasa === tasaActual);

        if (!tasaExistenteNacional) {
          // La tasa no existe, la agrego
          tasaExistenteNacional = {
            tasa: tasaActual,
            importe: 0,
            montoIVA: 0
          };
          totalesPorTasasNacional.value.push(tasaExistenteNacional);
        }

        if (comprasCopy[i].tipo === "compra") {
          tasaExistenteNacional.importe += importe * comprasCopy[i].cotizacion;
          tasaExistenteNacional.montoIVA += montoIVA * comprasCopy[i].cotizacion;
          importeTotalSinIVA.value += importe * comprasCopy[i].cotizacion;
          importeIVAtotal.value += montoIVA * comprasCopy[i].cotizacion;
        } else {
          tasaExistenteNacional.importe -= importe * comprasCopy[i].cotizacion;
          tasaExistenteNacional.montoIVA -= montoIVA * comprasCopy[i].cotizacion;
          importeTotalSinIVA.value -= importe * comprasCopy[i].cotizacion;
          importeIVAtotal.value -= montoIVA * comprasCopy[i].cotizacion;
        }

        // AGREGO TOTALES POR MONEDA ORIGEN
        let tasaExistente = totalesPorTasasOrigen.value.find(item => item.tasa === tasaActual);

        if (!tasaExistente) {
          // La tasa no existe, la agrego
          tasaExistente = {
            tasa: tasaActual,
            monedas: []
          };
          totalesPorTasasOrigen.value.push(tasaExistente);
        }

        let monedaExistente = tasaExistente.monedas.find(item => item.moneda === monedaActual);

        if (!monedaExistente) {
          // La moneda no existe, la agrego
          monedaExistente = {
            moneda: monedaActual,
            importe: 0,
            montoIVA: 0
          };
          tasaExistente.monedas.push(monedaExistente);
        }

        // Actualizo importes e IVA de la moneda y tasa actual
        if (comprasCopy[i].tipo === "compra") {
          monedaExistente.importe += importe;
          monedaExistente.montoIVA += montoIVA;
        } else {
          monedaExistente.importe -= importe;
          monedaExistente.montoIVA -= montoIVA;
        }
      }
    }
  };

  const chequearComprobanteExistente = async (comprobante: string, proveedor: string): Promise<boolean> => {
    if (proveedor == undefined || comprobante == undefined) return false;
    const q = query(
      collection(db, collectionRoute),
      ...(comprobante !== "" ? [where("comprobante", "==", comprobante)] : []),
      ...(proveedor !== "" && proveedor !== undefined ? [where("proveedor.id", "==", proveedor)] : [])
    ).withConverter(CompraConverter);

    const querySnapshot = await getDocs(q);

    if (querySnapshot.size == 0) {
      comprobante = " " + comprobante;
      const q = query(
        collection(db, collectionRoute),
        ...(comprobante !== "" ? [where("comprobante", "==", comprobante)] : []),
        ...(proveedor !== "" && proveedor !== undefined ? [where("proveedor.id", "==", proveedor)] : [])
      ).withConverter(CompraConverter);

      const querySnapshot = await getDocs(q);
      return querySnapshot.size > 0;
    }

    return querySnapshot.size > 0;
  };

  const modificarNroComprobante = async (idComprobante: string, nroNuevo: string, tipoComprobante: string) => {
    const documentoStore = useDocumentosStore();

    // Busco la compra a editar
    const docRef = doc(db, collectionRoute, idComprobante).withConverter(CompraConverter);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      throw new Error("No existe la compra");
    }

    const documento = await documentoStore.getDocumentoByDocumentoId(idComprobante, tipoComprobante);

    if (documento) {
      // Busco el documento a editar
      const docDocRef = doc(db, `empresas/${userStore.empresa!.id}/documentos/`, documento.id).withConverter(
        DocumentoConverter
      );
      const docDcoSnap = await getDoc(docDocRef);
      if (!docDcoSnap.exists()) {
        throw new Error("No el documento");
      }
      // Actualizo comprobante en Documentos
      await updateDoc(docDocRef, {
        comprobante: nroNuevo
      });
    }

    // Actualizo el nro comprobante
    await updateDoc(docRef, {
      comprobante: nroNuevo
    });
  };

  const buscarFacturasDevolucion = async (filtro: any) => {
    let fechaInicio: number | string = "";
    let fechaFin: number | string = "";

    if (filtro.fechaInicioFiltro && filtro.fechaInicioFiltro != "") {
      fechaInicio = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtro.fechaInicioFiltro));
    }
    if (filtro.fechaFinFiltro && filtro.fechaFinFiltro != "") {
      fechaFin = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtro.fechaFinFiltro));
    }

    const q = query(
      collection(db, collectionRoute),
      ...(fechaInicio != "" ? [where("fechaComprobante", ">=", fechaInicio)] : []),
      ...(fechaFin != "" ? [where("fechaComprobante", "<=", fechaFin)] : []),
      ...(filtro.proveedor != "" && filtro.proveedor != null ? [where("proveedor.id", "==", filtro.proveedor)] : []),
      ...(filtro.formaDePago != "" && filtro.formaDePago ? [where("formaDePago", "==", filtro.formaDePago)] : []),
      ...(filtro.moneda != "" ? [where("moneda", "==", filtro.moneda)] : []),
      ...(filtro.tipo != "" ? [where("tipo", "==", "compra")] : []),
      ...(filtro.local != "" ? [where("local", "==", filtro.local)] : []),
      orderBy("fechaComprobante", "desc"),
      orderBy("fechaCreacion", "desc")
    ).withConverter(CompraConverter);
    const querySnapshot = await getDocs(q);

    const devolverCompras: Ref<Compra[]> = ref([]);

    querySnapshot.forEach(doc => {
      const compra = doc.data() as Compra;
      if (compra.saldo > 0) {
        devolverCompras.value.push(compra);
      }
    });

    calcularMontosTotales();

    return devolverCompras.value;
  };

  const devolverUltimaCompraPorProducto = async (productoId: string, localId: string) => {
    let filtroProducto: string[] = [];
    filtroProducto.push(productoId);

    const q = query(
      collection(db, collectionRoute),
      where("productos", "array-contains-any", filtroProducto),
      where("local", "==", localId),
      orderBy("fechaCreacion", "desc"),
      limit(1)
    ).withConverter(CompraConverter);

    try {
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        // console.log(
        //   "No se encontraron compras para el producto con ID:",
        //   productoId
        // );
        return null;
      }

      const ultimaCompra = querySnapshot.docs[0].data();

      return ultimaCompra;
    } catch (error) {
      console.error("Error al obtener la última compra:", error);
      throw error;
    }
  };

  const getCompraById = async (id: string) => {
    try {
      const docRef = doc(db, collectionRoute, id);
      // .withConverter(
      //   CompraConverter
      // );

      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        return docSnapshot.data();
      } else {
        return null;
      }
    } catch (e) {
      console.error("Error en la consulta: ", e);
      throw e;
    }
  };

  const getComprasEstadisticas = async (filtro: any) => {
    // console.log(filtro);
    let fechaInicio: number | string = "";
    let fechaFin: number | string = "";

    if (filtro.fechaInicio != "") {
      fechaInicio = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtro.fechaInicio));
    }
    if (filtro.fechaFin != "") {
      fechaFin = utilFechaGetTimestampFromDate(formatearFechaAAAAMMDD(filtro.fechaFin));
    }

    compras.value = [];
    const q = query(
      collection(db, collectionRoute),
      ...(fechaInicio != "" ? [where("fechaComprobante", ">=", fechaInicio)] : []),
      ...(fechaFin != "" ? [where("fechaComprobante", "<=", fechaFin)] : []),
      where("activo", "==", true),
      ...(filtro.moneda != "" ? [where("moneda", "in", filtro.monedasFiltro)] : []),
      ...(filtro.locales.length > 0 ? [where("local", "in", filtro.locales)] : []),
      orderBy("fechaComprobante", "desc"),
      orderBy("fechaCreacion", "desc")
    ).withConverter(CompraConverter);
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(doc => {
      compras.value.push(doc.data());
    });

    // console.log(compras.value);

    return true;
  };

  const recalcularIVAComprasPorMes = async () => {
    try {
      let totalIVA = 0;
      if (compras.value.length > 0) {
        compras.value.forEach(compra => {
          // const compraData = compra.data();
          // console.log("Datos de la compra => ", compra);
          if (compra.tipo == "compra") {
            totalIVA += compra.montoIVA * compra.cotizacion;
          } else if (compra.tipo == "devolucion") {
            totalIVA -= compra.montoIVA * compra.cotizacion;
          }
        });
      }
      // console.log("Total IVA calculado: ", totalIVA);
      return totalIVA;
    } catch (e) {
      console.error("Error en la consulta: ", e);
      throw e;
    }
  };

  return {
    init,
    compra,
    compras,
    getCompras,
    filtros,
    cargando,
    addCompra,
    showExitoMsg,
    getUltimaCompraProducto,
    buscarCompras,
    actualizarSaldo,
    buscar,
    verInactivo,
    filtrarCompra,
    calcularMontosTotales,
    montoTotal,
    montoSubtotal,
    montoIVA,
    chequearComprobanteExistente,
    modificarNroComprobante,
    buscarFacturasDevolucion,
    totalesPorTasasOrigen,
    totalesPorTasasNacional,
    calcularTotalesPorTasasYMonedas,
    importeTotalSinIVA,
    importeIVAtotal,
    devolverUltimaCompraPorProducto,
    getCompraById,
    filtrado,
    getComprasEstadisticas,
    recalcularIVAComprasPorMes
  };
});
